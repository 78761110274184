import { types, applySnapshot } from "mobx-state-tree"
import { OrgSetupProjectAdminSettingsModel } from "./data-models/org-setup-project-admin-settings.data-models"

// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"

import { compareDesc } from "date-fns"
import { initialStore } from "./org-setup-project-admin-settings.provider"
import { formattedDateWithoutTzLabel } from "../../../../../library/converters/date-utc-converter"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import GetOrgSetupProjectAdminSettings from "./view-model-actions/get-org-setup-project-admin-settings"
import EditOrgSetupProjectAdminSettings from "./view-model-actions/edit-org-setup-project-admin-settings"

const OrgSetupProjectAdminSettingsViewModel = types
	.model({
		projectAdminSettingsData: OrgSetupProjectAdminSettingsModel,
		//
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setProjAdminSettingData(data: any) {
			self.projectAdminSettingsData = data
		},
		updateProjAdminSettingsData(
			replicaProjectAssignUserEligibility: number,
			replicaTeamMemberConfiguration: number,
			modifiedByUserName: string
		) {
			self.projectAdminSettingsData.replicaProjectAssignUserEligibility =
				replicaProjectAssignUserEligibility
			self.projectAdminSettingsData.replicaTeamMemberConfiguration =
				replicaTeamMemberConfiguration
			self.projectAdminSettingsData.updatedByUserName = modifiedByUserName
			self.projectAdminSettingsData.updatedAt = new Date().toISOString()
		},
	}))
	// ----------
	// AGER (Add, Get, Edit, Remove)
	.actions(GetOrgSetupProjectAdminSettings)
	.actions(EditOrgSetupProjectAdminSettings)
	// ----------
	.views((self) => ({
		viewProjAdminSettings() {
			const {
				replicaProjectAssignUserEligibility,
				replicaTeamMemberConfiguration,
			} = self.projectAdminSettingsData
			return {
				replicaProjectAssignUserEligibility,
				replicaTeamMemberConfiguration,
			}
		},
		viewEditHistory(dntFormat: DnTFormatProps) {
			const updatedAt = self.projectAdminSettingsData.updatedAt
				? formattedDateWithoutTzLabel({
						date: self.projectAdminSettingsData.updatedAt,
						dntFormat,
				  })
				: ""
			return {
				updatedAt,
				updatedBy: self.projectAdminSettingsData.updatedByUserName,
			}
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupEngtype")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgSetupProjectAdminSettingsViewModel
