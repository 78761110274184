import { types } from "mobx-state-tree"
import { OrgProjArchiveDatePolicyModel } from "./org-proj-arch-date-policy.model"
import { OrgProjArchiveDatePolicyViewModel } from "./org-proj-arch-date-policy.view-model"
import * as api from "./org-proj-arch-date-policies.apis"

const OrgProjArchiveDatePolicyStore = ({
	apiRead,
	apiUpdate,
	apiCreate,
	apiUpdatePolicy,
	apiDelete,
	apiCopy,
	apiMoveEngType,
}: api.OrgProjArchDatePolicyApiProps) =>
	types
		.compose(
			OrgProjArchiveDatePolicyModel({
				apiRead,
				apiUpdate,
				apiCreate,
				apiUpdatePolicy,
				apiDelete,
				apiCopy,
				apiMoveEngType,
			}),
			OrgProjArchiveDatePolicyViewModel
			// OrgProjArchiveDatePolicyUiHelper
		)
		.named("OrgProjArchiveDatePolicyStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgProjArchiveDatePolicyStoreInstance = OrgProjArchiveDatePolicyStore({
	apiRead: api.read,
	apiUpdate: api.update,
	apiCreate: api.create,
	apiUpdatePolicy: api.updatePolicy,
	apiDelete: api.del,
	apiCopy: api.copy,
	apiMoveEngType: api.moveEngType,
}).create(initialStore)

export default OrgProjArchiveDatePolicyStoreInstance
