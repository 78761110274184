import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import ERPData from "./ERPData."

const ERPDataController = observer(() => {
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.erp_data
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const actionName = "getERPData"
	const needRefresh = orgStore.erpData.needRefresh

	useEffect(() => {
		menuAccess && needRefresh && orgStore.erpData.getERPData()
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<ERPData
					partialStore={orgStore.erpData}
					actionStatus={orgStore.erpData.getActionStatus(actionName)}
					permission={permission}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

export default ERPDataController
