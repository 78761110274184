import { types } from "mobx-state-tree"

export const ERPDataTableModel = types.model({
	id: types.number,
	erpOriginalSystemId: types.string,
	erpClientMasterAliasId: types.string,
	erpClientMasterName: types.string,
	erpEngagementMasterAliasId: types.string,
	erpEngagementMasterName: types.string,
	erpEngagementYear: types.string,
	erpEngagementType: types.string,
	erpEngagementOwner: types.string,
	erpProjectAliasId: types.string,
	erpProjectName: types.string,
	erpProjectOwner: types.string,
	erpReportDate: types.string,
	erpReportReleaseDate: types.string,
	createdBy: types.string,
	rowIndex: types.number,
})
