import { types, applySnapshot } from "mobx-state-tree"
import {
	ActiveEngType,
	EngType,
} from "./data-models/org-setup-engTypes.data-models"

// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"

import {
	AddEngType,
	GetEngTypeList,
	GetActiveEngTypeList,
	EditEngType,
	RemoveEngType,
} from "./view-model-actions"
import { compareDesc } from "date-fns"
import { initialStore } from "./org-eng-types.provider"
import { formattedDateWithoutTzLabel } from "../../../../../library/converters/date-utc-converter"
import GetChecklistConnections from "./view-model-actions/get-checklist-connections"
import GetChecklistTemplates from "./view-model-actions/get-checklist-templates"
import ConnectChecklistTemplate from "./view-model-actions/connect-checklist-template"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"

const ChecklistModel = types.model({
	id: types.string,
	name: types.string,
})

const OrgEngTypesViewModel = types
	.model({
		engTypeList: types.array(EngType),
		activeEngTypeList: types.array(ActiveEngType),
		engagementId: types.string,
		//
		checklistTemplates: types.array(ChecklistModel),
		checklistConnections: types.array(
			types.model({
				id: types.string,
				projectTypeId: types.string,
				checklists: types.array(ChecklistModel),
			})
		),
		//
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setChecklistTemplates(templates: any) {
			self.checklistTemplates.length = 0
			self.checklistTemplates = templates
		},
		setChecklistConnections(connections: any) {
			self.checklistConnections.length = 0
			self.checklistConnections = connections
		},
		resetList() {
			self.engTypeList.length = 0
		},
		setSelectedEngId(id: string) {
			self.engagementId = id
		},
		pushItemToList(item: any) {
			self.engTypeList.push(item)
		},
		setEngTypeList(items: any) {
			self.engTypeList.length = 0
			self.engTypeList = items
		},
		setActiveEngTypeList(items: any) {
			self.activeEngTypeList.length = 0
			self.activeEngTypeList = items
		},
		popEngType(id: string) {
			self.engTypeList.splice(
				self.engTypeList.findIndex((item) => item.id === id),
				1
			)
		},
		updateEngStore(
			engTypeId: string,
			modifiedByUserName: string,
			modifiedAt: string,
			engTypeName?: string,
			sortOrder?: number,
			isActive?: boolean
		) {
			const target = self.engTypeList.find(
				(engType) => engType.id === engTypeId
			)
			if (!target) {
				console.error("No matched engType ID")
				return
			}

			target.modifiedBy = modifiedByUserName
			target.modifiedAt = modifiedAt
			if (engTypeName !== undefined) target.name = engTypeName
			if (sortOrder !== undefined) target.sortOrder = sortOrder
			if (isActive !== undefined) {
				target.isActive = isActive
				if (isActive) {
					target.activatedBy = modifiedByUserName
					target.activatedAt = modifiedAt
				} else {
					target.deActivatedBy = modifiedByUserName
					target.deActivatedAt = modifiedAt
				}
			}
		},
	}))
	// ----------
	// AGER (Add, Get, Edit, Remove)
	.actions(AddEngType)
	.actions(GetEngTypeList)
	.actions(GetActiveEngTypeList)
	.actions(GetChecklistConnections)
	.actions(GetChecklistTemplates)
	.actions(EditEngType)
	.actions(RemoveEngType)
	.actions(ConnectChecklistTemplate)
	// ----------
	.views((self) => ({
		isDuplicatedName(name: string) {
			return self.engTypeList.some(
				(engType: any) =>
					engType.name.toLowerCase() === name.toLowerCase()
			)
		},
		isDuplicatedSortOrder(order: number) {
			return self.engTypeList.some(
				(engType: any) => engType.sortOrder === order
			)
		},
	}))
	.views((self) => ({
		getEngTypeById(id: string) {
			return self.engTypeList.find((element) => element.id === id)
		},
		formattedList(dntFormat: DnTFormatProps) {
			let formattedList: any[] = []
			// sort the origin list
			let sortedList = self.engTypeList.slice().sort(function (a, b) {
				return compareDesc(b.sortOrder, a.sortOrder)
			})
			sortedList.map((engType) => {
				const formattedCreatedAt = engType.createdAt
					? formattedDateWithoutTzLabel({
							date: engType.createdAt,
							dntFormat,
					  })
					: ""
				const formattedModifiedAt = engType.modifiedAt
					? formattedDateWithoutTzLabel({
							date: engType.modifiedAt,
							dntFormat,
					  })
					: "-"
				const formattedActivatedAt = engType.activatedAt
					? formattedDateWithoutTzLabel({
							date: engType.activatedAt,
							dntFormat,
					  })
					: "-"
				const formattedDeActivatedAt = engType.deActivatedAt
					? formattedDateWithoutTzLabel({
							date: engType.deActivatedAt,
							dntFormat,
					  })
					: "-"
				formattedList.push({
					...engType,
					createdAt: formattedCreatedAt,
					modifiedAt: formattedModifiedAt,
					activatedAt: formattedActivatedAt,
					deActivatedAt: formattedDeActivatedAt,
				})
			})

			return formattedList
		},
		getActiveList() {
			return self.engTypeList.filter((item) => item.isActive)
		},
		getInactiveList() {
			return self.engTypeList.filter((item) => !item.isActive)
		},
		get engTypeOptions() {
			let options: { id: string; title: string }[] = []
			self.engTypeList.map((engType) => {
				options.push({
					id: engType.id,
					title: engType.name,
				})
			})
			return options
		},
		get activeEngTypeOptions() {
			return self.activeEngTypeList.map((engType) => ({
				id: engType.id,
				title: engType.name,
			}))
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupEngtype")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	// .actions((self) => ({
	// 	refresh() {
	// 		self.initializeStore()
	// 		self.getEngTypeList()
	// 	},
	// }))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgEngTypesViewModel
