import { types, applySnapshot } from "mobx-state-tree"
import {
	UserForExport,
	ClientsByGroup,
	UsersByGroup,
	RfSourceProject,
	SimpleClientProps,
	SimpleUserProps,
	ProjectDetailWithUsersAndFilesCount,
} from "./data-models/org-project.data-model"
// ---------- common models
import Responses from "../../../common-models/responses"
import ResponseSnackbar from "../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../common-models/common-view-model-actions"

import {
	GetOrgProjectList,
	//
	GetClientsByGroup,
	GetRfProjByGroup,
	GetUsersByGroup,
} from "./view-model-actions"
import {
	RemoveProject,
	AddProject,
} from "../../../service-modules/project-module/view-model-actions"
import { initialStore } from "./orgProjects.provider"
import { Period } from "../../../screens/organization-side/org-setup/org-setup-period-types/period-type.data-model"
import { ArchPolicyPeriod } from "../../../screens/organization-side/org-setup/org-setup-arch-policy-periods/arch-policy-period.data-model"
import GetArchPolicyPeriodList from "../../../screens/organization-side/org-setup/org-setup-arch-policy-periods/get-arch-policy-period-list"
import GetArchPolicyPeriodListByEngTypeId from "../../../screens/organization-side/org-setup/org-setup-arch-policy-periods/get-arch-policy-period-list-by-eng-type-id"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import GetProjPeriodTypes from "../../../screens/organization-side/org-setup/org-setup-period-types/get-proj-period-types"
import ProjectTableFilterViews from "../../../service-modules/project-module/view-model-views/table-filter-views"
import ExportUsersOfProject from "./view-model-actions/export-users"
import {
	DLProjectModel,
	DLRemindProjectModel,
	RFSourceProjInfoModel,
} from "../../../service-modules/project-module/data-model/project-module-data-models"
import { DLOrgMenuGroups } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { RefreshForProjects } from "./view-model-actions/refresh-for-projects"
import GetUsersAndFilesCountOfProjects from "./view-model-actions/get-users-and-files-count-of-projects"
import GetProjUsersAndFileCount from "./view-model-actions/get-project-users-and-file-count"
import GetProjectDetails from "../../../service-modules/project-module/view-model-actions/get-project-details"
import { ProjectDetailDataModel } from "../../../service-modules/project-module/data-model/project-detail.data-model"
import { AMProjectUsers } from "../../../screens/organization-side/archive-management/data-models/arch-mgmt-project-users"
import { getTzDate } from "../../../library/converters/date-utc-converter"
import {
	GetProjInfo,
	EditProjInfo,
} from "../../../screens/project-side/project-information/store/view-model-actions"
import {
	ProjInfo,
	ClientInGroup,
	CabinetDetails,
} from "../../../screens/project-side/project-information/store/data-models/proj-info.data-model"
import {
	UpdateProjInfoActions,
	UpdateProjInfoViews,
} from "../../../screens/project-side/project-information/store/view-model-actions/update-proj-info-common-actions"
import { GetActiveEngTypeList } from "../../../screens/organization-side/org-setup/org-setup-eng-types/store/view-model-actions"
import { ActiveEngType } from "../../../screens/organization-side/org-setup/org-setup-eng-types/store/data-models/org-setup-engTypes.data-models"
import { GetActiveRiskAssessmentList } from "../../../screens/organization-side/org-setup/org-setup-risk-assessment/store/view-model-actions"
import { ActiveRiskAssessment } from "../../../screens/organization-side/org-setup/org-setup-risk-assessment/store/data-models/org-setup-risk-assessment.data-models"
import AddOtherProject from "../../../service-modules/project-module/view-model-actions/add-other-project"
import { PreConditionData } from "../../../screens/project-side/archive/archive-pre-process/store/data-models/archive-pre-process.data-model"
import GetCabinetLocation from "../../../screens/project-side/project-information/store/view-model-actions/get-cabinet-location"
import ForceArchive from "./view-model-actions/force-archive"
import GetRemindProjectList from "./view-model-actions/get-remind-proj-list"
import GetPreConditionStatus from "../../../screens/organization-side/archive-management/am-archived-projects/store/view-model-actions/get-pre-condition-status"

const OrgProjectsViewModel = types
	.model({
		storeName: DLOrgMenuGroups.projects,
		projectDetails: ProjectDetailDataModel,
		projectUsers: AMProjectUsers,
		projInfo: ProjInfo,
		cabinetLocationDetails: types.array(CabinetDetails),
		//
		normalProjList: types.array(DLProjectModel),
		replicaProjList: types.array(DLProjectModel),
		archivedProjList: types.array(DLProjectModel),
		unarchivedProjList: types.array(DLProjectModel),
		roamArchivedProjList: types.array(DLProjectModel),
		forceArchivedProjList: types.array(DLProjectModel),
		remindProjList: types.array(DLRemindProjectModel),
		remindArcProjList: types.array(DLRemindProjectModel),
		//
		usersForExport: types.array(UserForExport),
		userExportStatusList: types.array(
			types.model({
				projId: types.string,
				success: types.boolean,
			})
		),
		usersAndFilesCountOfProjects: types.array(
			ProjectDetailWithUsersAndFilesCount
		),
		//
		selectedProj: "", // TODO: Need to be removed...
		selectedCabinetId: "",
		//
		rfSourceProjInfo: RFSourceProjInfoModel,
		//
		// to create project (but seems to be used for other task...)
		// NOTE: rf source is on the project store
		// NOTE: TBD about this placement
		clientsByGroupMap: types.map(ClientsByGroup),
		usersByGroupMap: types.map(UsersByGroup),
		archPolicyPeriodList: types.array(ArchPolicyPeriod),
		rfSources: types.array(RfSourceProject),
		//
		periodNames: types.array(Period),
		//
		preConditionData: PreConditionData,
		// for update dialog
		clientList: types.array(ClientInGroup),
		activeEngTypeList: types.array(ActiveEngType),
		activeRiskAssessmentList: types.array(ActiveRiskAssessment),
		selectedProjType: "",
		// ---------- common models
		needRefreshForNormal: true,
		needRefreshForReplica: true,
		needRefreshForArchived: true,
		needRefreshForUnarchived: true,
		needRefreshForForceArchived: true,
		needRefreshForAssignedNormal: true,
		needRefreshForAssignedReplica: true,
		needRefreshForAssignedArchived: true,
		needRefreshForAssignedUnarchived: true,
		needRefreshForAssignedForceArchived: true,
		needRefreshForROAM: true,
		needRefreshForRemindReport: true,
		needRefreshForRemindArchive: true,
		needRefreshForAdminReport: true,
		needRefreshForAdminArchive: true,
		needRefreshForArchivingReminder: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		pushItemToUsersAndFilesCountOfProjects(item: any) {
			self.usersAndFilesCountOfProjects.push(item)
		},
		resetUsersAndFilesCountOfProjects() {
			self.usersAndFilesCountOfProjects.length = 0
		},
		setProjectDetails(details: any) {
			self.projectDetails = details
		},
		pushItemToProjectUsers(users: any) {
			self.projectUsers = users
		},
		setCabinetLocationDetails(details: any) {
			self.cabinetLocationDetails.length = 0
			self.cabinetLocationDetails = details
		},
	}))
	.actions(UpdateProjInfoActions)
	.actions(ExportUsersOfProject)
	.actions(GetCabinetLocation)
	.actions((self) => ({
		concatItemsToUsersForExport(concattedList: any) {
			self.usersForExport = concattedList // NOTE: concat is not working here
		},
		resetUsersForExport() {
			self.usersForExport.length = 0
		},
		pushItemToUserExportStatusList(item: any) {
			self.userExportStatusList.push(item)
		},
		resetUserExportStatusList() {
			self.userExportStatusList.length = 0
		},
		// TODO: second param should be updated after the discussion (phase? ststus?type?)
		getProjInfoForRf(projId: string, projPhase: ProjectStatus) {
			let targetProject: any = {}
			if (projPhase === ProjectStatus.normal) {
				targetProject = self.normalProjList.find(
					(item) => item.id === projId
				)
			} else {
				alert(`Please update the ProjectPhase part setting`)
				return
			}
			return targetProject
		},
		// push
		// NOTE: TODO: Add 'set' type actions instead of the push.
		// NOTE: Some data needs both of 'set' and 'push' and some data needs just one of them
		pushItemToOriginalNormalProjectList(item: any) {
			self.normalProjList.push(item)
		},
		pushItemToOriginalReplicaProjectList(item: any) {
			self.replicaProjList.push(item)
		},
		pushItemToOriginalArchivedProjectList(item: any) {
			self.archivedProjList.push(item)
		},
		pushItemToOriginalUnarchivedProjectList(item: any) {
			self.unarchivedProjList.push(item)
		},
		//
		setNormalProjectList(projects: any) {
			self.normalProjList.length = 0
			self.normalProjList = projects
		},
		setReplicaProjectList(projects: any) {
			self.replicaProjList.length = 0
			self.replicaProjList = projects
		},
		setArchivedProjectList(projects: any) {
			self.archivedProjList.length = 0
			self.archivedProjList = projects
		},
		setUnarchivedProjectList(projects: any) {
			self.unarchivedProjList.length = 0
			self.unarchivedProjList = projects
		},
		setROAMArchivedProjectList(projects: any) {
			self.roamArchivedProjList.length = 0
			self.roamArchivedProjList = projects
		},
		setForceArchivedProjList(projects: any) {
			self.forceArchivedProjList.length = 0
			self.forceArchivedProjList = projects
		},
		setRemindProjList(projects: any) {
			self.remindProjList.length = 0
			self.remindProjList = projects
		},
		setRemindArcProjList(projects: any) {
			self.remindArcProjList.length = 0
			self.remindArcProjList = projects
		},
		//
		setPeriodList(list: any) {
			self.periodNames.length = 0
			self.periodNames = list
		},
		// reset
		resetOriginalNormalProjectList() {
			self.normalProjList.length = 0
		},
		resetOriginalReplicaProjectList() {
			self.replicaProjList.length = 0
		},
		resetOriginalArchivedProjectList() {
			self.archivedProjList.length = 0
		},
		resetOriginalUnarchivedProjectList() {
			self.unarchivedProjList.length = 0
		},
		setSelectedProj(projId: string) {
			self.selectedProj = projId
		},
		setSelectedCabinetId(cabinetId: string) {
			self.selectedCabinetId = cabinetId
		},
		popItemFromProjectList(projId: string, projStatus: string) {
			if (projStatus === ProjectStatus.normal) {
				self.normalProjList.splice(
					self.normalProjList.findIndex(
						(item: any) => item.id === projId
					),
					1
				)
			}
			if (projStatus === ProjectStatus.replica) {
				self.replicaProjList.splice(
					self.replicaProjList.findIndex(
						(item: any) => item.id === projId
					),
					1
				)
			}
		},
		setSelectedProjType(type: string) {
			self.selectedProjType = type
		},
		reflectUpdatedProjData(projInfo: any) {
			const targetIndex = self.normalProjList.findIndex(
				(item: any) => item.id === self.selectedProj
			)
			const targetProj = self.normalProjList.find(
				(item: any) => item.id === self.selectedProj
			)

			const updatedProj = {
				...targetProj,
				...projInfo,
			}
			if (!targetIndex) {
				return "Something wrong.. Cannot find the matched ID in the stored project list."
			}
			self.normalProjList.splice(
				targetIndex,
				1,
				JSON.parse(JSON.stringify(updatedProj))
			)
		},
		setRFSourceProjInfo(info: any) {
			self.rfSourceProjInfo = info
		},
		removeNormalProject(clientId: string) {
			self.normalProjList.splice(
				self.normalProjList.findIndex(
					(item: any) => item.id === clientId
				),
				1
			)
		},
		removeRemindProject(clientId: string) {
			self.remindProjList.splice(
				self.remindProjList.findIndex(
					(item: any) => item.id === clientId
				),
				1
			)
		},
		removeRemindArcProject(clientId: string) {
			self.remindArcProjList.splice(
				self.remindArcProjList.findIndex(
					(item: any) => item.id === clientId
				),
				1
			)
		},
	}))
	// create project related actions
	.actions((self) => ({
		setClientsByGroupMap(clients: SimpleClientProps[], groupId: string) {
			const clientsByGroup = {
				groupId: groupId,
				clients: clients,
			}
			self.clientsByGroupMap.set(groupId, clientsByGroup)
		},
		setUsersByGroupMap(users: SimpleUserProps[], groupId: string) {
			const usersByGroup = {
				groupId: groupId,
				users: users,
			}
			self.usersByGroupMap.set(groupId, usersByGroup)
		},
		setRfSources(rfSourceList: any) {
			self.rfSources = rfSourceList
		},
		setArchPolicyPeriodList(periodList: any) {
			self.archPolicyPeriodList = periodList
		},
		//
		setPreConditionData(data: any) {
			self.preConditionData = data
		},
		resetPreConditionData() {
			self.preConditionData.isPreProcessed = false
			self.preConditionData.preProcessFiles.map((files) => {
				files.isPreProcessed = false
				files.downloadUrl = ""
				files.viewUrl = ""
			})
		},
		setPreProcessComplete(isPreProcessed: boolean) {
			self.preConditionData.isPreProcessed = isPreProcessed
		},
		resetArchPolicyPeriodOptions() {
			self.archPolicyPeriodList.length = 0
		},
	}))
	// Update Proj related views
	.views(UpdateProjInfoViews)
	// create project related views
	.views((self) => ({
		viewSelectedProjInfo(selectedProj: string, projStatus: ProjectStatus) {
			let project
			if (projStatus === ProjectStatus.normal) {
				project = self.normalProjList
			} else if (projStatus === ProjectStatus.replica) {
				project = self.replicaProjList
			} else if (projStatus === ProjectStatus.archived) {
				project = self.archivedProjList
			} else if (projStatus === ProjectStatus.unarchived) {
				project = self.unarchivedProjList
			} else if (projStatus === ProjectStatus.forceArchived) {
				project = self.forceArchivedProjList
			} else {
				project = self.roamArchivedProjList
			}
			const selectedProjInfo = project.find(
				(project: any) => project.id === selectedProj
			)

			return selectedProjInfo
		},
		viewSelectedRemindProjInfo(selectedProj: string) {
			const selectedProjInfo = self.remindProjList.find(
				(project: any) => project.id === selectedProj
			)

			return selectedProjInfo
		},
		viewSelectedArcRemindProjInfo(selectedProj: string) {
			const selectedProjInfo = self.remindArcProjList.find(
				(project: any) => project.id === selectedProj
			)

			return selectedProjInfo
		},
		clientsByGroupOptions(groupId: string) {
			const clientsByGroup = self.clientsByGroupMap.get(groupId)?.clients
			let options: { id: string; name: string; aliasId: string }[] = []
			if (clientsByGroup) {
				clientsByGroup.map((client) => {
					options.push({
						id: client.id,
						name: client.name,
						aliasId: client.aliasId,
					})
				})
				return options
			} else {
				return []
			}
		},
		get archPolicyPeriodOptions() {
			return self.archPolicyPeriodList.map((period) => ({
				id: period.id,
				title: period.days + " days",
			}))
		},
		archPolicyPeriodValue(periodId: string) {
			const period = self.archPolicyPeriodList.find(
				(item) => item.id === periodId
			)
			return period?.days
		},
		getClientInfoById(
			projectId: string,
			dateFormat: string,
			timeZone: string,
			timeFormat: string,
			from?: string
		) {
			if (from === "roamArchive") {
				const projectDetails = self.roamArchivedProjList.find(
					(element: any) => element.id === projectId
				)

				return projectDetails
			} else {
				const projectDetails = self.replicaProjList.find(
					(element: any) => element.id === projectId
				)

				return projectDetails
			}
		},
		get rfOptions() {
			let options: { value: string; name: string; year: any }[] = []
			self.rfSources.map((rf) => {
				const combinedInfo = rf.combinedInfo
				const year = rf.year
				options.push({
					value: rf.id,
					name: combinedInfo,
					year,
				})
			})
			return options
		},
		// get viewPeriodNames() {
		// 	let options: { id: string; title: string }[] = []
		// 	ConsoleLog(["viewPeriodNames", self.periodNames])
		// 	self.periodNames.map((period: any) => {
		// 		options.push({
		// 			id: period.id,
		// 			title: period.title,
		// 		})
		// 	})
		// 	ConsoleLog(["viewPeriodNames---options", options])
		// 	return options
		// },
		// TODO: pending. GetClientInformationDetail API doesn't have the role information in it
		// projectDetailForCtxMenu() {
		// 	const details = self.projectDetails
		// 	return {
		// 		ableToRollForward: details.,
		// 		ableToMove: ,
		// 		ableToDelete: ,
		// 	}
		// },
		formattedProjectDetails(dateFormat: string, timeZone: string) {
			const {
				groupName,
				clientName,
				clientAliasId,
				projectId,
				projAliasId,
				projTitle,
				engTypeName,
				raName,
				statusName,
				periodName,
				version,
				isLocked,
				periodEndDate,
				expectedReportDate,
				finalReportDate,
				expectedArchDeadlineDate,
				finalArchDeadlineDate,
				archPolicyPeriodId,
				archPolicyPeriodName,
				createdBy,
				engPartner,
				clientAddress,
				fee,
				createdAt,
			} = self.projInfo

			const { replicaId } = self.projectDetails

			const formattedPED = periodEndDate
				? getTzDate({ date: periodEndDate, timeZone, dateFormat })
				: ""
			const formattedERD = expectedReportDate
				? getTzDate({ date: expectedReportDate, timeZone, dateFormat })
				: ""
			const formattedFRD = finalReportDate
				? getTzDate({ date: finalReportDate, timeZone, dateFormat })
				: ""
			const formattedFAD = finalArchDeadlineDate
				? getTzDate({
						date: finalArchDeadlineDate,
						timeZone,
						dateFormat,
				  })
				: ""
			const formattedEAD = expectedArchDeadlineDate
				? getTzDate({
						date: expectedArchDeadlineDate,
						timeZone,
						dateFormat,
				  })
				: ""
			const formattedCreatedAt = createdAt
				? getTzDate({
						date: createdAt,
						timeZone,
						dateFormat,
				  })
				: ""

			return {
				groupName,
				clientName,
				clientAliasId,
				projectId,
				projAliasId,
				projTitle,
				engTypeName,
				raName,
				statusName,
				periodName,
				version,
				isLocked,
				archPolicyPeriodId,
				archPolicyPeriodName,
				createdBy,
				engPartner,
				clientAddress,
				fee,
				periodEndDate: formattedPED,
				expectedReportDate: formattedERD,
				finalReportDate: formattedFRD,
				finalArchDeadlineDate: formattedFAD,
				expectedArchDeadlineDate: formattedEAD,
				createdAt: formattedCreatedAt,
				replicaId, // Need to update
			}
		},
		viewProjectUsers() {
			return self.projectUsers
		},
		viewCabinetLocDetails() {
			return self.cabinetLocationDetails
		},
	}))
	// ---------
	.actions(GetOrgProjectList)
	.actions(GetRemindProjectList)
	// Update proj info dialog
	.actions(GetProjInfo)
	.actions(EditProjInfo)
	.actions(GetActiveEngTypeList)
	.actions(GetActiveRiskAssessmentList)
	//
	.actions(AddProject)
	.actions(AddOtherProject)
	.actions(GetClientsByGroup)
	.actions(GetRfProjByGroup)
	.actions(GetUsersByGroup)
	.actions(RemoveProject)
	.actions(GetProjPeriodTypes)
	.actions(GetArchPolicyPeriodList) // TODO: @Noah Which one is the right?
	.actions(GetArchPolicyPeriodListByEngTypeId)
	//
	.actions(ForceArchive)
	// Filter related views
	.views(ProjectTableFilterViews)
	//
	.actions(GetUsersAndFilesCountOfProjects)
	.actions(GetProjUsersAndFileCount)
	.actions(GetProjectDetails)
	//PreProcess
	.actions(GetPreConditionStatus)
	// common actions
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/projects")
			applySnapshot(self, initialStore)
		},
	}))

	.actions(RefreshForProjects)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgProjectsViewModel
