import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const GetOrgSetupProjectAdminSettings = (self: any) => ({
	getOrgSetupProjectAdminSettings() {
		// 0.
		const actionName = "getOrgSetupProjectAdminSettings"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.readOrgSetupProjectAdminSettings()
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					const data = response.data.Data

					const projectAdminSettings = {
						includePBCdocuments: data.IncludePBCdocuments,
						replicaProjectAssignUserEligibility:
							data.ReplicaProjectAssignUserEligibility,
						replicaTeamMemberConfiguration:
							data.ReplicaTeamMemberConfiguration,
						createdAt: data.CreatedAt,
						createdByUserId: data.CreatedByUserId,
						createdByUserName: data.CreatedByUserName,
						updatedAt: data.UpdatedAt,
						updatedByUserId: data.UpdatedByUserId,
						updatedByUserName: data.UpdatedByUserName,
					}

					self.setProjAdminSettingData(projectAdminSettings)
					self.setNeedRefresh(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Successfully fetched project admin settings",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,

						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetOrgSetupProjectAdminSettings
