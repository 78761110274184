import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { postWithQueryString } from "../../../../../library/api-requests/request-post-others"
import { getRequest, postRequest } from "../../../../../library/api-requests"

export function create(params: { Title: string; Description: string }) {
	const response = postRequest({
		url: "/CreateArchivePolicy",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function read() {
	const response = getRequest({
		url: "/GetArchivePolicy",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function update(payload: {
	Title: string
	Description: string
	Id: number
}) {
	const response = postRequest({
		url: "/CreateArchivePolicy",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function del(payload: { ArchivePolicyId: number }) {
	const response = postRequest({
		url: `/DeleteArchivePolicy?ArchivePolicyId=${payload.ArchivePolicyId}`,
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

export type ERPDataApiProps = {
	apiRead: () => Promise<AxiosResponse<any>>
	apiUpdate: (payload: {
		Title: string
		Description: string
		Id: number
	}) => Promise<AxiosResponse<any>>
	apiDelete: (payload: {
		ArchivePolicyId: number
	}) => Promise<AxiosResponse<any>>
	apiCreate: (params: {
		Title: string
		Description: string
	}) => Promise<AxiosResponse<any>>
}
