import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const AddRiskAssessment = (self: any) => ({
	addRiskAssessment(props: {
		SortOrder: number
		RiskAssessmentItemName: string
		IsActive: boolean
	}) {
		// 0.
		const actionName = "addRiskAssessment"
		// 1.
		const checkedProps = hasEmptyProps({
			SortOrder: props.SortOrder,
			RiskAssessmentItemName: props.RiskAssessmentItemName,
		})
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.createRiskAssessment({
			SortOrder: props.SortOrder,
			RiskAssessmentItemName: props.RiskAssessmentItemName,
			IsActive: props.IsActive,
		})
			.then((response: any) => {
				if (response.data.Status === 1) {
					const { Data } = response.data
					const reOrganizedRiskAssessment = {
						riskAssessmentId: idToString(
							Data.RiskAssessmentItemId,
							IdType.ra
						), // [RiskAssessmentID], ex) 159
						riskAssessmentName: Data.RiskAssessmentItemName,
						createdAt: Data.CreatedAtUtc, // [CreatedDate], ex) "10/14/19 6:32:53 PM"
						createdBy: Data.CreatedByUserName, // [CreatedUser], ex) "cskim 10/14/2019"
						createdByUserId: Data.CreatedByUserId,
						modifiedAt: Data.ModifiedAtUtc,
						modifiedBy: Data.ModifiedByUserName || "-",
						modifiedByUserId: Data.ModifiedByUserId,
						activatedAt: Data.ActivatedAtUtc,
						activatedBy: Data.ActivatedByUserName || "-",
						activatedByUserId: Data.ActivatedByUserId,
						deActivatedAt: Data.DeActivatedAtUtc,
						deActivatedBy: Data.DeActivatedByUserName || "-",
						deActivatedByUserId: Data.DeActivatedByUserId,
						sortOrder: Data.SortOrder,
						isActive: Data.IsActive,
					}
					// ConsoleLog(reOrganizedRiskAssessment)
					self.pushItemToList(reOrganizedRiskAssessment)
					// close the dialog
					self.setAddRiskAssessmentDialogOpen(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to add a new Risk Assessment",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.Message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddRiskAssessment
