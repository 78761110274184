import { flow } from "mobx-state-tree"
import { OrgProjArchDatePolicyApiProps } from "../org-proj-arch-date-policies.apis"

const RequestMoveEngType = (
	self: any,
	moveEngType: OrgProjArchDatePolicyApiProps["apiMoveEngType"]
) => ({
	requestMoveEngType: flow(function* (payload) {
		const actionName = "requestMoveEngType"

		try {
			const response = yield moveEngType(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default RequestMoveEngType
