import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const EditEngType = (self: any) => ({
	editEngType({
		engagementId,
		modifiedByUserName,
		engTypeName,
		sortOrder,
		isActive,
	}: {
		engagementId: string
		modifiedByUserName: string
		engTypeName?: string
		sortOrder?: number
		isActive?: boolean
	}) {
		// 0.
		const actionName = "editEngType"

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const payload = {
			EngagementTypeItemId: idToNumber(engagementId, IdType.engType),
			EngagementTypeItemName: engTypeName,
			IsActive: isActive,
			SortOrder: sortOrder,
		}
		// ConsoleLog(payload)
		self.updateEngType(payload)
			.then((response: any) => {
				// if success
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.Status === 1) {
					self.updateEngStore(
						engagementId,
						modifiedByUserName,
						new Date().toISOString(),
						engTypeName,
						sortOrder,
						isActive
					)
					// close the dialog
					self.setOpenEdit(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to update the engagement type",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditEngType
