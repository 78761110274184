import { types } from "mobx-state-tree"

export const RiskAssessment = types.model({
	riskAssessmentId: types.string,
	riskAssessmentName: types.string,
	createdAt: types.string,
	createdByUserId: types.number,
	createdBy: types.string,
	modifiedByUserId: types.union(types.number, types.null),
	modifiedAt: types.union(types.string, types.null),
	modifiedBy: types.union(types.string, types.null),
	sortOrder: types.number,
	isActive: types.boolean,
	activatedByUserId: types.union(types.number, types.null),
	activatedAt: types.union(types.string, types.null),
	activatedBy: types.union(types.string, types.null),
	deActivatedByUserId: types.union(types.number, types.null),
	deActivatedAt: types.union(types.string, types.null),
	deActivatedBy: types.union(types.string, types.null),
})

export const ActiveRiskAssessment = types.model({
	riskAssessmentId: types.string,
	riskAssessmentName: types.string,
	isActive: types.boolean,
})

export const ProjectDetails = types.model({
	periodName: types.string,
	year: types.number,
	clientName: types.string,
	projectName: types.string,
	projectAliasId: types.string,
	projectStatus: types.string, // TODO: @Prudhvi-0817: does this mean the project status? if yes, please update
})
