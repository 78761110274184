import { types } from "mobx-state-tree"
import OrgEngTypeList from "./org-eng-types.model"
import OrgEngTypesViewModel from "./org-eng-types.view-model"
import OrgEngTypesUiHelper from "./org-eng-types.ui-helper"
import { OrgEngTypeApiProps } from "./org-eng-types.apis"
// apis
import * as api from "./org-eng-types.apis"

export const OrgSetupEngTypesStore = ({
	apiCreate,
	apiRead,
	apiReadActive,
	apiUpdate,
	apiDelete,
	apiReadChecklistTemplates,
	apiReadChecklistConnections,
}: OrgEngTypeApiProps) =>
	types
		.compose(
			OrgEngTypeList({
				apiCreate,
				apiRead,
				apiReadActive,
				apiUpdate,
				apiDelete,
				apiReadChecklistTemplates,
				apiReadChecklistConnections,
			}),
			OrgEngTypesViewModel,
			OrgEngTypesUiHelper
		)
		.named("OrgSetupEngTypesStore")

export const initialStore = {
	engagementId: "",
	// ui-helper
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	openAdd: false,
	openEdit: false,
	openEditConnectedChecklist: false,
	openDelete: false,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgSetupEngTypesStoreInstance = OrgSetupEngTypesStore({
	apiCreate: api.create,
	apiRead: api.readEngType,
	apiReadActive: api.readActiveEngType,
	apiUpdate: api.update,
	apiDelete: api.del,
	apiReadChecklistConnections: api.readChecklistConnections,
	apiReadChecklistTemplates: api.readChecklistTemplates,
}).create(initialStore)

export default OrgSetupEngTypesStoreInstance
