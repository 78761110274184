import { flow } from "mobx-state-tree"
import { OrgEngTypeApiProps } from "../org-eng-types.apis"

const CreateEngType = (
	self: any,
	apiCreate: OrgEngTypeApiProps["apiCreate"]
) => ({
	createEngType: flow(function* (payload) {
		const actionName = "createEngType"
		try {
			const response = yield apiCreate(payload)

			// ConsoleLog("createEngType response", response)
			return response
		} catch (error) {
			// console.error("createEngType error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default CreateEngType
