import React, { useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { DLDialog } from "../../../../../../components/basic-elements"
// import ERPDataForm from "./ERPDataForm"
import { addTzToDate } from "../../../../../../library/converters/date-utc-converter"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"

export default observer(function MapAULToERPDialog() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "mapAULToERP"
	//
	const [inputs, setInputs] = useState({
		erpClientMasterAliasId: "",
		erpClientMasterName: "",
		erpEngagementMasterAliasId: "",
		erpEngagementMasterName: "",
		erpEngagementYear: "",
		erpEngagementType: "",
		erpEngagementOwner: "",
		erpProjectAliasId: "",
		erpProjectName: "",
		erpProjectOwner: "",
		erpReportDate: "",
		erpReportReleaseDate: "",
	})
	let inputReady = inputs.erpClientMasterAliasId !== ""

	const dntFormat = store.global.getDntFormat
	const timeZone = store.global.timeZone
	const tzValue = timeZone.uiValue
	//
	const handleChange = useCallback((event: any) => {
		let name = event.target.id
		let value = event.target.value
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	const handleDateInput = (name: any, date: any) => {
		const newDate = addTzToDate(date, tzValue)

		setInputs((inputs: any) => ({
			...inputs,
			[name]: newDate,
		}))
	}
	//
	const handleAdd = () => {}

	return (
		<DLDialog
			eleTestId="map-aul-to-erp-dialog"
			isOpen={orgStore.mappingAULToERP.mapAULToERPDialogOpen}
			setIsOpen={orgStore.mappingAULToERP.setMapAULToERPDialogOpen}
			handleAction={handleAdd}
			showCloseBtn={true}
			dialogTitle={"Map AUL to ERP"}
			dialogContents={
				<>
					{/* <ERPDataForm
					inputs={inputs}
					handleChange={handleChange}
					handleDateInput={handleDateInput}
					dntFormat={dntFormat}
				/> */}
				</>
			}
			showOpenBtn={false}
			cancelBtnText={"Cancel"}
			actionReady={inputReady}
			actionBtn={"Map"}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				orgStore.mappingAULToERP.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.mappingAULToERP.getActionStatus(actionName) ===
				"LOADING"
			}
		/>
	)
})
