import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import {
	DLIconButton,
	DLButton,
	DLContextMenuWithButton,
	DLRadio,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import Icon from "@mdi/react"
import { mdiDotsHorizontal, mdiMenuDown } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import { AddRiskAssessmentBtn } from "../dialogs/AddRiskAccessmentDialog"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { dateRangeFilter } from "../../../../../library/react-table-utils"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { DLCheckbox } from "@datalobby/components"
import { StyledHeader } from "../../org-setup-users/sub-components/OrgSetupUserListTable"
import styled from "styled-components"

export default observer(function OrgSetupRiskAssessmentTable({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_risk
	const contentHeight = store.ui.contentsAreaHeight

	const handleContextMenu = (event: any, riskAssessmentId: string) => {
		event.preventDefault()
		orgStore.setupRiskAssessment.setSelectedRiskAssessment(riskAssessmentId)
		orgStore.setupRiskAssessment.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}
	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue
	const dntFormat = store.global.getDntFormat
	const selectedRaId = orgStore.setupRiskAssessment.selectedRiskAssessment

	const handleCheckbox = (id: string) => {
		orgStore.setupRiskAssessment.setSelectedRiskAssessment(id)
	}

	const active = orgStore.setupRiskAssessment
		.formattedList(dntFormat)
		.filter((i) => i.isActive)
	const inactive = orgStore.setupRiskAssessment
		.formattedList(dntFormat)
		.filter((i) => !i.isActive)

	const pageHeader = (instance: Table<any>) => {
		const totalRA = instance.getPreFilteredRowModel().flatRows.length
		const filteredRA = instance.getFilteredRowModel().flatRows.length

		return (
			<StyledHeader>
				<div className="FR AC">
					{permission.create && <AddRiskAssessmentBtn i18n={i18n} />}
				</div>
				<div className="FR" style={{ marginTop: 10 }}>
					<DLContextMenuWithButton
						eleTestId="folders-btn"
						menuOptions={[
							{
								label: "Edit Risk Assessment Info",
								value: "ctx-edit-ra",
								available: permission.update,
								disabled: !selectedRaId,
								clickEvent: () =>
									orgStore.setupRiskAssessment.setEditRiskAssessmentDialogOpen(
										true
									),
							},
							{
								label: `Delete Risk Assessment`,
								value: "ctx-delete-ra",
								available: permission.delete,
								disabled: !selectedRaId,
								clickEvent: () =>
									orgStore.setupRiskAssessment.setRemoveRiskAssessmentDialogOpen(
										true
									),
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Item Actions"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>
				<div
					className="FR AC counter-container"
					style={{ marginTop: 10 }}
				>
					<div className="counter-container">
						Active Risk Assessments ({active.length})
					</div>
				</div>
			</StyledHeader>
		)
	}

	return (
		<StyledTable>
			<div className="active">
				<ReactTableV8
					tableColumns={columns({
						type: "active",
						selectedId: selectedRaId,
						handleCheckbox,
						handleContextMenu,
					})}
					data={active}
					hasPagination={false}
					// customFilters={(props: any) =>
					// 	filterComponent({ ...props, dateFormat })
					// }
					handleContextMenu={(e: any, row: any) =>
						handleContextMenu(e, row.riskAssessmentId)
					}
					height={store.ui.contentsAreaHeight / 2}
					permission={permission}
					i18n={i18n}
					pageHeader={pageHeader}
					menuId={menuId}
					onRowClick={(event: any, row: any) =>
						handleCheckbox(row.riskAssessmentId)
					}
				/>
			</div>

			<div className="inactive">
				<div className="counter-container">
					Inactive Risk Assessments ({inactive.length})
				</div>
				<ReactTableV8
					tableColumns={columns({
						type: "inactive",
						selectedId: selectedRaId,
						handleCheckbox,
						handleContextMenu,
					})}
					data={inactive}
					hasPagination={false}
					showPageSetting={false}
					height={store.ui.contentsAreaHeight / 2}
					menuId="InActive"
					onRowClick={(event: any, row: any) =>
						handleCheckbox(row.riskAssessmentId)
					}
				/>
			</div>
		</StyledTable>
	)
})

const StyledTable = styled.div`
	margin-left: 8px;
	.inactive {
		margin-top: 2rem;
	}
	.counter-container {
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: 4px;
	}
	.pageHeader {
		padding: 0 !important;
	}
	table {
		border: 1px solid rgba(224, 224, 224, 1);
	}
	table > thead > tr > th {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
	table > tbody > tr > td {
		padding: 8px;
	}
`

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const columns = ({
	type,
	selectedId,
	handleCheckbox,
	handleContextMenu,
}: {
	type: string
	selectedId: string
	handleCheckbox: (raId: string) => void
	handleContextMenu: any
}) => [
	{
		header: "Select",
		accessorKey: "id",
		size: 30,
		cell: (props: any) => {
			const id = props.row.original.riskAssessmentId
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					<DLRadio
						eleTestId={id + "-radio"}
						checked={id === selectedId}
						onChange={() => handleCheckbox(id)}
						color="primary"
					/>
				</div>
			)
		},
	},
	{
		header: "Sorting Order",
		accessorKey: "sortOrder",
		size: 30,
	},
	{
		header: "Actions",
		accessorKey: "actions",
		size: 30,
		cell: (props: any) => {
			const id = props.row.original.riskAssessmentId
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					<DLButton
						variant="text"
						eleTestId="project-list-context-btn"
						clickHandler={(event: any) =>
							handleContextMenu(event, id)
						}
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLButton>
				</div>
			)
		},
	},
	{
		header: "Risk Assessment Name",
		accessorKey: "riskAssessmentName",
		size: 250,
	},
	{
		header: "Created By",
		accessorKey: "createdBy",
	},
	{
		header: "Created At",
		accessorKey: "createdAt",
	},
	{
		header: "Modified By",
		accessorKey: "modifiedBy",
	},
	{
		header: "Modified At",
		accessorKey: "modifiedAt",
	},
	{
		header: type === "active" ? "Activated By" : "Deactivated By",
		accessorKey: type === "active" ? "activatedBy" : "deActivatedBy",
	},
	{
		header: type === "active" ? "Activated At" : "Deactivated At",
		accessorKey: type === "active" ? "activatedAt" : "deActivatedAt",
	},
]
