import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const EditRiskAssessment = (self: any) => ({
	editRiskAssessment({
		RiskAssessmentItemId,
		RiskAssessmentItemName,
		SortOrder,
		IsActive,
		modifiedByUserName,
	}: {
		RiskAssessmentItemId: string
		RiskAssessmentItemName?: string
		SortOrder?: number
		IsActive?: boolean
		modifiedByUserName: string
	}) {
		// 0.
		const actionName = "editRiskAssessment"
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		const payload = {
			RiskAssessmentItemId: idToNumber(
				RiskAssessmentItemId.toString(),
				IdType.ra
			),
			RiskAssessmentItemName,
			SortOrder,
			IsActive,
		}
		// ConsoleLog(payload)
		self.updateRiskAssessment(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.updateRiskAssessmentStore(
						new Date().toISOString(), // [CreatedDate], ex) "10/14/19 6:32:53 PM"
						modifiedByUserName,
						RiskAssessmentItemId,
						RiskAssessmentItemName,
						SortOrder,
						IsActive
					)
					// close the dialog
					self.setEditRiskAssessmentDialogOpen(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to update the Risk Assessment",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditRiskAssessment
