import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// separated actions
import GetAULMappedERP from "./view-model-actions/get-aul-mapped-erp-data"

import { initialStore } from "./map-aul-to-erp.provider"
import { ERPDataTableModel } from "./data-models/aul-mapped-erp-data.data-model"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"

const AULMappedERPViewModel = types
	.model({
		aulMappedERPDataList: types.array(ERPDataTableModel),
		selectedItem: 0,
		clickPoint: ClickPoint,
		bulkMapERPDialogOpen: false,
		mapAULToERPDialogOpen: false,
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setAULMappedERPDataList(clients: any) {
			self.aulMappedERPDataList.length = 0
			self.aulMappedERPDataList = clients
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedItem(item: number) {
			self.selectedItem = item
		},
		setBulkMapERPDialogOpen(request?: boolean) {
			self.bulkMapERPDialogOpen = request ?? !self.bulkMapERPDialogOpen
		},
		setMapAULToERPDialogOpen(request?: boolean) {
			self.mapAULToERPDialogOpen = request ?? !self.mapAULToERPDialogOpen
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	.actions(GetAULMappedERP)
	//
	.views((self) => ({
		viewAULMappedERPDataList() {
			return self.aulMappedERPDataList
		},
		viewSelectedItemInfo() {
			return self.aulMappedERPDataList.find(
				(i: any) => i.id === self.selectedItem
			)
		},
	}))
	// common actions
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default AULMappedERPViewModel
