import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { CannotAccess } from "../../../../components/basic-elements"
import OrgSetupProjectAdminSettings from "./OrgSetupProjectAdminSettings"

export default observer(function OrgSetupProjectAdminSettingsController() {
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_replica_settings
	const partialStore = orgStore.orgSetupProjAdminSettings
	// access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const actionName = "getOrgSetupProjectAdminSettings"

	const needRefresh = partialStore.needRefresh

	useEffect(() => {
		if (menuAccess && needRefresh) {
			partialStore.getOrgSetupProjectAdminSettings()
		}
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<OrgSetupProjectAdminSettings
					fetchingStatus={partialStore.getActionStatus(actionName)}
					permission={permission}
					partialStore={partialStore}
				/>
			) : (
				<CannotAccess />
			)}
		</>
	)
})
