import { types } from "mobx-state-tree"

export const OrgSetupProjectAdminSettingsModel = types.model({
	includePBCdocuments: types.number,
	replicaProjectAssignUserEligibility: types.number,
	replicaTeamMemberConfiguration: types.number,
	createdAt: types.string,
	createdByUserId: types.number,
	createdByUserName: types.string,
	updatedAt: types.string,
	updatedByUserId: types.number,
	updatedByUserName: types.string,
})
