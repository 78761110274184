import { types } from "mobx-state-tree"

export const ArchiveCondition = types.model({
	ArchiveDatePolicyItemId: types.number,
	DaysString: types.union(types.null, types.string),
	IsActive: types.boolean,
	ActivatedAtUtc: types.union(types.null, types.string),
	ActivatedByUserName: types.union(types.null, types.string),
	ActivatedByUserId: types.union(types.null, types.number),
	SortOrder: types.number,
	DeActivatedAtUtc: types.string,
	DeActivatedByUserName: types.string,
	DeActivatedByUserId: types.number,
	CreatedAtUtc: types.string,
	CreatedByUserName: types.string,
	CreatedByUserId: types.number,
	ModifiedAtUtc: types.string,
	ModifiedByUserName: types.string,
	ModifiedByUserId: types.number,
})

export const ArchDatePolicyFlatItem = types.model({
	id: types.string,
	title: types.string,
	description: types.union(types.null, types.string),
	parentId: types.union(types.null, types.string),
	expanded: types.boolean,
	type: types.enumeration(["policy", "engType"]),
	archiveDatePolicyItems: types.union(
		types.null,
		types.array(types.frozen())
	),
	isActive: types.boolean,
	isDefault: types.boolean,
})
