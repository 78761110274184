import React from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
//
import {
	ConsoleLog,
	DLButton,
	DLContextMenuWithButton,
	DLIconButton,
} from "../../../../../components/basic-elements"
//
import Icon from "@mdi/react"
import { mdiDotsHorizontal, mdiMenuDown, mdiPlus } from "@mdi/js"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { StyledHeader } from "../../../org-setup/org-setup-users/sub-components/OrgSetupUserListTable"

export default observer(function MappingAULToERPTable({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" __________// MappingAULToERPTable //__________ ")

	const store = useRootStore()
	const orgStore = useOrgStore()
	// const [selectedClients, setSelectedClients] = useState<any>([])

	const handleContextMenu = (event: any, id: number) => {
		event.preventDefault()
		orgStore.mappingAULToERP.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		orgStore.mappingAULToERP.setSelectedItem(id)
	}

	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue
	const selectedItem = orgStore.mappingAULToERP.selectedItem

	const pageHeader = (instance: Table<any>) => {
		const totalERPData = instance.getPreFilteredRowModel().flatRows.length
		const filteredClients = instance.getFilteredRowModel().flatRows.length

		return (
			<StyledHeader>
				{permission.create && (
					<div className="FR">
						<DLButton
							eleTestId="bulk-import-client-btn"
							clickHandler={() =>
								orgStore.mappingAULToERP.setBulkMapERPDialogOpen(
									true
								)
							}
							variant="contained"
							color="primary"
							size="regular"
							eleClassName="top-row-btns"
						>
							Bulk Map AUL To ERP
						</DLButton>
					</div>
				)}
				<div className="FR AC" style={{ marginTop: 10 }}>
					{(permission.update || permission.delete) && (
						<DLContextMenuWithButton
							eleTestId="folders-btn"
							menuOptions={[
								{
									label: `Map`,
									value: "ctx-map-erp-data",
									available: permission.update,
									disabled: selectedItem === 0,
									// clickEvent: () =>
									// 	orgStore.mappingAULToERP.setOpenEditDialog(
									// 		true
									// 	),
								},
								{
									label: `Remove`,
									value: "ctx-remove-erp-data",
									available: permission.delete,
									disabled: selectedItem === 0,
									// clickEvent: () =>
									// 	orgStore.erpData.setRemoveClientDialogOpen(
									// 		true
									// 	),
								},
							]}
							useScroll={false}
							showButton={true}
							showIcon={false}
							btnText="Item Actions"
							btnVariant="outlined"
							btnColor="primary"
							btnSize="regular"
							btnClassName="ctx-menu-styles"
							eleIcon={<Icon path={mdiMenuDown} size={1} />}
						/>
					)}

					<DLContextMenuWithButton
						eleTestId="info-btn"
						menuOptions={[
							{
								label: "Details",
								value: "ctx-erp-info",
								available: permission.update,
								disabled: selectedItem === 0,
								// clickEvent: () =>
								// 	orgStore.erpData.setAssignClientToGroupDialogOpen(true),
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Item Info"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles ml-8"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>
				<div
					className="FR AC counter-container"
					style={{ marginTop: 10 }}
				>
					<BasicCounter
						label="Total ERP Data: "
						value={totalERPData}
					/>

					{/* {totalERPData > filteredClients && (
						<div className="FR AC">
							<span className="partition">|</span>
							<div className="filtered-count">
								Filtered Clients: {filteredClients}
							</div>
							<span className="partition">|</span>
							<DLButton
								eleTestId="clear-filters"
								clickHandler={instance.resetColumnFilters}
							>
								Clear Filters
							</DLButton>
						</div>
					)} */}
				</div>
			</StyledHeader>
		)
	}

	return (
		<ReactTableV8
			tableColumns={columns(handleContextMenu)}
			data={orgStore.mappingAULToERP.viewAULMappedERPDataList()}
			hasPagination={true}
			customFilters={(props: any) =>
				filterComponent({ ...props, dateFormat })
			}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.id)
			}
			height={store.ui.contentsAreaHeight - 60}
			permission={permission}
			pageHeader={pageHeader}
			menuId={DLOrgSubMenus.mapping_aul_to_erp}
			onRowClick={(event: any, row: any) =>
				orgStore.mappingAULToERP.setSelectedItem(row.id)
			}
		/>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const columns = (handleContextMenu: any) => {
	return [
		{
			header: "S.No",
			accessorKey: "rowIndex",
		},
		{
			header: "Actions",
			accessorKey: "actions",
			cell: (props: any) => {
				const erpId = props.row.original.id
				return (
					<div className="FR AC JC" style={{ width: "100%" }}>
						<DLIconButton
							aria-label="more"
							clickHandler={(event: any) =>
								handleContextMenu(event, erpId)
							}
							eleTestId="erp-ctx-btn-on-row"
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLIconButton>
					</div>
				)
			},
		},
		{
			header: "ERP Client Master AliasId",
			accessorKey: "erpClientMasterAliasId",
		},
		{
			header: "ERP Client Master Name",
			accessorKey: "erpClientMasterName",
		},
		{
			header: "ERP Engagement Master AliasId",
			accessorKey: "erpEngagementMasterAliasId",
		},
		{
			header: "ERP Engagement Master Name",
			accessorKey: "erpEngagementMasterName",
		},
		{
			header: "ERP Engagement Year",
			accessorKey: "erpEngagementYear",
		},
		{
			header: "ERP Engagement Type",
			accessorKey: "erpEngagementType",
		},
		{
			header: "ERP Engagement Owner",
			accessorKey: "erpEngagementOwner",
		},
		{
			header: "ERP Project AliasId",
			accessorKey: "erpProjectAliasId",
		},
		{
			header: "ERP Project Name",
			accessorKey: "erpProjectName",
		},
		{
			header: "ERP Project Owner",
			accessorKey: "erpProjectOwner",
		},
		{
			header: "ERP Report Date",
			accessorKey: "erpReportDate",
		},
		{
			header: "ERP Report Release Date",
			accessorKey: "erpReportReleaseDate",
		},
		{
			header: "AUL System Id",
			accessorKey: "aulSystemId",
		},
		{
			header: "AUL Period",
			accessorKey: "aulPeriod",
		},
		{
			header: "AUL GroupName",
			accessorKey: "aulGroupName",
		},
		{
			header: "AUL ClientMasterName",
			accessorKey: "aulClientMasterName",
		},
		{
			header: "AUL IsLocked",
			accessorKey: "aulIsLocked",
		},
		{
			header: "AUL ProjectTypeName",
			accessorKey: "aulProjectTypeName",
		},
		{
			header: "AUL ProjectName",
			accessorKey: "aulProjectName",
		},
		{
			header: "AUL ProjectAliasId",
			accessorKey: "aulProjectAliasId",
		},
		{
			header: "AUL Version",
			accessorKey: "aulVersion",
		},
		{
			header: "AUL EPUsers",
			accessorKey: "aulEPUsers",
		},
		{
			header: "AUL QCUsers",
			accessorKey: "aulQCUsers",
		},
		{
			header: "AUL FinalReportDate",
			accessorKey: "aulFinalReportDate",
		},
	]
}
