import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"

import { OrgSetupProjectAdminSettingsApiProps } from "./org-setup-project-admin-settings.apis"
import ReadOrgSetupProjectAdminSettings from "./model-actions/read-org-setup-project-admin-settings"
import UpdateOrgSetupProjectAdminSettings from "./model-actions/update-org-setup-project-admin-settings"

const OrgSetupProjectAdminSettingsModel = ({
	apiRead,
	apiUpdate,
}: OrgSetupProjectAdminSettingsApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self) => ReadOrgSetupProjectAdminSettings(self, apiRead))
		.actions((self) => UpdateOrgSetupProjectAdminSettings(self, apiUpdate))
		// common model actions
		.actions(CommonModelActions)

export default OrgSetupProjectAdminSettingsModel
