// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
	idToString,
} from "../../../../../../library/converters/id-converter"

const CopyArchiveDatePolicy = (self: any) => ({
	copyArchiveDatePolicy(policyId: string) {
		// 0.
		const actionName = "copyArchiveDatePolicy"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		const policyNumberId = idToNumber(policyId, IdType.archivePolicy)
		self.requestCopyArchiveDatePolicy(policyNumberId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response

					const policy = response.data.Data

					const policyId = idToString(
						policy.ArchiveDatePolicySetId,
						IdType.archivePolicy
					)

					const flatPolicy = {
						id: policyId,
						parentId: null,
						title: policy.ArchiveDatePolicySetName,
						description: policy.ArchiveDatePolicySetDescription,
						expanded: true,
						type: "policy",
						archiveDatePolicyItems: policy.ArchiveDatePolicyItems,
					}

					self.pushItemToFlatList(flatPolicy)

					// self.pushItemToFlatList(flatPolicy)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default CopyArchiveDatePolicy
