import React from "react"
import { observer } from "mobx-react-lite"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

const CheckFinalReportDateMatchController = observer(() => {
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.check_final_rep_date_match
	const menuAccess = orgStore.checkin.checkAccess(menuId)

	return (
		<>
			{menuAccess ? (
				<div>Check Final Report Date Match</div>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

export default CheckFinalReportDateMatchController
