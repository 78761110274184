import React from "react"
import { observer } from "mobx-react-lite"

import { PageContainer } from "../../../../components/app-frame-elements"
import { ConsoleLog } from "../../../../components/basic-elements"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import styled from "styled-components"
import { DLI18nProps } from "../../../../common-models/types/common-props"

import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import MappingAULToERPTable from "./sub-components/MappingAULToERPTable"
import MappingAULToERPCxtMenu from "./sub-components/MappingAULToERPCxtMenu"
import MapAULToERPDialog from "./sub-components/dialogs/MapAULToERPDialog"

export default observer(function MappingAULToERP({
	partialStore,
	actionStatus,
	permission,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={actionStatus === ActionStatus.loading}
		>
			{/* ----- Contents ----- */}
			{actionStatus === "SUCCESS" && (
				<StyledERPData>
					<MappingAULToERPTable permission={permission} />
				</StyledERPData>
			)}
			<MappingAULToERPCxtMenu permission={permission} />

			{partialStore.mapAULToERPDialogOpen && <MapAULToERPDialog />}
			{/* ---------- dialogs  ---------- */}

			{/* ---------- snackbar  ---------- */}
			<CommonSnackbarComponent i18n={{}} partialStore={partialStore} />
		</PageContainer>
	)
})

const StyledERPData = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.partition {
		margin: 0 0.5rem;
	}
`
