import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import {
	DLButton,
	DLDialog,
	ConsoleLog,
	DLSpinnerCenterAligned,
} from "../../../../../components/basic-elements"
import styled from "styled-components"
import Icon from "@mdi/react"
import {
	mdiArrowUp,
	mdiArrowDown,
	mdiPlus,
	mdiPause,
	mdiInformationOutline,
} from "@mdi/js"
import { DLTooltip } from "@datalobby/components"
import * as XLSX from "xlsx"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { handleExport } from "../../../../../components/basic-elements/export/ExportXLSX"
import { handleDownloadExcelTemplate } from "../../../../../library/bulk-import-utils/handle-download-template"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"

export default observer(function BulkImportERPDataDialog({
	partialStore,
}: {
	partialStore: any
}) {
	const actionName = "createMultipleERP"

	const [bulkERPList, setBulkERPList] = useState([])

	const handleBulkImport = () => {}

	const isReady = () => {
		const erpData = JSON.parse(JSON.stringify(bulkERPList))
		const emptyCheck =
			erpData.filter(
				(x: any) =>
					x.erpProjectAliasId === "" || x.erpProjectName === ""
			).length > 0

		return bulkERPList.length !== 0 && !emptyCheck
	}

	const erpData = JSON.parse(JSON.stringify(bulkERPList))

	const submitLoadingStatus = erpData.filter(
		(client: any) => client.status === ActionStatus.loading
	)

	return (
		<DLDialog
			eleTestId="bulk-add-erp-dialog"
			// isOpen
			isOpen={partialStore.bulkImportERPDialogOpen}
			setIsOpen={partialStore.setBulkImportERPDialogOpen}
			showOpenBtn={false}
			showCloseBtn
			dialogTitle="Bulk Import ERP Data"
			dialogContents={
				<BulkImportERPDialogContents
					bulkERPList={bulkERPList}
					setBulkERPList={setBulkERPList}
				/>
			}
			draggable
			actionBtn={
				<div className="FR JR" style={{ width: "100%" }}>
					<DLButton
						eleTestId="proceed-bulk-add"
						clickHandler={() => handleBulkImport()}
						color="primary"
						disabled={
							!isReady() || submitLoadingStatus.length !== 0
						}
					>
						Submit
					</DLButton>
				</div>
			}
			fullWidth
			maxWidth="lg"
		/>
	)
})

const BulkImportERPDialogContents = observer(
	({
		bulkERPList,
		setBulkERPList,
	}: {
		bulkERPList: any
		setBulkERPList: any
	}) => {
		const actionName = "createMultipleERP"

		const [fileReadStatus, setFileReadStatus] = useState<ActionStatus>(
			ActionStatus.standby
		)

		function handleFile(e: any) {
			var file = e.target.files[0]
			var reader = new FileReader()

			reader.onload = function (e) {
				if (e.target) {
					if (
						e.target.result !== null &&
						typeof e.target.result !== "string"
					) {
						var data = new Uint8Array(e.target.result)
						var workbook = XLSX.read(data, { type: "array" })

						const jsonData = XLSX.utils.sheet_to_json(
							workbook.Sheets.Sheet1
						)
						let organizedList: any[] = []
						// let duplicatedList: DuplicatedItemProps[] = []
						const hasEmptyFields = jsonData.every(
							(item: any) =>
								Object.keys(item).includes("erpProjectName") &&
								Object.keys(item).includes("erpProjectAliasId")
						)

						if (!hasEmptyFields) {
							alert(
								"Excel file contains empty fields. Please input correct data."
							)
							setFileReadStatus(ActionStatus.fail)
							return false
						}
						jsonData.map((item: any, i: any) => {
							// const aliasIdCheck =
							// 	jsonData.filter(
							// 		(x: any) =>
							// 			x.erpProjectAliasId ===
							// 			item.erpProjectAliasId
							// 	).length > 1
							// const nameCheck =
							// 	jsonData.filter(
							// 		(x: any) =>
							// 			x.erpProjectName === item.erpProjectName
							// 	).length > 1
							organizedList.push({
								id: `Id${i + 1}`,
								erpClientMasterAliasId:
									item.erpClientMasterAliasId,
								erpClientMasterName: item.erpClientMasterName,
								erpEngagementMasterAliasId:
									item.erpEngagementMasterAliasId,
								erpEngagementMasterName:
									item.erpEngagementMasterName,
								erpEngagementYear: item.erpEngagementYear,
								erpEngagementType: item.erpEngagementType,
								erpEngagementOwner: item.erpEngagementOwner,
								erpProjectAliasId: item.erpProjectAliasId,
								erpProjectName: item.erpProjectName,
								erpProjectOwner: item.erpProjectOwner,
								erpReportDate: item.erpReportDate,
								erpReportReleaseDate: item.erpReportReleaseDate,
								status: ActionStatus.standby,
								statusMessage: "",
							})
						})

						setBulkERPList(organizedList)
						setFileReadStatus(ActionStatus.success)
					}
				} else {
					ConsoleLog("event target is null")
				}
			}
			reader.readAsArrayBuffer(file)
		}

		const handleSelectFilesFromLocal = (e: any) => {
			handleFile(e)
			setFileReadStatus(ActionStatus.loading)
		}

		return (
			<StyledDialogContents className="bulk-add-erp-dialog">
				{fileReadStatus === ActionStatus.loading && (
					<DLSpinnerCenterAligned absolute backgroundOpacity={0.6} />
				)}
				<div className="FR JSB">
					<div className="FR JSB">
						<DLButton
							eleTestId="download-template"
							startIcon={<Icon path={mdiArrowDown} size={0.8} />}
							clickHandler={() =>
								handleDownloadExcelTemplate(
									[
										{
											erpClientMasterAliasId: "",
											erpClientMasterName: "",
											erpEngagementMasterAliasId: "",
											erpEngagementMasterName: "",
											erpEngagementYear: "",
											erpEngagementType: "",
											erpEngagementOwner: "",
											erpProjectAliasId: "",
											erpProjectName: "",
											erpProjectOwner: "",
											erpReportDate: "",
											erpReportReleaseDate: "",
										},
									],
									"Sample ERP Template"
								)
							}
							// disabled={clients.length === 0}
						>
							Download Template
						</DLButton>
						<DLButton
							eleTestId="choose-files-btn"
							startIcon={<Icon path={mdiArrowUp} size={0.8} />}
							color="primary"
						>
							<label className="file-upload-btn-wrapper">
								Choose A file
								<input
									type="file"
									name="file"
									onChange={handleSelectFilesFromLocal}
									data-testid="file-input"
									accept=".xlsx,.xls"
								/>
							</label>
						</DLButton>
						<div style={{ marginTop: "11px" }}>
							<DLTooltip
								title={
									<div>
										<b>Note :</b>
										<br />
										<span>
											1.Please make sure the sheet name is
											"Sheet1"
										</span>
									</div>
								}
								elePlacement="right"
							>
								<Icon path={mdiInformationOutline} size={1} />
							</DLTooltip>
						</div>
					</div>
					{/* {multipleERPStatus !== ActionStatus.success && (
						<div className="FR JSB">
							<DuplicatedItemList
								inputTotal={clients.length}
								dupItems={duplicatedItems}
								dupItemsInSheet={duplicatedItemsInSheet}
								handleRemoveDuplicated={handleRemoveDuplicated}
								handleExportDuplicated={handleExportDuplicated}
							/>
						</div>
					)} */}
				</div>
				<div>
					<ReactTableV8
						tableColumns={columns()}
						data={JSON.parse(JSON.stringify(bulkERPList)) || []}
						hasPagination={true}
						height={400}
						showPageSetting={false}
						menuId={"BulkImportERP"}
					/>
					{/* )} */}
				</div>
			</StyledDialogContents>
		)
	}
)

const StyledDialogContents = styled.div`
	.duplicated-items-count {
		.warning {
			color: ${(props) => props.theme.themeRed};
			font-weight: 700;
		}
	}
	input[type="file"] {
		display: none;
	}
	.header {
		font-weight: bold;
	}
	.FAIL {
		color: ${(props) => props.theme.themeRed};
	}
	.SUCCESS {
		color: ${(props) => props.theme.emphasis};
	}
	.LOADING {
		color: ${(props) => props.theme.themeOrange};
	}
`
const columns = () => {
	return [
		{
			header: "ERP Client Master AliasId",
			accessorKey: "erpClientMasterAliasId",
		},
		{
			header: "ERP Client Master Name",
			accessorKey: "erpClientMasterName",
		},
		{
			header: "ERP Engagement Master AliasId",
			accessorKey: "erpEngagementMasterAliasId",
		},
		{
			header: "ERP Engagement Master Name",
			accessorKey: "erpEngagementMasterName",
		},
		{
			header: "ERP Engagement Year",
			accessorKey: "erpEngagementYear",
		},
		{
			header: "ERP Engagement Type",
			accessorKey: "erpEngagementType",
		},
		{
			header: "ERP Engagement Owner",
			accessorKey: "erpEngagementOwner",
		},
		{
			header: "ERP Project AliasId",
			accessorKey: "erpProjectAliasId",
		},
		{
			header: "ERP Project Name",
			accessorKey: "erpProjectName",
		},
		{
			header: "ERP Project Owner",
			accessorKey: "erpProjectOwner",
		},
		{
			header: "ERP Report Date",
			accessorKey: "erpReportDate",
		},
		{
			header: "ERP Report Release Date",
			accessorKey: "erpReportReleaseDate",
		},
	]
}
