import { types } from "mobx-state-tree"
import ProjInfoModel from "./proj-info.model"
import { ProjInfoViewModel } from "./proj-info.view-model"
import ProjInfoUiHelper from "./proj-info.ui-helper"
import * as api from "./proj-info.apis"
import * as orgGroupApis from "../../../../service-modules/group-module/org-group-shared-apis"
import { readArchPolicyPeriodList } from "../../../organization-side/org-setup/org-setup-arch-policy-periods/arch-policy-period.apis"
import { readArchPolicyPeriodListByEngTypeId } from "../../../organization-side/org-setup/org-setup-arch-policy-periods/arch-policy-period-by-eng-type-id.apis"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { CreateProjectMethod } from "../../../../components/combined-elements/create-project/create-project.data-props"
import { readActiveEngType } from "../../../organization-side/org-setup/org-setup-eng-types/store/org-eng-types.apis"
import { readActiveRiskAssessment } from "../../../organization-side/org-setup/org-setup-risk-assessment/store/org-risk-assessment.apis"

export const ProjInfoStore = ({
	apiRead,
	apiUpdate,
	apiReadActiveEngType,
	apiReadActiveRiskAssessment,
	apiReadPeriodNames,
	apiReadClients,
	apiReadArchPolicyPeriodList,
	apiReadArchPolicyPeriodListByEngTypeId,
	apiUpdateArchiveChecklist,
	apiReadCabinetLocation,
}: api.ProjInfoApiProps) =>
	types
		.compose(
			ProjInfoModel({
				apiRead,
				apiUpdate,
				apiReadActiveEngType,
				apiReadActiveRiskAssessment,
				apiReadPeriodNames,
				apiReadClients,
				apiReadArchPolicyPeriodList,
				apiReadArchPolicyPeriodListByEngTypeId,
				apiUpdateArchiveChecklist,
				apiReadCabinetLocation,
			}),
			ProjInfoViewModel,
			ProjInfoUiHelper
		)
		.named("ProjInfoStore")

export const initialStore = {
	projInfo: {
		statusName: ProjectStatus.unknown,
		createMethod: CreateProjectMethod.byUndefined,
		hasExportAccess: false,
	},
	refresh: false,
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ProjInfoStoreInstance = ProjInfoStore({
	apiRead: api.read,
	apiUpdate: api.update,
	apiReadActiveEngType: readActiveEngType,
	apiReadActiveRiskAssessment: readActiveRiskAssessment,
	apiReadPeriodNames: api.readPeriodNames,
	apiReadClients: orgGroupApis.readClientsByGroup,
	apiReadArchPolicyPeriodList: readArchPolicyPeriodList,
	apiReadArchPolicyPeriodListByEngTypeId: readArchPolicyPeriodListByEngTypeId,
	apiUpdateArchiveChecklist: api.updateArchChecklist,
	apiReadCabinetLocation: api.readCabinetLocation,
}).create(initialStore)

export default ProjInfoStoreInstance
