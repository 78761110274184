import { types } from "mobx-state-tree"
import OrgSetupProjectAdminSettingsModel from "./org-setup-project-admin-settings.model"
import OrgSetupProjectAdminSettingsViewModel from "./org-setup-project-admin-settings.view-model"
import * as api from "./org-setup-project-admin-settings.apis"

export const OrgSetupProjectAdminSettingsStore = ({
	apiRead,
	apiUpdate,
}: api.OrgSetupProjectAdminSettingsApiProps) =>
	types
		.compose(
			OrgSetupProjectAdminSettingsModel({
				apiRead,
				apiUpdate,
			}),
			OrgSetupProjectAdminSettingsViewModel
		)
		.named("OrgSetupProjectAdminSettingsStore")

export const initialStore = {
	projectAdminSettingsData: {
		includePBCdocuments: 0,
		replicaProjectAssignUserEligibility: 0,
		replicaTeamMemberConfiguration: 0,
		createdAt: "",
		createdByUserId: 0,
		createdByUserName: "",
		updatedAt: "",
		updatedByUserId: 0,
		updatedByUserName: "",
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgSetupProjectAdminSettingsStoreInstance =
	OrgSetupProjectAdminSettingsStore({
		apiRead: api.read,
		apiUpdate: api.update,
	}).create(initialStore)

export default OrgSetupProjectAdminSettingsStoreInstance
