import React from "react"
import { Link } from "react-router-dom"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import OrgSidebarMenuList from "./OrgSidebarMenuList"
import Drawer from "@material-ui/core/Drawer"
import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js"
import { DLIconButton } from "../../../components/basic-elements/buttons"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import SidebarDateDisplay from "./SidebarDateDisplay"
import StyledSidebar from "./sidebar.style"
import { DLTooltip } from "@datalobby/components"
import keyboardJS from "keyboardjs"

export default observer(function OrgSidebar() {
	const store = useRootStore()
	const orgStore = useOrgStore()

	let orgTitle = orgStore.checkin.orgInfo.orgName

	return (
		<StyledSidebar
			drawerWidth={store.ui.drawerWidth}
			collapsedDrawerWidth={store.ui.collapsedDrawerWidth}
			data-testid="org-left-sidebar"
		>
			<Drawer
				variant="permanent"
				className={
					store.ui.isSidebarOpen
						? "drawer drawer-open org-drawer indent"
						: "drawer drawer-close org-drawer"
				}
				classes={{
					paper: store.ui.isSidebarOpen
						? "drawer drawer-open org-drawer indent"
						: "drawer drawer-close org-drawer",
				}}
				open={store.ui.isSidebarOpen}
			>
				<div
					className={
						store.ui.isSidebarOpen
							? `sidebar-header FR AC JSB`
							: `sidebar-header FR AC JC`
					}
				>
					<h1
						className={
							store.ui.isSidebarOpen
								? "company-title bold"
								: "company-title off"
						}
						data-testid="orgTitle-on-sidebar"
					>
						<DLTooltip title={orgTitle}>
							<Link
								to="/organization/my-page/recent"
								onClick={() => keyboardJS.pause()}
							>
								{orgTitle ? orgTitle : "Pending..."}
							</Link>
						</DLTooltip>
					</h1>

					<DLIconButton
						eleClassName="sidebar-on-off-button"
						variant="iconOnly"
						size="large"
						clickHandler={() =>
							store.ui.setSidebarOpen(!store.ui.isSidebarOpen)
						}
						eleTestId="left-sidebar on-off btn"
					>
						{store.ui.isSidebarOpen ? (
							<Icon path={mdiChevronLeft} size={1} />
						) : (
							<Icon path={mdiChevronRight} size={1} />
						)}
					</DLIconButton>
				</div>

				<SidebarDateDisplay
					// because tz.uiValue is required, cannot use dntFormat here
					tz={store.global.timeZone}
					tf={store.global.timeFormat.uiValue} // WARNING: UI VALUE!!!! Do not use value
					df={store.global.dateFormat.value}
					on={store.ui.isSidebarOpen}
				/>

				<OrgSidebarMenuList />
			</Drawer>
		</StyledSidebar>
	)
})
