import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function ERPDataCxtMenu({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()

	const menuOptions = [
		{
			label: "Details",
			value: "ctx-detail-erp-data",
			available: permission.read,
			// clickEvent: () =>
			// 	orgStore.erpData.setAssignClientToGroupDialogOpen(true),
		},
		{
			label: `Edit`,
			value: "ctx-edit-erp-data",
			available: permission.update,
			clickEvent: () => orgStore.erpData.setOpenEditDialog(true),
		},
		{
			label: `Remove`,
			value: "ctx-remove-erp-data",
			available: permission.delete,
			// clickEvent: () =>
			// 	orgStore.erpData.setRemoveClientDialogOpen(true),
		},
	]

	const handleClose = () => {
		orgStore.erpData.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="erp-data-ctx-menus"
			clickPoint={{
				mouseX: orgStore.erpData.clickPoint.mouseX,
				mouseY: orgStore.erpData.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[1]}
		/>
	)
})
