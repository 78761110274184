import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	postRequest,
	deleteRequest,
} from "../../../../../library/api-requests"
import { ChecklistRecordType } from "@datalobby/types/lib"

export function create(params: {
	EngagementTypeItemName: string
	IsActive: boolean
	SortOrder: number
}) {
	const response = postRequest({
		url: "/CreateEngagementTypeItem",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function readEngType() {
	const response = getRequest({
		url: "/GetAllEngagementTypeItems",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function readActiveEngType() {
	const response = getRequest({
		url: "/GetAllActiveEngagementTypeItems",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type ReadChecklistConnectionsProps = {
	orgId: string
	projectTypeId?: string
}
export function readChecklistConnections({
	orgId,
	projectTypeId,
}: ReadChecklistConnectionsProps) {
	let url = `/checklists`

	const response = getRequest({
		url,
		params: {
			orgId,
			type: ChecklistRecordType.connection,
			...(projectTypeId && { projectTypeId }),
		},
		tokenType: TOKEN_TYPE.cdkBackend,
	})
	return response
}
export function readChecklistTemplates(orgId: string) {
	let url = "/checklists"

	const response = getRequest({
		url,
		params: {
			orgId,
			type: ChecklistRecordType.template,
		},
		tokenType: TOKEN_TYPE.cdkBackend,
	})
	return response
}
export function update(params: {
	EngagementTypeItemId: number
	EngagementTypeItemName?: string
	SortOrder?: number
	IsActive?: boolean
}) {
	const response = postRequest({
		url: "/UpdateEngagementTypeItem",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function del(params: { ID: string; UserID: string }) {
	const response = deleteRequest({
		url: "/DeleteEngagementType",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgEngTypeApiProps = {
	apiCreate: (params: {
		EngagementTypeItemName: string
		IsActive: boolean
		SortOrder: number
	}) => Promise<AxiosResponse<any>>
	apiRead: () => Promise<AxiosResponse<any>>
	apiReadActive: () => Promise<AxiosResponse<any>>
	apiReadChecklistConnections: (
		params: ReadChecklistConnectionsProps
	) => Promise<AxiosResponse<any>>
	apiReadChecklistTemplates: (orgId: string) => Promise<AxiosResponse<any>>
	//
	apiUpdate: (params: {
		EngagementTypeItemId: number
		EngagementTypeItemName?: string
		SortOrder?: number
		IsActive?: boolean
	}) => Promise<AxiosResponse<any>>
	apiDelete: (params: {
		ID: string
		UserID: string
	}) => Promise<AxiosResponse<any>>
}
