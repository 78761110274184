import { flow } from "mobx-state-tree"
import { OrgRiskAssessmentApiProps } from "../org-risk-assessment.apis"

const CreateRiskAssessment = (
	self: any,
	apiCreate: OrgRiskAssessmentApiProps["apiCreate"]
) => ({
	createRiskAssessment: flow(function* (payload: {
		SortOrder: number
		RiskAssessmentItemName: string
		IsActive: boolean
	}) {
		const actionName = "createRiskAssessment"
		try {
			const response = yield apiCreate(payload)

			// ConsoleLog("createRiskAssessment response", response)
			return response
		} catch (error) {
			// console.error("createRiskAssessment error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default CreateRiskAssessment
