export const UpdateProjInfoActions = (self: any) => ({
	setProjInfo(projInfo: any) {
		self.projInfo = projInfo
	},
	setPeriodList(list: any) {
		self.periodNames.length = 0
		self.periodNames = list
	},
	setClientList(clients: any) {
		self.clientList.length = 0
		self.clientList = clients
	},
	setActiveEngTypeList(items: any) {
		self.activeEngTypeList.length = 0
		self.activeEngTypeList = items
	},
	setActiveRiskAssessmentList(items: any) {
		self.activeRiskAssessmentList.length = 0
		self.activeRiskAssessmentList = items
	},
	setArchPolicyPeriodList(items: any) {
		self.archPolicyPeriodList.length = 0
		self.archPolicyPeriodList = items
	},
})

export const UpdateProjInfoViews = (self: any) => ({
	viewEngTypeOptions() {
		return self.activeEngTypeList.map((engType: any) => ({
			id: engType.id,
			title: engType.name,
		}))
	},
	viewRiskAssessmentOptions() {
		return self.activeRiskAssessmentList.map((risk: any) => ({
			id: risk.riskAssessmentId,
			title: risk.riskAssessmentName,
		}))
	},
	viewPeriodNames() {
		let options: { id: string; title: string }[] = []
		self.periodNames.map((period: any) => {
			options.push({
				id: period.id,
				title: period.title,
			})
		})
		return options
	},
	viewClientsList() {
		let options: {
			id: string
			title: string
			clientAliasId: string
		}[] = []
		self.clientList.map((client: any) => {
			options.push({
				id: client.clientId,
				title: client.clientName,
				clientAliasId: client.clientAliasId,
			})
		})
		// ConsoleLog("viewClients", options)
		return options
	},
	viewArchPolicyPeriodList() {
		return self.archPolicyPeriodList.map((period: any) => ({
			value: period.id,
			name: period.title,
		}))
	},
	getArchiveDays(id: string) {
		return self.archPolicyPeriodList.find((period: any) => period.id === id)
			?.days
	},
})
