import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import MappingAULToERP from "./MappingAULToERP"

const MappingAULToERPController = observer(() => {
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.mapping_aul_to_erp
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const actionName = "getAULMappedERP"

	const needRefresh = orgStore.mappingAULToERP.needRefresh

	useEffect(() => {
		menuAccess && needRefresh && orgStore.mappingAULToERP.getAULMappedERP()
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<MappingAULToERP
					partialStore={orgStore.mappingAULToERP}
					actionStatus={orgStore.mappingAULToERP.getActionStatus(
						actionName
					)}
					permission={permission}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

export default MappingAULToERPController
