import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetAssignUserEligibility = (self: any) => ({
	getAssignUserEligibility() {
		const actionName = "getAssignUserEligibility"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API
		self.readAssignUserEligibility()
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					self.setAssignUserEligibility(
						response.data.Data.CanAssignUser
					)
					// when success
					// self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// when fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetAssignUserEligibility
