import React from "react"
import SortableTree from "react-sortable-tree"
import { observer } from "mobx-react-lite"
import StyledTreeWithoutExpandBtn from "./StyledTreeWithoutExpandBtn"
import { DLFileProps } from "../../../service-modules/file-module/data-models/file.data-model"
import ConsoleLog from "../ConsoleLog"

// interface Node {
// 	id: string
// 	title: string
// 	parentId: string
// 	description?: string
// 	detail?: any
// 	type?: string
// 	aliasId?: string | null
// }
export type GenerateNodeDataProps = {
	isSearchFocus: boolean
	isSearchMatch: boolean
	lowerSiblingCounts: any
	node: DLFileProps // updated at 06/12/2020
	parentNode: null | string
	path: string[]
	treeIndex: number
}

export type OnMoveNodeDataProps = {
	nextParentNode: DLFileProps
	nextPath: string[]
	nextTreeIndex: number
	node: DLFileProps
	path: string[]
	prevPath: string[]
	prevTreeIndex: number
	treeData: DLFileProps[]
	treeIndex: number
}

// NOTE:
// Do not add search related variables or actions here directly. It should work on TreeNav (the parent of this component)
// Do not add expand/collapse of row related variables or actions here
interface DLTreeListByTreeDataProps {
	treeData: { title: string; [x: string]: any }[] // Should be updated
	// functions for control
	getDetail?: (targetId: string, isRecursive: boolean) => void // to get sub items // TODO: remove?
	expandAll?: boolean
	//
	showCheckbox?: boolean
	rowHeight?: "thin" | "medium" | "large" | "relative" //  for custom styling. This is not a default props of the package
	rowDirection?: "ltr" | "rtl"
	canDrag?: boolean | ((props: any) => boolean)
	eleOnChange?: (props: any) => void
	eleRenderNode: (props: any) => JSX.Element
	eleHeight?: number
	eleWidth?: number | string
	indentWidth?: number
	sortBy?: string
	placeholderRenderer?: any
	onMoveNode?: any
	onDragStateChanged?: any
	onVisibilityToggle?: any
	eleClassName?: string
	//
	externalNodeType?: any // to add by DnD
}

export type DLTreeRenderRowProps = {
	node: any
	depth: number
	canDrag: boolean
	eleWidth: number
	indentWidth: number
	syncExpandedStatus: any
}

export default observer(function DLTreeListByTreeData({
	//
	treeData,
	//
	getDetail,
	expandAll,
	//
	showCheckbox = false,
	rowHeight = "relative",
	rowDirection,
	canDrag = true,
	eleOnChange,
	eleRenderNode,
	eleHeight = 900,
	eleWidth = 1200,
	indentWidth = 40,
	//
	sortBy,
	placeholderRenderer,
	onMoveNode,
	onDragStateChanged,
	onVisibilityToggle,
	eleClassName,
	//
	externalNodeType,
}: DLTreeListByTreeDataProps) {
	// for eleRowRenderer
	const rowHeightForStyle =
		rowHeight === "thin" ? 24 : rowHeight === "medium" ? 32 : 48
	console.log(eleWidth, "eleWidtheleWidth")
	return (
		<div className={`FC ${eleClassName}`} data-testid="dl-tree-nav">
			<StyledTreeWithoutExpandBtn>
				<SortableTree
					dndType={externalNodeType}
					shouldCopyOnOutsideDrop // for file lib drawer in project
					//
					treeData={treeData}
					rowHeight={
						rowHeight === "thin"
							? 24
							: rowHeight === "medium"
							? 32
							: rowHeight === "relative"
							? undefined
							: 48
					}
					rowDirection={rowDirection}
					canDrag={canDrag}
					getNodeKey={({ node }) => node.id}
					generateNodeProps={({ node, path }) => {
						const depth = path.length
						return {
							title: eleRenderNode({
								node,
								path,
								depth,
								canDrag,
								showCheckbox,
								eleWidth,
								indentWidth,
								rowHeightForStyle,
								// functional parts
								getDetail,
							}),
							// className: matched ? "" : "not-matched",
							style: {
								// color: matched ? "brown" : "blue",
								// boxShadow:
								// 	depth === 1 && node.expanded
								// 		? "0px 6px 10px -10px rgba(0,0,0,0.4)"
								// 		: "none",
							},
						}
					}}
					isVirtualized={false}
					onChange={(treeData) => {
						ConsoleLog("DLTreeListByTreeDatak, onChange", treeData)
						// eleOnChange && eleOnChange(treeData)
					}}
					placeholderRenderer={placeholderRenderer}
					onMoveNode={onMoveNode}
					onDragStateChanged={onDragStateChanged}
					scaffoldBlockPxWidth={indentWidth}
					onVisibilityToggle={onVisibilityToggle}
					canNodeHaveChildren={(node) => node.isParent}
					// style related
					style={{
						width: eleWidth,
						height: eleHeight,
						overflow: "hidden",
						overflowY: "scroll",
					}}
				/>
			</StyledTreeWithoutExpandBtn>
		</div>
	)
})
