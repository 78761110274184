import React, { useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import {
	DLDialog,
	DLInputField,
	DLRadioGroup,
	DLTextField,
} from "../../../../../../components/basic-elements"
import styled from "styled-components"

export default observer(function EditSortingOrderDialog({
	selectedRowDetails,
}: {
	selectedRowDetails: any
}) {
	const orgStore = useOrgStore()
	const actionName = "editArchiveDatePolicy"

	const [sortingOrder, setSortingOrder] = useState(
		selectedRowDetails.SortOrder
	)
	//
	const handleEdit = () => {
		const isDefaultSet =
			orgStore.projArchDatePolicy.selectedPolicyInfo?.isDefault ?? false
		if (isDefaultSet) {
			alert("Cannot edit the sorting order of default policy set item.")
			return
		}
		const archiveDatePolicyItems: any =
			orgStore.projArchDatePolicy.viewSelectedPolicy()
				?.archiveDatePolicyItems || []
		const sortingOrderValue = parseInt(sortingOrder, 10)
		const isSortOrderExists = archiveDatePolicyItems.some(
			(item: any) =>
				item.SortOrder === sortingOrderValue &&
				item.ArchiveDatePolicyItemId !==
					selectedRowDetails.ArchiveDatePolicyItemId
		)
		if (isSortOrderExists) {
			alert(
				"Sorting Order already exists. Please enter a different Sorting Order."
			)
			return
		}
		const userInfo = orgStore.checkin.orgInfo
		const modifiedByUserName = `[${userInfo.userAliasId}] ${userInfo.userName}`

		orgStore.projArchDatePolicy.editArchivePolicyDatePolicyItem(
			orgStore.projArchDatePolicy.selectedPolicy,
			selectedRowDetails.ArchiveDatePolicyItemId,
			parseInt(sortingOrder),
			undefined, // incase of edit sort order, it should be undefined
			modifiedByUserName
		)
	}

	return (
		<DLDialog
			eleTestId="edit-sorting-order-dialog"
			isOpen={orgStore.projArchDatePolicy.openEditSortingOrderDialog}
			setIsOpen={
				orgStore.projArchDatePolicy.setOpenEditSortingOrderDialog
			}
			handleAction={handleEdit}
			showCloseBtn={true}
			dialogTitle={"Edit Sorting Order"}
			dialogContents={
				<EditSortingOrderForm
					sortingOrder={sortingOrder}
					setSortingOrder={setSortingOrder}
					selectedRowDetails={selectedRowDetails}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={"Cancel"}
			actionBtn={"Update"}
			actionReady={true}
			maxWidth="xs"
			fullWidth={true}
			showSpinner={
				orgStore.projArchDatePolicy.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.projArchDatePolicy.getActionStatus(actionName) ===
				"LOADING"
			}
		/>
	)
})

const EditSortingOrderForm = ({
	sortingOrder,
	setSortingOrder,
	selectedRowDetails,
}: {
	sortingOrder: any
	setSortingOrder: any
	selectedRowDetails: any
}) => {
	return (
		<StyledForm>
			<div className="input-container">
				<DLInputField
					eleType="number"
					eleTestId="sort-order"
					eleClassName="sort-order"
					eleValue={sortingOrder}
					eleLabel="Sorting Order"
					eleHandleChange={(e: any) => {
						if (e.target.value > 0) {
							setSortingOrder(e.target.value)
						}
					}}
				/>
			</div>
			<div className="input-container">
				<DLTextField
					eleTestId="policy-item-name-field"
					eleId="policyItemName"
					eleLabel="Archive Date Policy Item Name"
					// eleDefaultValue={inputs.clientAliasId}
					eleFullWidth
					eleValue={selectedRowDetails?.DaysString}
					eleReadOnly
				/>
			</div>
			<div className="input-container">
				<div className="label MuiFormLabel-root Mui-disabled">
					Archive Date Policy Item Status
				</div>
				<DLRadioGroup
					groupName="itemStatus"
					disabled
					itemDirection="row"
					items={[
						{
							value: "Active",
							label: "Active",
						},
						{
							value: "Inactive",
							label: "Inactive",
						},
					]}
					selectedValue={
						selectedRowDetails?.IsActive ? "Active" : "Inactive"
					}
					eleClassName="itemStatus"
				/>
			</div>
			<div className="input-container"></div>
		</StyledForm>
	)
}

const StyledForm = styled.div`
	.input-container {
		margin-bottom: 1rem;
	}
	.label {
		font-size: 0.7rem;
	}
	.itemStatus {
		margin-left: 8px;
		margin-top: 5px;
	}
`
