import { flow } from "mobx-state-tree"
import { OrgEngTypeApiProps } from "../org-eng-types.apis"

const ReadActiveEngTypeList = (
	self: any,
	apiReadActive: OrgEngTypeApiProps["apiReadActive"]
) => ({
	readActiveEngTypeList: flow(function* () {
		const actionName = "readActiveEngTypeList"
		try {
			const response = yield apiReadActive()
			// ConsoleLog("readEngTypeList response", response)
			return response
		} catch (error) {
			// console.error("readEngTypeList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadActiveEngTypeList
