// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const MoveEngType = (self: any) => ({
	moveEngType(engTypeId: string, to: string) {
		// 0.
		const actionName = "moveEngType"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		const engTypeNumberId = idToNumber(engTypeId, IdType.engType)
		const sourceArchivePolicy = idToNumber(to, IdType.archivePolicy)

		const payload = {
			EngagementTypeItemId: engTypeNumberId,
			SourceArchiveDatePolicySetId: sourceArchivePolicy,
		}

		self.requestMoveEngType(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response
					self.moveEngTypeInFlatList(engTypeId, to)
					//
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default MoveEngType
