import { flow } from "mobx-state-tree"
import { OrgProjArchDatePolicyApiProps } from "../org-proj-arch-date-policies.apis"

const UpdateArchiveDatePolicy = (
	self: any,
	update: OrgProjArchDatePolicyApiProps["apiUpdatePolicy"]
) => ({
	updateArchiveDatePolicy: flow(function* (payload) {
		const actionName = "updateArchiveDatePolicy"

		try {
			const response = yield update(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateArchiveDatePolicy
