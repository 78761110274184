import React, { useState, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { DLDialog } from "../../../../../../components/basic-elements"
import CreateArchiveDatePolicyForm from "./CreateArchiveDatePolicyForm"

export default observer(function EditArchiveDatePolicyDialog() {
	const orgStore = useOrgStore()
	const actionName = "editArchiveDatePolicy"
	//
	const selectedPolicyInfo: any =
		orgStore.projArchDatePolicy.selectedPolicyInfo
	const initialData = {
		title: selectedPolicyInfo?.title,
		description: selectedPolicyInfo?.description,
	}
	const [inputs, setInputs] = useState(initialData)
	// let inputReady = inputs.title !== "" && inputs.description !== ""
	//

	useEffect(() => {
		orgStore.projArchDatePolicy.responses.removeResponse(actionName)
	}, [])

	const handleInputs = useCallback((event: any) => {
		let name = event.target.name
		let value = event.target.value
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}, [])
	//
	const handleEdit = () => {
		const policyId = orgStore.projArchDatePolicy.selectedPolicy
		orgStore.projArchDatePolicy.editArchiveDatePolicy(
			inputs.title,
			inputs.description,
			policyId
		)
	}

	const validations = () => {
		if (
			inputs.title !== "" &&
			// inputs.description !== "" &&
			(selectedPolicyInfo.title !== inputs.title ||
				selectedPolicyInfo.description !== inputs.description)
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<DLDialog
			eleTestId="edit-archive-policy-dialog"
			isOpen={orgStore.projArchDatePolicy.openEditDialog}
			setIsOpen={orgStore.projArchDatePolicy.setOpenEditDialog}
			handleAction={handleEdit}
			showCloseBtn={true}
			dialogTitle={"Edit Archive Date Policy Set"}
			dialogContents={
				<CreateArchiveDatePolicyForm
					inputs={inputs}
					handleInputs={handleInputs}
					actionName={actionName}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={"Cancel"}
			actionReady={validations()}
			actionBtn={"Update"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.projArchDatePolicy.responses.getResponse(actionName)
					?.message
			}
			showSpinner={
				orgStore.projArchDatePolicy.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.projArchDatePolicy.getActionStatus(actionName) ===
				"LOADING"
			}
		/>
	)
})
