import React from "react"
import { NavLink, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { DLTooltip } from "@datalobby/components"
// WARNING:
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import List from "@material-ui/core/List"
import orgMenuIconList from "../../../temporary-data/org-side/default-org-menu-list/org-menu-icon-list"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { MenuForUiProps } from "../../../service-modules/role-module/roles.data-model"
import { DLI18nProps } from "../../../common-models/types/common-props"
import keyboardJS from "keyboardjs"

export default observer(function SideBarMenuForOrg({
	menuInfo,
	i18n,
}: {
	menuInfo: MenuForUiProps
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	let location = useLocation()

	const { id, title, url } = menuInfo

	// NOTE: 'sub menu' is not currently used on the organization side bar
	// const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false)

	// const handleSubmenu = () => {
	// 	setSubMenuOpen(!subMenuOpen)
	// }

	// 	if (!menuInfo.subMenus) {
	// 		orgStore.default.setCurrentOrgMainMenu(menuInfo.id)
	// 	}
	// }

	const handleSidebarMenuClick = (menuId: string, menuTitle: string) => {
		keyboardJS.pause()
		orgStore.default.setCurrentOrgMainMenu(menuId, menuTitle)
	}

	const renderIcon = (menuId: string) => {
		return orgMenuIconList.find((element) => element?.menuId === menuId)
			?.icon
	}

	const currentMenu = orgStore.checkin.accessibleFlatMenus.find(
		(menu: any) => menu.url === location.pathname.split("/")[2] //location.pathname
	)
	// console.log("currentMenu in sidebar org", currentMenu)

	return (
		// ! Because this component uses shared classNames
		// ! DO NOT CHANGE ANY CLASS NAME
		// ! TODO: update is required
		<List className="sidebar-menu-item">
			{/* ! Next task: Make below NavLink to optional */}
			<NavLink
				to={
					currentMenu && id === currentMenu.menuGroup
						? "/organization/" + currentMenu.url
						: "/organization/" + url
				}
				className="link FR"
				activeClassName={"active-menu"}
				onClick={() => handleSidebarMenuClick(id, title)}
			>
				<DLTooltip title={title} elePlacement="right" arrow>
					<ListItem button data-testid={`org-menu-${id}`}>
						<ListItemIcon className="menu-icon FR JC">
							<>{renderIcon(id)}</>
						</ListItemIcon>

						<ListItemText
							className="menu-text"
							// primary={
							// 	title.length > 17
							// 		? title.slice(0, 17) + "..."
							// 		: title
							// }
							primary={
								// i18n[id]?.length > 17
								// 	? i18n[id]?.slice(0, 17) + "..." :
								i18n[id] ?? id
							}
						/>
					</ListItem>
				</DLTooltip>
			</NavLink>
		</List>
	)
})
