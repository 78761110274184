import React, { useEffect, useState } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import useForm from "../../../../../library/use-form"
import sharedRegEx from "../../../../../library/sharedRegEx"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
	DLTextField,
	DLRadioGroup,
} from "../../../../../components/basic-elements"

import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import styled from "styled-components"

const EditEngTypeDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const orgStore = useOrgStore()
	const actionName = "editEngType"
	const { twEditEngType } = i18n
	//
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
		sortOrder: { value: "", error: "", requestInput: false },
	}

	const engTypeInfo = orgStore.setupEngTypes.getEngTypeById(
		orgStore.setupEngTypes.engagementId
	)

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const editEngType = () => {
		const engagementId = orgStore.setupEngTypes.engagementId
		const userInfo = orgStore.checkin.orgInfo
		const modifiedByUserName = `[${userInfo.userAliasId}] ${userInfo.userName}`

		orgStore.setupEngTypes.editEngType({
			engagementId,
			engTypeName: inputs.name.value,
			sortOrder: parseInt(inputs.sortOrder.value),
			modifiedByUserName,
		})
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		editEngType
	)

	useEffect(() => {
		inputs.name.value = engTypeInfo ? engTypeInfo.name : ""
		inputs.sortOrder.value = engTypeInfo ? engTypeInfo.sortOrder : ""
	}, [engTypeInfo])

	return (
		<DLDialog
			eleTestId="edit-engtype-dialog"
			isOpen={orgStore.setupEngTypes.openEdit}
			setIsOpen={orgStore.setupEngTypes.setOpenEdit}
			showCloseBtn={true}
			showOpenBtn={false}
			dialogTitle={
				<div className="FR AC">
					<Icon
						path={mdiPencil}
						size={1}
						color={MessageColorType.default}
						style={{ marginRight: 8 }}
					/>
					{twEditEngType}
				</div>
			}
			dialogContents={
				<EditEngTypeDialogForm
					i18n={i18n}
					inputs={inputs}
					handleOnChange={handleOnChange}
					selectedRowDetails={engTypeInfo}
				/>
			}
			actionReady={
				isReady &&
				(engTypeInfo?.name !== inputs.name.value ||
					engTypeInfo?.sortOrder !== parseInt(inputs.sortOrder.value))
			}
			handleAction={handleOnSubmit}
			actionBtn={i18n.twUpdate}
			cancelBtnText={i18n.twCancel}
			showSpinner={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

// const EditEngTypeDialogForm = observer(
// 	({
// 		inputs,
// 		handleOnChange,
// 		i18n,
// 		actionName,
// 		defaultData,
// 	}: {
// 		inputs: any
// 		handleOnChange: any
// 		i18n: DLI18nProps
// 		actionName: string
// 		defaultData: any
// 	}) => {
// 		const orgStore = useOrgStore()
// 		return (
// 			<StyledEditDialog>
// 				<InputWithLabel required label={i18n.twName}>
// 					<DLInputField
// 						eleTestId="engagement-type-name-input"
// 						eleFullWidth
// 						eleName="name"
// 						eleValue={inputs.name.value}
// 						eleHandleChange={handleOnChange}
// 						eleRequired
// 						warningMsg={inputs.name.error}
// 						requestInput={inputs.name.requestInput}
// 					/>
// 					{/* CHECK 1: Display fail message when the API return the fail response  */}
// 					{orgStore.setupEngTypes.getActionStatus(actionName) ===
// 						"FAIL" &&
// 						orgStore.setupEngTypes.responses.getResponse(actionName)
// 							?.message}
// 					{/* CHECK 2: Check the duplication and same as default value  */}
// 					{defaultData.name !== inputs.name.value &&
// 						orgStore.setupEngTypes.isDuplicatedName(
// 							inputs.name.value
// 						) && (
// 							<DLSystemMsg
// 								type={MessageColorType.red}
// 								msg={i18n.tsSameNameExist}
// 							/>
// 						)}
// 				</InputWithLabel>
// 			</StyledEditDialog>
// 		)
// 	}
// )

const EditEngTypeDialogForm = ({
	i18n,
	inputs,
	handleOnChange,
	selectedRowDetails,
}: {
	i18n: DLI18nProps
	inputs: any
	handleOnChange: any
	selectedRowDetails: any
}) => {
	const orgStore = useOrgStore()
	return (
		<StyledForm>
			<div className="input-container">
				<DLInputField
					eleType="number"
					eleTestId="sort-order"
					eleClassName="sort-order"
					eleName="sortOrder"
					eleRequired
					eleValue={inputs.sortOrder.value}
					eleLabel="Sorting Order"
					eleHandleChange={(e: any) => {
						if (e.target.value > 0) {
							handleOnChange(e)
						}
					}}
				/>
				{selectedRowDetails.sortOrder !==
					parseInt(inputs.sortOrder.value) &&
					orgStore.setupEngTypes.isDuplicatedSortOrder(
						parseInt(inputs.sortOrder.value)
					) && (
						<DLSystemMsg
							type={MessageColorType.red}
							msg="The same Sort Order already exist"
						/>
					)}
			</div>
			<div className="input-container">
				{/* <InputWithLabel label={i18n.twName} direction="vertical"> */}
				<DLInputField
					eleTestId="engagement-type-name-input"
					eleFullWidth
					eleName="name"
					eleValue={inputs.name.value}
					eleHandleChange={handleOnChange}
					eleRequired
					eleLabel={"Engagement Type Name"}
					warningMsg={inputs.name.error}
					requestInput={inputs.name.requestInput}
				/>
				{selectedRowDetails.name !== inputs.name.value &&
					orgStore.setupEngTypes.isDuplicatedName(
						inputs.name.value
					) && (
						<DLSystemMsg
							type={MessageColorType.red}
							msg={i18n.tsSameNameExist}
						/>
					)}
				{/* </InputWithLabel> */}
			</div>
			<div className="input-container">
				<div className="label MuiFormLabel-root Mui-disabled">
					Engagement Type Status
				</div>
				<DLRadioGroup
					groupName="engTypeStatus"
					disabled
					itemDirection="row"
					items={[
						{
							value: "Active",
							label: "Active",
						},
						{
							value: "Inactive",
							label: "Inactive",
						},
					]}
					selectedValue={
						selectedRowDetails?.isActive ? "Active" : "Inactive"
					}
					eleClassName="itemStatus"
				/>
			</div>
		</StyledForm>
	)
}

const StyledForm = styled.div`
	.input-container {
		margin-bottom: 1rem;
	}
	.label {
		font-size: 0.7rem;
	}
	.itemStatus {
		margin-left: 8px;
		margin-top: 5px;
	}
`

export default EditEngTypeDialog
