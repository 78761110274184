import { types } from "mobx-state-tree"

export const EngType = types.model({
	id: types.string,
	name: types.string,
	archiveDatePolicySetId: types.string,
	createdByUserId: types.number,
	createdAt: types.string,
	createdBy: types.string,
	modifiedByUserId: types.number,
	modifiedAt: types.union(types.string, types.null),
	modifiedBy: types.union(types.string, types.null),
	sortOrder: types.number,
	isActive: types.boolean,
	activatedByUserId: types.number,
	activatedAt: types.union(types.string, types.null),
	activatedBy: types.union(types.string, types.null),
	deActivatedByUserId: types.union(types.number, types.null),
	deActivatedAt: types.union(types.string, types.null),
	deActivatedBy: types.union(types.string, types.null),
})

export const ActiveEngType = types.model({
	id: types.string,
	name: types.string,
	isActive: types.boolean,
})
