import { flow } from "mobx-state-tree"
import { OrgSetupProjectAdminSettingsApiProps } from "../org-setup-project-admin-settings.apis"

const UpdateOrgSetupProjectAdminSettings = (
	self: any,
	apiUpdate: OrgSetupProjectAdminSettingsApiProps["apiUpdate"]
) => ({
	updateOrgSetupProjectAdminSettings: flow(function* (payload) {
		const actionName = "updateOrgSetupProjectAdminSettings"
		try {
			const response = yield apiUpdate(payload)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default UpdateOrgSetupProjectAdminSettings
