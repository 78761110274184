import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetRiskAssessmentList = (self: any) => ({
	getRiskAssessmentList() {
		// 0.
		const actionName = "getRiskAssessmentList"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const payload = {
			PageSize: 1000,
		}
		self.readRiskAssessmentList(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					const { Data } = response.data
					// let list: any[] = []
					const list: any[] = Data.map((item: any) => ({
						riskAssessmentId: idToString(
							item.RiskAssessmentItemId,
							IdType.ra
						), // [RiskAssessmentID], ex) 2
						riskAssessmentName: item.RiskAssessmentItemName, // [RiskAssessmentName] ,ex)Risky
						createdAt: item.CreatedAtUtc, // [CreatedDate], ex) "10/14/19 6:32:53 PM"
						createdBy: item.CreatedByUserName, // [CreatedUser], ex) "cskim 10/14/2019"
						createdByUserId: item.CreatedByUserId,
						modifiedByUserId: item.ModifiedByUserId,
						modifiedAt: item.ModifiedAtUtc,
						modifiedBy: item.ModifiedByUserName || "-",
						sortOrder: item.SortOrder,
						isActive: item.IsActive,
						activatedByUserId: item.ActivatedByUserId,
						activatedAt: item.ActivatedAtUtc,
						activatedBy: item.ActivatedByUserName || "-",
						deActivatedByUserId: item.DeActivatedByUserId,
						deActivatedAt: item.DeActivatedAtUtc,
						deActivatedBy: item.DeActivatedByUserName || "-",
					}))
					self.setRiskAssessmentList(list)
					self.setNeedRefresh(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to get the risk assessment list",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,

						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetRiskAssessmentList
