import { encryptUrl } from "../../../../../library/url-encryption"
import { OpenFileProps } from "../../../../../service-modules/file-module/check-file-props-to-open"

const OpenEditor = (self: any) => ({
	openEditor(props: OpenFileProps) {
		// 0.
		const {
			orgId,
			sector,
			//
			menuId,
			fileInfo, // NOTE: This can be omitted if the proper API be added
			projectId,
			//
			asReadonly,
		} = props

		const urlInfo = {
			menuId,
			sector,
			//
			orgId,
			projId: projectId,
			fileId: fileInfo.id,
			srcId: fileInfo.srcId,
			srcFormat: fileInfo.srcFormat,
			//
			fileTitle: fileInfo.title,
			fileAliasId: fileInfo.aliasId,
			//
			asReadonly,
		}
		const urlParams = `/editor/${encryptUrl(urlInfo)}`
		window.open(urlParams)
	},
})

export default OpenEditor
