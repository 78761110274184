import { flow } from "mobx-state-tree"
import { AULMappedERPApiProps } from "../map-aul-to-erp.apis"

const ReadAULMappedERP = (
	self: any,
	read: AULMappedERPApiProps["apiRead"]
) => ({
	readAULMappedERP: flow(function* () {
		const actionName = "readAULMappedERP"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		// NOTE: NO PARAMS
		try {
			const response = yield read()
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadAULMappedERP
