import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import OrgRoute from "./OrgRoute"
import OrgSideAppFrame from "../components/app-frame-elements/OrgSideAppFrame"
import OrgCheckin from "../screens/organization-side/org-access-control/org-checkin/OrgCheckin"
import OrgCheckout from "../screens/organization-side/org-access-control/OrgCheckout"
// stores
import { OrgStoreProvider } from "../stores/org-store/org-store.provider"
// menus
import {
	MyPage,
	Notifications,
	// Home,
	OrgDashboard,
	ScheduleAndBudget,
	ArchiveManagement,
	// HardCopyManagement,
	OrgGroupsController,
	// ReportsManagement,
	OrgClientsController,
	OrgEngagementsController,
	AllProjects,
	AdminRemind,
	OrgLibrary,
	OrganizationSetup,
	OrgTrashController,
	OrgSearchController,
	OrgSetupUsersController,
	OrgSetupClientsController,
	OrgSetupGroupsController,
	ERPDataSync,
} from "../screens/organization-side"

import { OrgMgmtController } from "../screens/lobby-side"
import { LobbyStoreProvider } from "../stores/lobby-store/lobby-store.provider"
import AssignedProjects from "../screens/organization-side/assigned-projects/AssignedProjects"
import ArchivingReminderController from "../screens/organization-side/archiving-reminder/ArchivingReminderController"

export default function OrgRouter() {
	return (
		<OrgStoreProvider>
			<OrgSideAppFrame>
				<Switch>
					{/* ---------- Organization access control  */}
					<Route
						exact
						path="/organization/checkin"
						component={OrgCheckin}
					/>
					<Route
						exact
						path="/organization/checkout"
						component={OrgCheckout}
					/>
					{/* ---------- Organization Menus  */}
					<OrgRoute path="/organization/my-page">
						<MyPage />
					</OrgRoute>
					<OrgRoute path="/organization/assigned-projects">
						<AssignedProjects />
					</OrgRoute>
					<OrgRoute path="/organization/archiving-reminder">
						<ArchivingReminderController />
					</OrgRoute>
					<OrgRoute path="/organization/notifications">
						<Notifications />
					</OrgRoute>
					<OrgRoute path="/organization/org-dashboard">
						<OrgDashboard />
					</OrgRoute>
					<OrgRoute path="/organization/schedule-and-budget">
						<ScheduleAndBudget />
					</OrgRoute>
					<OrgRoute path="/organization/archive-management">
						<ArchiveManagement />
					</OrgRoute>
					{/* <OrgRoute path="/organization/hard-copy-management">
						<HardCopyManagement />
					</OrgRoute> */}
					<OrgRoute path="/organization/groups">
						<OrgGroupsController />
					</OrgRoute>
					{/* <OrgRoute path="/organization/reports-management">
						<ReportsManagement />
					</OrgRoute> */}
					<OrgRoute path="/organization/clients">
						<OrgClientsController />
					</OrgRoute>
					<OrgRoute path="/organization/engagements">
						<OrgEngagementsController />
					</OrgRoute>
					<OrgRoute path="/organization/projects">
						<AllProjects />
					</OrgRoute>
					<OrgRoute path="/organization/admin-remind">
						<AdminRemind />
					</OrgRoute>
					<OrgRoute path="/organization/templates-and-library">
						<OrgLibrary />
					</OrgRoute>
					<OrgRoute path="/organization/trash">
						<OrgTrashController />
					</OrgRoute>
					<OrgRoute path="/organization/organization-setup">
						<OrganizationSetup />
					</OrgRoute>
					<OrgRoute path="/organization/organization-management">
						{/* TODO: WARNING: NEED TO MOVE */}
						<OrgMgmtController />
					</OrgRoute>
					<OrgRoute path="/organization/search">
						<OrgSearchController />
					</OrgRoute>
					<OrgRoute path="/organization/erp-data-sync">
						<ERPDataSync />
					</OrgRoute>
					<OrgRoute path="/organization/org-normal-setup">
						<Route
							exact
							path="/organization/org-normal-setup"
							component={OrgSetupUsersController}
						/>
						<Route
							exact
							path="/organization/org-normal-setup/org-users"
							component={OrgSetupUsersController}
						/>
						<Route
							exact
							path="/organization/org-normal-setup/org-groups"
							component={OrgSetupGroupsController}
						/>
						<Route
							exact
							path="/organization/org-normal-setup/org-clients"
							component={OrgSetupClientsController}
						/>
					</OrgRoute>
				</Switch>
			</OrgSideAppFrame>
		</OrgStoreProvider>
	)
}
