import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

export function reOrganizeItem(item: any) {
	const reOrganizedItem = {
		id: item.Id.toString(), // [Id], ex) 13
		aliasId: idToString(
			item.ArchivePreConditionId,
			IdType.archiveCondition
		), // [ArchivePreConditionId], ex) 12
		title: item.ArchivePreCondition, // [ArchivePreCondition], ex) "Opened Files"
		description: item.Description, // [Description], ex) "All Workpaper & Permanent File closed"
		helpText: item.HelpText, // [HelpText], ex) "Every opened Workpaper or Permanent File in the project should be closed to complete archival/retention process"
		//
		isMandatory: item.IsMandatory === 1 ? true : false, // [IsMandatory], ex) 1
		//								order: item.Order, // [Order], ex) 1
		createdBy: idToString(item.CreatedBy, IdType.user), // [CreatedBy], ex) 1
		createdAt: item.CreatedDate, // [CreatedDate], ex) "2018-11-26T21:34:34.44527-05:00"
		modifiedBy:
			item.ModifiedBy === 0
				? null
				: idToString(item.ModifiedBy, IdType.user), // [ModifiedBy], ex) 0
		modifiedAt: item.ModifiedDate, // [ModifiedDate]
	}
	return reOrganizedItem
}

const GetProjArchDatePolicies = (self: any) => ({
	getProjArchDatePolicies() {
		// 0.
		const actionName = "getProjArchDatePolicies"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readProjArchDatePolicies()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const fetchedPolicies = response.data.Data
					let reOrganizedPolicies: any[] = []

					console.log(fetchedPolicies, "fetchedPolicies")
					fetchedPolicies.map((policy: any) => {
						const fetchedEngTypes =
							policy.AssociatedEngagementTypeItems
						const fetchedPolicies = policy.ArchiveDatePolicyItems

						const policyId = idToString(
							policy.ArchiveDatePolicySetId,
							IdType.archivePolicy
						)

						console.log(fetchedEngTypes, "fetchedEngTypes")

						// 1. engagement type list
						fetchedEngTypes.map((engType: any) => {
							const engTypeId = idToString(
								engType.EngagementTypeItemId,
								IdType.engType
							)
							// const reOrganizedEngType = {
							// 	id: engTypeId, // [Id], ex) 2
							// 	title: engType.EngagementType, // [EngagementType], ex) "A"
							// 	//
							// 	createdBy: null, // [CreatedBy], ex) 1
							// 	createdAt: engType.CreatedDate, // [CreatedDate], ex) "2018-11-26T21:34:34.44527-05:00"
							// 	modifiedBy: idToString(
							// 		engType.ModifiedBy,
							// 		IdType.user
							// 	), // [ModifiedBy], ex) 0
							// 	modifiedAt: engType.ModifiedDate, // [ModifiedDate]
							// }
							const flatEngType = {
								id: engTypeId,
								title: engType.EngagementTypeItemName,
								description: null,
								parentId: policyId,
								expanded: false,
								type: "engType",
								archiveDatePolicyItems: null,
								isActive: engType.IsActive,
								isDefault: policy.IsDefault,
							}
							console.log(flatEngType, "flatEngType")
							self.pushItemToFlatList(flatEngType)
						})

						const flatPolicy = {
							id: policyId,
							parentId: null,
							title: policy.ArchiveDatePolicySetName,
							description: policy.ArchiveDatePolicySetDescription,
							expanded: true,
							type: "policy",
							archiveDatePolicyItems:
								policy.ArchiveDatePolicyItems.sort(
									(a: any, b: any) =>
										a.SortOrder - b.SortOrder
								),
							isActive: true,
							isDefault: policy.IsDefault,
						}
						self.pushItemToFlatList(flatPolicy)
					})
					const defaultSelect = fetchedPolicies.find(
						(i: any) => i.IsDefault
					)?.ArchiveDatePolicySetId

					const defaultPolicy = idToString(
						defaultSelect,
						IdType.archivePolicy
					)
					self.setSelectedPolicy(defaultPolicy)
					self.setNeedRefresh(false)
					// set success case
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Successfully fetched project archive date policies",
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetProjArchDatePolicies
