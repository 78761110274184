import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import { OrgProjArchDatePolicyApiProps } from "./org-proj-arch-date-policies.apis"
import ReadProjArchDatePolicies from "./model-actions/read-proj-arch-date-policies"
import UpdateArchivePolicyDatePolicyItem from "./model-actions/update-arch-date-policy-item"
import CreateArchiveDatePolicy from "./model-actions/create-archive-date-policy"
import UpdateArchiveDatePolicy from "./model-actions/update-archive-date-policy"
import DeleteArchiveDatePolicy from "./model-actions/delete-archive-date-policy"
import RequestCopyArchiveDatePolicy from "./model-actions/request-copy-archive-date-policy"
import RequestMoveEngType from "./model-actions/request-move-eng-type"

export const OrgProjArchiveDatePolicyModel = ({
	apiRead,
	apiUpdate,
	apiCreate,
	apiUpdatePolicy,
	apiDelete,
	apiCopy,
	apiMoveEngType,
}: OrgProjArchDatePolicyApiProps) =>
	types
		.model({})
		.actions((self, read = apiRead) => ReadProjArchDatePolicies(self, read))
		.actions((self, update = apiUpdate) =>
			UpdateArchivePolicyDatePolicyItem(self, update)
		)
		.actions((self, update = apiUpdatePolicy) =>
			UpdateArchiveDatePolicy(self, update)
		)
		.actions((self, create = apiCreate) =>
			CreateArchiveDatePolicy(self, create)
		)
		.actions((self, del = apiDelete) => DeleteArchiveDatePolicy(self, del))
		.actions((self, copy = apiCopy) =>
			RequestCopyArchiveDatePolicy(self, copy)
		)
		.actions((self, moveEngType = apiMoveEngType) =>
			RequestMoveEngType(self, moveEngType)
		)
		// common model actions
		.actions(CommonModelActions)
