import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetActiveRiskAssessmentList = (self: any) => ({
	getActiveRiskAssessmentList() {
		// 0.
		const actionName = "getActiveRiskAssessmentList"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const payload = {
			PageSize: 1000,
		}
		self.readActiveRiskAssessmentList(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					const { Data } = response.data
					// let list: any[] = []
					const list: any[] = Data.map((item: any) => ({
						riskAssessmentId: idToString(
							item.RiskAssessmentItemId,
							IdType.ra
						), // [RiskAssessmentID], ex) 2
						riskAssessmentName: item.RiskAssessmentItemName, // [RiskAssessmentName] ,ex)Risky
						isActive: item.IsActive,
					}))
					self.setActiveRiskAssessmentList(list)

					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to get the risk assessment list",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,

						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetActiveRiskAssessmentList
