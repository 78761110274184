import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions
import ReadAULMappedERP from "./model-actions/read-aul-mapped-erp-data"

import { AULMappedERPApiProps } from "./map-aul-to-erp.apis"
// WARNING: TODO: NOTE: Archive Policy List MVVM is not updated yet

const AULMappedERPModel = ({ apiRead }: AULMappedERPApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self, read = apiRead) => ReadAULMappedERP(self, read))
		// common model actions
		.actions(CommonModelActions)

export default AULMappedERPModel
