import { flow } from "mobx-state-tree"
import { OrgSetupProjectAdminSettingsApiProps } from "../org-setup-project-admin-settings.apis"

const ReadOrgSetupProjectAdminSettings = (
	self: any,
	apiRead: OrgSetupProjectAdminSettingsApiProps["apiRead"]
) => ({
	readOrgSetupProjectAdminSettings: flow(function* () {
		const actionName = "readOrgSetupProjectAdminSettings"
		try {
			const response = yield apiRead()
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadOrgSetupProjectAdminSettings
