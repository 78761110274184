import { flow } from "mobx-state-tree"
import { OrgProjArchDatePolicyApiProps } from "../org-proj-arch-date-policies.apis"

const DeleteArchiveDatePolicy = (
	self: any,
	del: OrgProjArchDatePolicyApiProps["apiDelete"]
) => ({
	deleteArchiveDatePolicy: flow(function* (policyId: number) {
		const actionName = "deleteArchiveDatePolicy"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		const params = {
			ArchiveDatePolicySetId: policyId,
		}
		try {
			const response = yield del(params)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteArchiveDatePolicy
