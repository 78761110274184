import React from "react"
import styled from "styled-components"

const StyledTreeWithoutExpandBtn = styled.div`
	outline: none;
	.rst__tree {
		/* height: 900px !important; */
		outline: none;
		/* & > div > div > div > div {
			box-shadow: 0px 6px 10px -10px rgba(0, 0, 0, 0.4);
		} */
		.rst__node {
			display: flex;
			border-bottom: 1px solid ${(props) => props.theme.shade05};
			/* border-bottom: 1px solid red; */
			/* overflow: hidden; */
			:hover {
				background-color: ${(props) => props.theme.secondaryDeep};
				.depth0 {
					background-color: ${(props) => props.theme.secondaryDeep};
				}
			}
			/* below part 'lineBlock' is related with collapse / expand button */
			.rst__lineBlock {
				&.rst__lineFullVertical {
				}
				&.rst__lineHalfHorizontalRight {
					/* margin-left: -20px; */
				}
				/* opacity: 0; */
				/* border: 1px solid red; */
				flex-shrink: 0;
			}
			/* border-bottom: 1px solid purple; */
			.rst__nodeContent {
				/* border-bottom: 1px solid orange; */
				display: flex;
				width: 100%;
				div:first-child {
					display: flex;
					align-items: center;
					flex-shrink: 0;
					/* border-bottom: 1px solid purple; */
					/* background-color: purple; */
				}
				/* button[type="button"] {
					cursor: pointer;
					width: 12px;
					height: 12px;
					margin-left: -12px;
					border: solid black;
					border-width: 0 3px 3px 0;
					display: inline-block;
					padding: 3px;
					outline: none;
				} */
				button.rst__expandButton {
					display: none;
					/* display: inline-block; */
					background-color: transparent;
					cursor: pointer;
					width: 9px;
					height: 9px;
					margin-left: -15px;
					border: solid gray;
					border-width: 0 2px 2px 0;
					padding: 3px;
					outline: none;
					transform: rotate(45deg);
					transition: 0.2s;
					-webkit-transform: rotate(45deg);
					opacity: 0;
				}
				button.rst__collapseButton {
					display: none;
					/* display: inline-block; */
					background-color: transparent;
					cursor: pointer;
					width: 9px;
					height: 9px;
					margin-left: -15px;
					border: solid gray;
					border-width: 0 2px 2px 0;
					padding: 3px;
					outline: none;
					transform: rotate(-135deg);
					transition: 0.2s;
					-webkit-transform: rotate(-135deg);
					opacity: 0;
				}
				.rst__lineChildren {
					width: 0 !important;
					/* width: calc(44px - 24px) !important; */
				}

				.rst__rowWrapper {
					/* border-bottom: 1px solid gray; */
					/* To highlight search matched node  */
					.rst__row.rst__rowSearchMatch {
						border: 5px solid orange;
						&.rst__rowSearchFocus {
							border: 5px solid red;
						}
					}
					.rst__row {
						display: flex;
						flex-direction: row;
						align-items: center;
						white-space: nowrap;
						// border-bottom: 1px solid ${(props) =>
							props.theme.shade10};
						// border-bottom: 1px solid orange;
						position: relative;
						.rst__moveHandle,
						.rst__loadingHandle {
							width: 24px;
							height: 24px;
							// background: #d9d9d9
							// 	url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+")
							// 	no-repeat center;
							cursor: grab;
							position: absolute;
							z-index: 1;
						}
						/* .rst__moveHandle,
						.rst__loadingHandle {
							height: 100%;
							width: 44px;
							background: #d9d9d9
								url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+")
								no-repeat center;
							box-shadow: 0 2px 2px -2px;
							cursor: move;
							border-radius: 1px;
							z-index: 1;
						} */
						.rst__loadingHandle {
							cursor: default;
							background: #d9d9d9;
						}
						.rst__rowContents {
							.rst__rowLabel {
								.rst__rowTitle {
									.drag-icon {
										opacity: 0.2;
										transition: 0.2s;
										:hover {
											opacity: 1;
										}
									}
								}
								.rst__rowToolbar {
								}
							}
						}
					}
				}
			}
		}
	}
	.rst__virtualScrollOverride {
		/* overflow: auto !important; */
	}
	.rst__virtualScrollOverride * {
		box-sizing: border-box;
	}

	.ReactVirtualized__Grid__innerScrollContainer {
		overflow: visible !important;
	}

	.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
		direction: rtl;
	}

	.ReactVirtualized__Grid {
		outline: none;
	}
	/* from node renderer default css */
	/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
	.rst__rowLandingPad,
	.rst__rowCancelPad {
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
	}
	.rst__rowLandingPad > *,
	.rst__rowCancelPad > * {
		opacity: 0 !important;
	}
	.rst__rowLandingPad::before,
	.rst__rowCancelPad::before {
		background-color: ${(props) => props.theme.secondaryDeep};
		transition: 0.2s;
		/* border: 1px dashed pink; */
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
	@keyframes pointFade {
		0%,
		19.999%,
		100% {
			opacity: 0;
		}
		20% {
			opacity: 1;
		}
	}

	.rst__loadingCircle {
		width: 80%;
		height: 80%;
		margin: 10%;
		position: relative;
	}

	.rst__loadingCirclePoint {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}
	.rst__rtl.rst__loadingCirclePoint {
		right: 0;
		left: initial;
	}

	.rst__loadingCirclePoint::before {
		content: "";
		display: block;
		margin: 0 auto;
		width: 11%;
		height: 30%;
		background-color: #fff;
		border-radius: 30%;
		animation: pointFade 800ms infinite ease-in-out both;
	}
	.rst__loadingCirclePoint:nth-of-type(1) {
		transform: rotate(0deg);
	}
	.rst__loadingCirclePoint:nth-of-type(7) {
		transform: rotate(180deg);
	}
	.rst__loadingCirclePoint:nth-of-type(1)::before,
	.rst__loadingCirclePoint:nth-of-type(7)::before {
		animation-delay: -800ms;
	}
	.rst__loadingCirclePoint:nth-of-type(2) {
		transform: rotate(30deg);
	}
	.rst__loadingCirclePoint:nth-of-type(8) {
		transform: rotate(210deg);
	}
	.rst__loadingCirclePoint:nth-of-type(2)::before,
	.rst__loadingCirclePoint:nth-of-type(8)::before {
		animation-delay: -666ms;
	}
	.rst__loadingCirclePoint:nth-of-type(3) {
		transform: rotate(60deg);
	}
	.rst__loadingCirclePoint:nth-of-type(9) {
		transform: rotate(240deg);
	}
	.rst__loadingCirclePoint:nth-of-type(3)::before,
	.rst__loadingCirclePoint:nth-of-type(9)::before {
		animation-delay: -533ms;
	}
	.rst__loadingCirclePoint:nth-of-type(4) {
		transform: rotate(90deg);
	}
	.rst__loadingCirclePoint:nth-of-type(10) {
		transform: rotate(270deg);
	}
	.rst__loadingCirclePoint:nth-of-type(4)::before,
	.rst__loadingCirclePoint:nth-of-type(10)::before {
		animation-delay: -400ms;
	}
	.rst__loadingCirclePoint:nth-of-type(5) {
		transform: rotate(120deg);
	}
	.rst__loadingCirclePoint:nth-of-type(11) {
		transform: rotate(300deg);
	}
	.rst__loadingCirclePoint:nth-of-type(5)::before,
	.rst__loadingCirclePoint:nth-of-type(11)::before {
		animation-delay: -266ms;
	}
	.rst__loadingCirclePoint:nth-of-type(6) {
		transform: rotate(150deg);
	}
	.rst__loadingCirclePoint:nth-of-type(12) {
		transform: rotate(330deg);
	}
	.rst__loadingCirclePoint:nth-of-type(6)::before,
	.rst__loadingCirclePoint:nth-of-type(12)::before {
		animation-delay: -133ms;
	}
	.rst__loadingCirclePoint:nth-of-type(7) {
		transform: rotate(180deg);
	}
	.rst__loadingCirclePoint:nth-of-type(13) {
		transform: rotate(360deg);
	}
	.rst__loadingCirclePoint:nth-of-type(7)::before,
	.rst__loadingCirclePoint:nth-of-type(13)::before {
		animation-delay: 0ms;
	}

	/* @Noah: What the hell...  */
	.rst__rowLandingPad,
	.rst__rowCancelPad {
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
	}
	.rst__rowLandingPad > *,
	.rst__rowCancelPad > * {
		opacity: 0 !important;
	}
	.rst__rowLandingPad::before,
	.rst__rowCancelPad::before {
		background-color: lightblue;
		/* border: 3px dashed white; */
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
`

export default StyledTreeWithoutExpandBtn
