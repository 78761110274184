import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"

import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import DLTreeNav from "../../../../../components/combined-elements/tree-nav/DLTreeNav"

import {
	DLContextMenu,
	DLIconButton,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import ArchPolicyRowController from "./ArchPolicyRowController"
import EngTypeRowController from "./EngTypeRowController"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import {
	CommonIconSize,
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"
import styled from "styled-components"

export default observer(function ProjArchDatePolicyNavController({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const policyStore = orgStore.projArchDatePolicy
	const actionName = "getProjArchDatePolicies"
	//
	let canDrag = permission.update // policyStore.canDrag

	//
	const handleOpenCreateDialog = () => {
		policyStore.setOpenCreateDialog(true)
	}
	const handleAllowDrag = () => {}
	const handleNavOpen = () => {}

	const handleRightClick = (e: any, id: string) => {
		e.preventDefault()
		policyStore.setClickPoint({
			mouseX: e.clientX,
			mouseY: e.clientY,
		})
		policyStore.setSelectedPolicy(id)
	}

	const handleMoveEngType = (data: any) => {
		if (data.node.type === "policy") {
			alert("Cannot move archive date policy")
		} else if (data.nextParentNode === null) {
			return
		} else if (
			data.node.type === "engType" &&
			data.nextParentNode.type === "policy" &&
			data.node.parentId !== data.nextParentNode.id
		) {
			console.log(
				data,
				data.node.parentId,
				data.nextParentNode.id,
				"nextParentNode"
			)
			const nextParentName = data.nextParentNode.title
			const nextParentId = data.nextParentNode.id
			const engTypeId = data.node.id
			let proceed = window.confirm(
				`Do you want to update this Eng. Type to use this '${nextParentName}' Archive policy?`
			)
			if (!proceed) {
				return
			}
			policyStore.moveEngType(engTypeId, nextParentId)
		}
	}

	const handleDuplicate = () => {
		let proceed = window.confirm(
			`Are you sure you want to duplicate this ${policyStore.selectedPolicyInfo?.title}`
		)
		if (!proceed) {
			return
		} else {
			policyStore.copyArchiveDatePolicy(policyStore.selectedPolicy)
		}
	}
	const handleDelete = () => {
		if (policyStore.selectedPolicyInfo?.isDefault) {
			alert("Cannot delete the Default archive date policy set.")
			return
		}
		const hasEngagements = policyStore.flatList.some(
			(item) => item.parentId === policyStore.selectedPolicy
		)
		if (hasEngagements) {
			alert("Cannot delete policy set due to associated engagements.")
			return
		}
		const proceed = window.confirm(
			"Are you sure you want to delete this policy set?"
		)
		if (!proceed) {
			return
		} else {
			policyStore.removeArchiveDatePolicy(policyStore.selectedPolicy)
		}
	}

	const ArchivePolicyRenderRow = ({
		node,
	}: {
		node: { id: string; parentId: null | string; [x: string]: any }
	}) => {
		if (node.type === "policy") {
			return (
				<ArchPolicyRowController
					node={node}
					handleRightClick={handleRightClick}
					canDrag={false} // there is no move feature for archive policy
				/>
			)
		} else if (node.type === "engType") {
			return (
				<EngTypeRowController
					node={node}
					// handleRightClick={handleRightClick}
					canDrag={canDrag}
				/>
			)
		}
	}

	let contentsHeight = store.ui.contentsAreaHeight
	let navWidth = 320
	let navHeaderHeight = 100
	let navOpen = true
	//

	let treeData = policyStore.simpleTreeForNav
	let treeHeight = contentsHeight - navHeaderHeight
	let treeIndent = 24
	console.log(treeData, "treeData")

	let loadingStatus = policyStore.getActionStatus(actionName)
	return (
		<StyledProjArchDatePolicyNav>
			<DLTreeNav
				navWidth={navWidth}
				navHeaderHeight={navHeaderHeight}
				contentsHeight={contentsHeight}
				navOpen={navOpen}
				canDrag={(props: any) => {
					return !props.node.isParent
				}}
				treeData={treeData}
				treeHeight={treeHeight}
				treeIndent={treeIndent}
				loadingStatus={loadingStatus}
				//
				showSearchbar={false}
				showSortBtn={false}
				showNav={false}
				actionButtons={
					<div className="FR JSB AC icons-when-nav-open">
						<div>
							<b>Archive Date Policy Sets</b>
						</div>
						{permission.create && (
							<DLIconButton
								eleTestId="add-arch-policy-on-nav"
								tooltipText="Add Archive Policy"
								clickHandler={handleOpenCreateDialog}
							>
								<Icon path={mdiPlus} size={0.8} />
							</DLIconButton>
						)}
					</div>
				}
				//
				treeRowRenderer={ArchivePolicyRenderRow}
				handleAllowDrag={handleAllowDrag}
				handleNavOpen={handleNavOpen}
				//
				// handleQueryText={handleQuery}
				onMoveNode={handleMoveEngType}
			/>
			{policyStore.clickPoint.mouseX && (
				<ArchPolicyCtxMenu
					clickPoint={policyStore.clickPoint}
					handleClose={() =>
						policyStore.setClickPoint({
							mouseX: null,
							mouseY: null,
						})
					}
					handleOpenEdit={() => {
						if (policyStore.selectedPolicyInfo?.isDefault) {
							alert(
								"Cannot edit the Default archive date policy set."
							)
							return
						}
						policyStore.setOpenEditDialog(true)
					}}
					handleDuplicate={handleDuplicate}
					handleDelete={handleDelete}
				/>
			)}
		</StyledProjArchDatePolicyNav>
	)
})

const StyledProjArchDatePolicyNav = styled.div`
	.icons-when-nav-open {
		width: 100%;
	}
	.rst__nodeContent > div:first-child {
		width: 100% !important;
	}
	.rst__rowWrapper,
	.rst__row,
	.rst__rowContents,
	.rst__rowLabel,
	.rst__rowTitle,
	.arch-policy-row {
		width: 100% !important;
	}
`

const ArchPolicyCtxMenu = observer(
	({
		clickPoint,
		handleClose,
		handleOpenEdit,
		handleDuplicate,
		handleDelete,
	}: {
		clickPoint: any
		handleClose: any
		handleOpenEdit: any
		handleDuplicate: any
		handleDelete: any
	}) => {
		const menuOptions = [
			{
				label: "Copy",
				value: "ctx-duplicate-arch-date-policy",
				clickEvent: handleDuplicate,
				icon: (
					<DLIcon
						name={DLIconName.duplicate}
						size={CommonIconSize.ctxMenuIcon}
					/>
				),
			},
			{
				label: "Edit",
				value: "ctx-edit-arch-date-policy",
				icon: (
					<DLIcon
						name={DLIconName.edit}
						size={CommonIconSize.ctxMenuIcon}
					/>
				),
				clickEvent: handleOpenEdit,
			},
			{
				label: "Delete",
				value: "ctx-delete-arch-date-policy",
				clickEvent: handleDelete,
				icon: (
					<DLIcon
						name={DLIconName.delete}
						size={CommonIconSize.ctxMenuIcon}
					/>
				),
			},
		]
		return (
			<DLContextMenu
				eleId="arch-date-policy-ctx-menu"
				clickPoint={clickPoint}
				handleClose={handleClose}
				menuOptions={menuOptions}
			/>
		)
	}
)
