import { types } from "mobx-state-tree"
// ViewModel, Modle, UiHelper
import AULMappedERPModel from "./map-aul-to-erp.model"
import AULMappedERPViewModel from "./map-aul-to-erp.view-model"
// common models
// apis
import * as api from "./map-aul-to-erp.apis"

export const AULMappedERPStore = ({ apiRead }: api.AULMappedERPApiProps) =>
	types
		.compose(
			AULMappedERPModel({
				apiRead,
			}),
			AULMappedERPViewModel
		)
		.named("AULMappedERPStore")

export const initialStore = {
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
}

const AULMappedERPStoreInstance = AULMappedERPStore({
	apiRead: api.read,
}).create(initialStore)

export default AULMappedERPStoreInstance
