import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { getRequest, postRequest } from "../../../../../library/api-requests"

export function create(params: {
	ArchiveDatePolicySetName: string
	ArchiveDatePolicySetDescription: string
}) {
	const response = postRequest({
		url: "/CreateArchiveDatePolicySet",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function read() {
	const response = getRequest({
		url: "/GetAllArchiveDatePolicySets",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function update(payload: {
	ArchiveDatePolicySetId: number
	ArchiveDatePolicyItemId: number
	SortOrder: number
	IsActive?: boolean
}) {
	const response = postRequest({
		url: "/UpdateArchiveDatePolicyItem",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function updatePolicy(payload: {
	ArchiveDatePolicySetName: string
	ArchiveDatePolicySetDescription: string
	ArchiveDatePolicySetId: number
}) {
	const response = postRequest({
		url: "/UpdateArchiveDatePolicySet",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function del(payload: { ArchiveDatePolicySetId: number }) {
	const response = postRequest({
		url: `/DeleteArchiveDatePolicySet`,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

export function copy(payload: { SourceArchiveDatePolicySetId: number }) {
	const response = postRequest({
		url: `/CopyArchiveDatePolicySet`,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

export function moveEngType(payload: {
	EngagementTypeItemId: number
	SourceArchiveDatePolicySetId: number
}) {
	const response = postRequest({
		url: `/MapArchivePolicySetToEngagementType`,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgProjArchDatePolicyApiProps = {
	apiRead: () => Promise<AxiosResponse<any>>
	apiUpdate: (payload: {
		ArchiveDatePolicySetId: number
		ArchiveDatePolicyItemId: number
		SortOrder: number
		IsActive?: boolean
	}) => Promise<AxiosResponse<any>>
	apiUpdatePolicy: (payload: {
		ArchiveDatePolicySetName: string
		ArchiveDatePolicySetDescription: string
		ArchiveDatePolicySetId: number
	}) => Promise<AxiosResponse<any>>
	apiDelete: (payload: {
		ArchiveDatePolicySetId: number
	}) => Promise<AxiosResponse<any>>
	apiCreate: (params: {
		ArchiveDatePolicySetName: string
		ArchiveDatePolicySetDescription: string
	}) => Promise<AxiosResponse<any>>
	apiCopy: (payload: {
		SourceArchiveDatePolicySetId: number
	}) => Promise<AxiosResponse<any>>
	apiMoveEngType: (payload: {
		EngagementTypeItemId: number
		SourceArchiveDatePolicySetId: number
	}) => Promise<AxiosResponse<any>>
}
