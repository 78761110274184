import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const EditOrgSetupProjectAdminSettings = (self: any) => ({
	editOrgSetupProjectAdminSettings({
		ReplicaProjectAssignUserEligibility,
		ReplicaTeamMemberConfiguration,
		modifiedByUserName,
	}: {
		ReplicaProjectAssignUserEligibility: number
		ReplicaTeamMemberConfiguration: number
		modifiedByUserName: string
	}) {
		// 0.
		const actionName = "editOrgSetupProjectAdminSettings"

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const payload = {
			ReplicaProjectAssignUserEligibility,
			ReplicaTeamMemberConfiguration,
		}
		// ConsoleLog(payload)
		self.updateOrgSetupProjectAdminSettings(payload)
			.then((response: any) => {
				// if success
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.Status === 1) {
					self.updateProjAdminSettingsData(
						ReplicaProjectAssignUserEligibility,
						ReplicaTeamMemberConfiguration,
						modifiedByUserName
					)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to update the project admin settings",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditOrgSetupProjectAdminSettings
