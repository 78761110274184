import { types } from "mobx-state-tree"
// common models
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions
import ReadERPData from "./model-actions/read-erp-data"
// import CreateERPData from "./model-actions/create-erp-data"
// import UpdateERPData from "./model-actions/update-erp-data"
// import DeleteERPData from "./model-actions/delete-erp-data"

import { ERPDataApiProps } from "./erp-data.apis"
// WARNING: TODO: NOTE: Archive Policy List MVVM is not updated yet

const ERPDataModel = ({
	apiRead,
}: // apiUpdate,
// apiDelete,
// apiCreate,
ERPDataApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self, read = apiRead) => ReadERPData(self, read))
		// .actions((self, update = apiUpdate) =>
		// 	UpdateArchivePolicy(self, update)
		// )
		// .actions((self, create = apiCreate) =>
		// 	CreateArchivePolicy(self, create)
		// )
		// .actions((self, del = apiDelete) => DeleteArchivePolicy(self, del))
		// common model actions
		.actions(CommonModelActions)

export default ERPDataModel
