import { types, applySnapshot } from "mobx-state-tree"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import {
	GetProjInfo,
	EditProjInfo,
	EditArchiveChecklist,
} from "./view-model-actions"
import { initialStore } from "./proj-info.provider"
import { GetActiveEngTypeList } from "../../../organization-side/org-setup/org-setup-eng-types/store/view-model-actions"
import { ActiveEngType } from "../../../organization-side/org-setup/org-setup-eng-types/store/data-models/org-setup-engTypes.data-models"
import { GetActiveRiskAssessmentList } from "../../../organization-side/org-setup/org-setup-risk-assessment/store/view-model-actions"
import { ActiveRiskAssessment } from "../../../organization-side/org-setup/org-setup-risk-assessment/store/data-models/org-setup-risk-assessment.data-models"
import {
	ProjInfo,
	ClientInGroup,
	CabinetDetails,
} from "./data-models/proj-info.data-model"
import GetClientsByGroup from "./view-model-actions/get-clients-by-group"
import { Period } from "../../../organization-side/org-setup/org-setup-period-types/period-type.data-model"
import { ArchPolicyPeriod } from "../../../organization-side/org-setup/org-setup-arch-policy-periods/arch-policy-period.data-model"
import GetArchPolicyPeriodList from "../../../organization-side/org-setup/org-setup-arch-policy-periods/get-arch-policy-period-list"
import GetArchPolicyPeriodListByEngTypeId from "../../../organization-side/org-setup/org-setup-arch-policy-periods/get-arch-policy-period-list-by-eng-type-id"
import GetProjPeriodTypes from "../../../organization-side/org-setup/org-setup-period-types/get-proj-period-types"
import {
	UpdateProjInfoViews,
	UpdateProjInfoActions,
} from "./view-model-actions/update-proj-info-common-actions"
import {
	getTzDate,
	getTzDateAndTime,
} from "../../../../library/converters/date-utc-converter"
import GetCabinetLocation from "./view-model-actions/get-cabinet-location"
import ExportArchivedZipFile from "../../../organization-side/archive-management/am-archived-projects/store/view-model-actions/export-archived-zip-file"

const ProjInfoViewModel = types
	.model({
		projInfo: ProjInfo,
		cabinetLocationDetails: types.array(CabinetDetails),
		// for update dialog
		clientList: types.array(ClientInGroup),
		periodNames: types.array(Period),
		activeEngTypeList: types.array(ActiveEngType),
		activeRiskAssessmentList: types.array(ActiveRiskAssessment),
		archPolicyPeriodList: types.array(ArchPolicyPeriod),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setCabinetLocationDetails(details: any) {
			self.cabinetLocationDetails.length = 0
			self.cabinetLocationDetails = details
		},
	}))
	.actions(UpdateProjInfoActions)
	// ---------
	// AGER (Add, Get, Edit, Remove)
	.actions(GetProjInfo)
	.actions(EditProjInfo)
	.actions(GetProjPeriodTypes)
	.actions(GetClientsByGroup)
	.actions(EditArchiveChecklist)
	//
	.actions(ExportArchivedZipFile)
	//
	.actions(GetActiveEngTypeList)
	.actions(GetActiveRiskAssessmentList)
	.actions(GetArchPolicyPeriodList)
	.actions(GetArchPolicyPeriodListByEngTypeId)
	.actions(GetCabinetLocation)
	.views(UpdateProjInfoViews)
	.views((self) => ({
		getChecklistInfo(
			timeZone: string,
			dateFormat: string,
			timeFormat: string
		) {
			const formattedLastUpdatedDate = self.projInfo
				.archiveChecklistLastUpdatedDate
				? getTzDateAndTime({
						date: self.projInfo.archiveChecklistLastUpdatedDate,
						timeZone,
						dateFormat,
						timeFormat,
				  })
				: ""
			const formattedFinalReportDate = self.projInfo.finalReportDate
				? getTzDate({
						date: self.projInfo.finalReportDate,
						timeZone,
						dateFormat,
				  })
				: ""
			return {
				checklistItem1: self.projInfo.archiveChecklistItem1,
				checklistItem2: self.projInfo.archiveChecklistItem2,
				updateByAliasName:
					self.projInfo.archiveChecklistLastUpdatedByUserAlias,
				updateByUserName:
					self.projInfo.archiveChecklistLastUpdatedByUserName,
				lastUpdatedDate: formattedLastUpdatedDate,
				finalReportDate: formattedFinalReportDate,
			}
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
		updateChecklistInfo(checklist: any) {
			self.projInfo = {
				...self.projInfo,
				...checklist,
			}
		},
		updateProjectLock(isLocked: boolean) {
			console.log(isLocked, "isLocked1")
			self.projInfo = {
				...self.projInfo,
				isLocked,
			}
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export { ProjInfoViewModel }
