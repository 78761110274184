import { getRequest } from "../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"

// @Noah: NEED TO UPDATE THE FILE NAME!!!
export async function readArchPolicyPeriodListByEngTypeId(engTypeId: number) {
	const response = await getRequest({
		url: "/GetArchiveDateDaysByEngagementTypeItemId",
		params: {
			engagementId: engTypeId,
		},
		tokenType: TOKEN_TYPE.orgToken, // TODO: orgToken? projToken?
	})

	return response
}
