import { flow } from "mobx-state-tree"
import { OrgProjArchDatePolicyApiProps } from "../org-proj-arch-date-policies.apis"

const UpdateArchivePolicyDatePolicyItem = (
	self: any,
	update: OrgProjArchDatePolicyApiProps["apiUpdate"]
) => ({
	updateArchivePolicyDatePolicyItem: flow(function* (payload) {
		const actionName = "updateArchivePolicyDatePolicyItem"

		try {
			const response = yield update(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateArchivePolicyDatePolicyItem
