import { types } from "mobx-state-tree"
import UpdateProjInfo from "./model-actions/update-proj-info"
import ReadProjInfo from "./model-actions/read-proj-info"
import UpdateArchiveChecklist from "./model-actions/update-archive-checklist"
import ReadProjPeriodTypes from "../../../organization-side/org-setup/org-setup-period-types/read-proj-period-types"
import { ReadActiveRiskAssessmentList } from "../../../organization-side/org-setup/org-setup-risk-assessment/store/model-actions"
import { ProjInfoApiProps } from "./proj-info.apis"
import ReadClientsByGroup from "./model-actions/read-clients-by-group"
import ReadArchPolicyPeriodList from "../../../organization-side/org-setup/org-setup-arch-policy-periods/read-arch-policy-period-list"
import ReadArchPolicyPeriodListByEngTypeId from "../../../organization-side/org-setup/org-setup-arch-policy-periods/read-arch-policy-period-list-by-eng-type-id"
import ReadCabinetLocation from "./model-actions/read-cabinet-location"
import ReadExportArchivedZipFile from "../../../organization-side/archive-management/am-archived-projects/store/model-actions/read-export-archived-zip-file"
import { ReadActiveEngTypeList } from "../../../organization-side/org-setup/org-setup-eng-types/store/model-actions"

const ProjInfoModel = ({
	apiRead,
	apiUpdate,
	apiReadActiveEngType,
	apiReadActiveRiskAssessment,
	apiReadPeriodNames,
	apiReadClients,
	apiReadArchPolicyPeriodList,
	apiReadArchPolicyPeriodListByEngTypeId,
	apiUpdateArchiveChecklist,
	apiReadCabinetLocation,
}: ProjInfoApiProps) =>
	types
		.model({})
		.actions((self) => ReadProjInfo(self, apiRead))
		.actions((self) => UpdateProjInfo(self, apiUpdate))
		.actions((self) => ReadActiveEngTypeList(self, apiReadActiveEngType))
		.actions((self) =>
			ReadActiveRiskAssessmentList(self, apiReadActiveRiskAssessment)
		)
		.actions((self) => ReadProjPeriodTypes(self, apiReadPeriodNames))
		.actions((self) => ReadClientsByGroup(self, apiReadClients))
		.actions((self) =>
			UpdateArchiveChecklist(self, apiUpdateArchiveChecklist)
		)
		.actions((self) =>
			ReadArchPolicyPeriodList(self, apiReadArchPolicyPeriodList)
		)
		.actions((self) =>
			ReadArchPolicyPeriodListByEngTypeId(
				self,
				apiReadArchPolicyPeriodListByEngTypeId
			)
		)
		.actions((self) => ReadCabinetLocation(self, apiReadCabinetLocation))
		.actions(ReadExportArchivedZipFile)

export default ProjInfoModel
