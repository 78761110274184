import React from "react"
import { observer } from "mobx-react-lite"

import { PageContainer } from "../../../../components/app-frame-elements"
import { ConsoleLog } from "../../../../components/basic-elements"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import styled from "styled-components"
import { DLI18nProps } from "../../../../common-models/types/common-props"

import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import ERPDataTable from "./sub-components/ERPDataTable"
import ERPDataCtxMenu from "./sub-components/ERPDataCtxMenu"
import CreateERPDataDialog from "./dialogs/CreateERPDataDialog"
import UpdateERPDataDialog from "./dialogs/UpdateERPDataDialog"
import BulkImportERPDataDialog from "./dialogs/BulkImportERPDataDialog"

export default observer(function ERPData({
	partialStore,
	actionStatus,
	permission,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" __________ ERPData __________ ")
	console.log(partialStore.viewERPDataList(), "viewERPDataList")
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={actionStatus === ActionStatus.loading}
		>
			{/* ----- Contents ----- */}
			{actionStatus === "SUCCESS" && (
				<StyledERPData>
					<ERPDataTable permission={permission} />
				</StyledERPData>
			)}
			<ERPDataCtxMenu permission={permission} />
			{/* ---------- dialogs  ---------- */}

			{partialStore.openCreateDialog && <CreateERPDataDialog />}
			{partialStore.openEditDialog && <UpdateERPDataDialog />}
			{partialStore.bulkImportERPDialogOpen && (
				<BulkImportERPDataDialog partialStore={partialStore} />
			)}

			{/* ---------- snackbar  ---------- */}
			<CommonSnackbarComponent i18n={{}} partialStore={partialStore} />
		</PageContainer>
	)
})

const StyledERPData = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.partition {
		margin: 0 0.5rem;
	}
`
