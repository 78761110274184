import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// separated actions
import GetArchPolicies from "./view-model-actions/get-erp-data"

import { initialStore } from "./erp-data.provider"
import { ERPDataTableModel } from "./data-models/erp-data.data-model"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"

const OrgArchivePoliciesViewModel = types
	.model({
		erpDataList: types.array(ERPDataTableModel),
		selectedItem: 0,
		clickPoint: ClickPoint,
		openCreateDialog: false,
		openEditDialog: false,
		bulkImportERPDialogOpen: false,
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setERPDataList(clients: any) {
			self.erpDataList.length = 0
			self.erpDataList = clients
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedItem(item: number) {
			self.selectedItem = item
		},
		setOpenCreateDialog(request?: boolean) {
			self.openCreateDialog = request ?? !self.openCreateDialog
		},
		setOpenEditDialog(request?: boolean) {
			self.openEditDialog = request ?? !self.openEditDialog
		},
		setBulkImportERPDialogOpen(request?: boolean) {
			self.bulkImportERPDialogOpen =
				request ?? !self.bulkImportERPDialogOpen
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	.actions(GetArchPolicies)
	//
	.views((self) => ({
		viewERPDataList() {
			return self.erpDataList
		},
		viewSelectedItemInfo() {
			return self.erpDataList.find((i: any) => i.id === self.selectedItem)
		},
	}))
	// common actions
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgArchivePoliciesViewModel
