import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetActiveEngTypeList = (self: any) => ({
	getActiveEngTypeList() {
		// 0.
		const actionName = "getActiveEngTypeList"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.readActiveEngTypeList()
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					const fetchedEngTypes = response.data.Data
					let list: any[] = []
					fetchedEngTypes.map((engType: any) => {
						const reOrganizedEngType = {
							id: idToString(
								engType.EngagementTypeItemId,
								IdType.engType
							), // [EngagementID], ex) 159
							name: engType.EngagementTypeItemName,
							isActive: engType.IsActive,
						}
						list.push(reOrganizedEngType)
					})
					self.setActiveEngTypeList(list)

					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to get the active engagement type list",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,

						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetActiveEngTypeList
