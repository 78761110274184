import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	putRequest,
	postRequest,
	deleteRequest,
	getRequest,
} from "../../../../../library/api-requests"

export function create(params: {
	SortOrder: number
	RiskAssessmentItemName: string
	IsActive: boolean
}) {
	const response = postRequest({
		url: "/CreateRiskAssessmentItem",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function read(payload: { PageIndex?: number; PageSize: number }) {
	const response = getRequest({
		url: "/GetAllRiskAssessmentItems",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function readActiveRiskAssessment(payload: {
	PageIndex?: number
	PageSize: number
}) {
	const response = getRequest({
		url: "/GetAllActiveRiskAssessmentItems",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function update(params: {
	RiskAssessmentItemId: number
	RiskAssessmentItemName?: string
	SortOrder?: number
	IsActive?: boolean
}) {
	const response = postRequest({
		url: "/UpdateRiskAssessmentItem",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function del(params: { id: number }) {
	const response = deleteRequest({
		url: "/DeleteRiskAssessment",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgRiskAssessmentApiProps = {
	apiCreate: (params: {
		SortOrder: number
		RiskAssessmentItemName: string
		IsActive: boolean
	}) => Promise<AxiosResponse<any>>

	apiRead: (payload: {
		PageIndex?: number
		PageSize: number
	}) => Promise<AxiosResponse<any>>

	apiReadActive: (payload: {
		PageIndex?: number
		PageSize: number
	}) => Promise<AxiosResponse<any>>

	apiUpdate: (params: {
		RiskAssessmentItemId: number
		RiskAssessmentItemName?: string
		SortOrder?: number
		IsActive?: boolean
	}) => Promise<AxiosResponse<any>>
	apiDelete: (params: { id: number }) => Promise<AxiosResponse<any>>
}
