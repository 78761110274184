import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import ProjectUsers from "./ProjectUsers"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
//
import ProjUsersContextMenus from "./sub-components/ProjUsersContextMenus"
import EditProjUserDialog from "./dialogs/EditProjUserDialog"
import AddProjUserDialog from "./dialogs/AddProjUserDialog"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import CommonSnackbarComponent from "../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import ProjNoPermissionOnMenu from "../proj-access-control/ProjNoPermissionOnMenu"
import {
	ActionStatus,
	DLSector,
} from "../../../common-models/enumerations/common-enums"
import { ProjI18n } from "../../../common-models/enumerations/translation-sheets"

export default observer(function ProjectUsersController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const actionName = "getProjUsers"
	const menuId = DLProjSubMenus.users
	const i18nSheet = ProjI18n.users
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived

	const needRefresh = projStore.projUsers.needRefresh

	const menuAccess = projStore.checkin.checkAccess(menuId)
	let permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	useEffect(() => {
		if (menuAccess && needRefresh) {
			// because project info screen already called the user list, check the action status of it
			const action1_status =
				projStore.projUsers.getActionStatus("getProjUsers")
			if (
				action1_status !== ActionStatus.success &&
				action1_status !== ActionStatus.loading
			) {
				projStore.projUsers.getProjUsers()
			}

			// because project info screen already called the role set, check the action status of it
			const action2_status = projStore.roles.getActionStatus("getRoleSet")
			if (
				action2_status !== ActionStatus.success &&
				action2_status !== ActionStatus.loading
			) {
				projStore.roles.getRoleSet(DLSector.proj)
			}

			const action3_status = projStore.archivePreProcess.getActionStatus(
				"getPreConditionStatus"
			)
			if (
				action3_status !== ActionStatus.success &&
				action3_status !== ActionStatus.loading
			) {
				projStore.archivePreProcess.getPreConditionStatus()
			}
		}
	}, [menuAccess, needRefresh])

	useEffect(() => {
		// call the method (api) only for replica project
		const actionStatus = projStore.projUsers.getActionStatus(
			"getAssignUserEligibility"
		)
		if (
			projStore.projInfo.projInfo.statusName === "Replica" &&
			(needRefresh || actionStatus === undefined)
		) {
			projStore.projUsers.getAssignUserEligibility()
		}
	}, [needRefresh])

	let loadingProjRoleSet = projStore.roles.getActionStatus("getRoleSet")
	const i18n = store.i18n.combineI18n(i18nSheet)
	const orgRoleId = localStorage.getItem("orgRoleId")

	permission = {
		...permission,
		...((orgRoleId === "aul-org-2021-01-aa" ||
			orgRoleId === "aul-org-2021-01-sa") && {
			create: true,
			update: true,
			delete: true,
		}),
	}

	return (
		<>
			{menuAccess ? (
				loadingProjRoleSet === "SUCCESS" && (
					<>
						<ProjectUsers
							fetchingStatus={projStore.projUsers.getActionStatus(
								actionName
							)}
							roleSet={projStore.roles.viewRoleSet2()}
							permission={permission}
							i18n={i18n}
						/>
						<CommonSnackbarComponent
							i18n={i18n}
							partialStore={projStore.projUsers}
						/>
						{projStore.projUsers.clickPoint.mouseX && (
							<ProjUsersContextMenus permission={permission} />
						)}
						{projStore.projUsers.openAdd && (
							<AddProjUserDialog i18n={i18n} />
						)}
						{projStore.projUsers.openEdit && (
							<EditProjUserDialog i18n={i18n} />
						)}
					</>
				)
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
