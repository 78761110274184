import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../components/app-frame-elements"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import ProjArchDatePolicyNavController from "./sub-components/ProjArchDatePolicyNavController"
import ProjArchDatePolicyItems from "./sub-components/ProjArchDatePolicyItems"
import CreateArchiveDatePolicyDialog from "./sub-components/dialogs/CreateArchiveDatePolicyDialog"
import EditArchiveDatePolicyDialog from "./sub-components/dialogs/EditArchiveDatePolicyDialog"

export default observer(function OrgProjArchiveDatePolicy({
	partialStore,
	fetchingStatus,
	permission,
	contentHeight,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	contentHeight: any
}) {
	return (
		<PageContainer
			hasToolbar={false}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === "LOADING"}
		>
			{fetchingStatus === "SUCCESS" && (
				<div
					className="FR proj-arch-date-policy"
					style={{ height: contentHeight }}
				>
					<ProjArchDatePolicyNavController permission={permission} />
					<ProjArchDatePolicyItems partialStore={partialStore} />
				</div>
			)}
			<CommonSnackbarComponent i18n={{}} partialStore={partialStore} />
			{partialStore.openCreateDialog && <CreateArchiveDatePolicyDialog />}
			{partialStore.openEditDialog && <EditArchiveDatePolicyDialog />}
		</PageContainer>
	)
})
