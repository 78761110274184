import React, { useState, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { DLDialog } from "../../../../../components/basic-elements"
import ERPDataForm from "./ERPDataForm"
import { addTzToDate } from "../../../../../library/converters/date-utc-converter"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function UpdateERPDataDialog() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "editERPData"
	//
	const selectedItemInfo: any = orgStore.erpData.viewSelectedItemInfo()
	const initialData = {
		erpClientMasterAliasId: selectedItemInfo.erpClientMasterAliasId,
		erpClientMasterName: selectedItemInfo.erpClientMasterName,
		erpEngagementMasterAliasId: selectedItemInfo.erpEngagementMasterAliasId,
		erpEngagementMasterName: selectedItemInfo.erpEngagementMasterName,
		erpEngagementYear: selectedItemInfo.erpEngagementYear,
		erpEngagementType: selectedItemInfo.erpEngagementType,
		erpEngagementOwner: selectedItemInfo.erpEngagementOwner,
		erpProjectAliasId: selectedItemInfo.erpProjectAliasId,
		erpProjectName: selectedItemInfo.erpProjectName,
		erpProjectOwner: selectedItemInfo.erpProjectOwner,
		erpReportDate: selectedItemInfo.erpReportDate,
		erpReportReleaseDate: selectedItemInfo.erpReportReleaseDate,
	}
	const [inputs, setInputs] = useState(initialData)

	const dntFormat = store.global.getDntFormat
	const timeZone = store.global.timeZone
	const tzValue = timeZone.uiValue

	const handleChange = useCallback((event: any) => {
		let name = event.target.id
		let value = event.target.value
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	const handleDateInput = (name: any, date: any) => {
		const newDate = addTzToDate(date, tzValue)

		setInputs((inputs: any) => ({
			...inputs,
			[name]: newDate,
		}))
	}
	//
	const handleEdit = () => {}

	const validations = () => {
		if (
			inputs.erpClientMasterName !== "" &&
			selectedItemInfo.erpClientMasterName !== inputs.erpClientMasterName
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<DLDialog
			eleTestId="edit-erp-data-dialog"
			isOpen={orgStore.erpData.openEditDialog}
			setIsOpen={orgStore.erpData.setOpenEditDialog}
			handleAction={handleEdit}
			showCloseBtn={true}
			dialogTitle={"Edit ERP Data"}
			dialogContents={
				<ERPDataForm
					inputs={inputs}
					handleChange={handleChange}
					handleDateInput={handleDateInput}
					dntFormat={dntFormat}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={"Cancel"}
			actionReady={validations()}
			actionBtn={"Update"}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				orgStore.projArchDatePolicy.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.projArchDatePolicy.getActionStatus(actionName) ===
				"LOADING"
			}
		/>
	)
})
