import { types } from "mobx-state-tree"
// ViewModel, Modle, UiHelper
import ERPDataModel from "./erp-data.model"
import ERPDataViewModel from "./erp-data.view-model"
// common models
// apis
import * as api from "./erp-data.apis"

export const ERPDataStore = ({
	apiRead,
	apiUpdate,
	apiDelete,
	apiCreate,
}: api.ERPDataApiProps) =>
	types
		.compose(
			ERPDataModel({
				apiRead,
				apiUpdate,
				apiDelete,
				apiCreate,
			}),
			ERPDataViewModel
		)
		.named("ERPDataStore")

export const initialStore = {
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
}

const ERPDataStoreInstance = ERPDataStore({
	apiRead: api.read,
	apiUpdate: api.update,
	apiDelete: api.del,
	apiCreate: api.create,
}).create(initialStore)

export default ERPDataStoreInstance
