import React, { useEffect, useState } from "react"
import OrgHeader from "./header/OrgHeader"
import { OrgSidebar } from "./sidebars"

// WARNING: Have to remove
import { useTheme } from "@material-ui/core/styles"
import { useRootStore } from "../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
// sub components
import {
	StyledAppFrame,
	AppFrameStyles,
	StyledResizingTrigger,
} from "./app-frame.style"
import DLFixedTabsController from "./app-frame-tabs/DLFixedTabsController"
import RightDrawer from "./global-drawer/RightDrawer"
import HelpInformationDrawer from "./sub-components/HelpInformationDrawer"
// style
import StyledDLMenuTabs from "./dl-menu-tabs.style"
import { ConsoleLog } from "../basic-elements"
import { useOrgStore } from "../../stores/org-store/org-store.provider"
import { Helmet } from "react-helmet"
import styled from "styled-components"

/**
 * You can find this component on the OrgRouter and ProjRouter
 */

interface AppFrameProps {
	children: any
}

export default observer(function OrgSideAppFrame({ children }: AppFrameProps) {
	// re-rendering check
	ConsoleLog("** OrgSideAppFrame ******************", "")

	const store = useRootStore()
	const orgStore = useOrgStore()
	const dntFormat = store.global.getDntFormat
	const userList = orgStore.setupUsers.userListForRender
	const theme = useTheme()
	const classes = AppFrameStyles(theme)

	// FIXME: private router should be implemented overall area (current, just a '/' router is wrapped by private router)
	// FIXME: also private router need to be updated

	const showTabs = store.ui.showTabs

	const [dragStart, setDragStart] = useState(false)
	const [movedX, setMovedX] = useState(0)

	const handleMouseDown = (e: any) => {
		setDragStart(true)
	}
	const handleMouseUp = (e: any) => {
		setDragStart(false)
	}
	const handleMouseMove = (e: any) => {
		dragStart && setMovedX(e.movementX)
	}

	useEffect(() => {
		dragStart && store.ui.setDrawerWidth(movedX)
	}, [dragStart, movedX])

	return (
		<div onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Audit Lobby</title>
			</Helmet>
			<StyledAppFrame className={classes.root}>
				<div>
					<OrgSidebar />
					{/* <RightDrawer
							dntFormat={dntFormat}
							userList={userList}
						/> */}
					<HelpInformationDrawer />
				</div>
				<StyledResizingTrigger
					className="FR JC"
					onMouseDown={handleMouseDown}
				>
					<div className="notify-area"></div>
				</StyledResizingTrigger>
				<div
					className={
						store.ui.isSidebarOpen
							? classes.contentContainer
							: classes.contentContainerShift
					}
				>
					<OrgHeader />
					{showTabs && (
						<StyledDLMenuTabs className="page-header FR AC JSB">
							<DLFixedTabsController />
							{/* TODO: pageToolbar should be moved to page container */}
						</StyledDLMenuTabs>
					)}
					{/* <hr /> */}
					{children}
				</div>
			</StyledAppFrame>
		</div>
	)
})
