import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../library/converters/id-converter"
import { ArchPolicyPeriodProps } from "./arch-policy-period.data-model"

const GetArchPolicyPeriodListByEngTypeId = (self: any) => ({
	getArchPolicyPeriodListByEngTypeId(engTypeId: string) {
		// 0. set actionName
		const actionName = "getArchPolicyPeriodListByEngTypeId"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		console.log(engTypeId, "engTypeId")
		// 3) request API call to Model
		self.readArchPolicyPeriodListByEngTypeId(
			idToNumber(engTypeId, IdType.engType)
		)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					//
					const fetchedData = response.data.Data
					let list: ArchPolicyPeriodProps[] = []
					fetchedData.map((item: any) => {
						const reOrganizedItem = {
							id: idToString(
								item.ArchiveDatePolicyId,
								IdType.archPolicyPeriod
							),
							title: item.ArchiveDatePolicy,
							days: parseInt(
								item.ArchiveDatePolicy.split(" ")[0]
							),
						}
						list.push(reOrganizedItem)
					})
					self.setArchPolicyPeriodList(list)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage: "Successfully",
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetArchPolicyPeriodListByEngTypeId
