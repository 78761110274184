import { flow } from "mobx-state-tree"
import { ERPDataApiProps } from "../erp-data.apis"

const ReadERPData = (self: any, read: ERPDataApiProps["apiRead"]) => ({
	readERPData: flow(function* () {
		const actionName = "readERPData"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		// NOTE: NO PARAMS
		try {
			const response = yield read()
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadERPData
