import { flow } from "mobx-state-tree"
import { OrgEngTypeApiProps } from "../org-eng-types.apis"

const ReadEngTypeList = (
	self: any,
	apiRead: OrgEngTypeApiProps["apiRead"]
) => ({
	readEngTypeList: flow(function* () {
		const actionName = "readEngTypeList"
		try {
			const response = yield apiRead()
			// ConsoleLog("readEngTypeList response", response)
			return response
		} catch (error) {
			// console.error("readEngTypeList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadEngTypeList
