import { flow } from "mobx-state-tree"
import { OrgProjArchDatePolicyApiProps } from "../org-proj-arch-date-policies.apis"

const CreateArchiveDatePolicy = (
	self: any,
	create: OrgProjArchDatePolicyApiProps["apiCreate"]
) => ({
	createArchiveDatePolicy: flow(function* (
		title: string,
		description: string
	) {
		const actionName = "createArchiveDatePolicy"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		const payload = {
			ArchiveDatePolicySetName: title,
			ArchiveDatePolicySetDescription: description,
		}
		try {
			const response = yield create(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default CreateArchiveDatePolicy
