import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import {
	RiskAssessment,
	ProjectDetails,
	ActiveRiskAssessment,
} from "./data-models/org-setup-risk-assessment.data-models"
import {
	GetRiskAssessmentList,
	GetActiveRiskAssessmentList,
	AddRiskAssessment,
	EditRiskAssessment,
	RemoveRiskAssessment,
} from "./view-model-actions"
import { compareDesc } from "date-fns"
import { initialStore } from "./org-risk-assessment.provider"
import {
	formattedDateWithoutTzLabel,
	getTzDate,
} from "../../../../../library/converters/date-utc-converter"

const OrgRiskAssessmentViewModel = types
	.model({
		//
		riskAssessmentList: types.array(RiskAssessment),
		activeRiskAssessmentList: types.array(ActiveRiskAssessment),
		projectDetails: types.array(ProjectDetails),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		resetList() {
			self.riskAssessmentList.length = 0
		},
		pushItemToList(item: any) {
			self.riskAssessmentList.push(item)
		},
		setRiskAssessmentList(items: any) {
			self.riskAssessmentList.length = 0
			self.riskAssessmentList = items
		},
		setActiveRiskAssessmentList(items: any) {
			self.activeRiskAssessmentList.length = 0
			self.activeRiskAssessmentList = items
		},
		resetProjList() {
			self.projectDetails.length = 0
		},
		pushItemToProjList(item: any) {
			self.projectDetails.push(item)
		},
		removeRiskAssessmentStore(id: string) {
			self.riskAssessmentList.splice(
				self.riskAssessmentList.findIndex(
					(item) => item.riskAssessmentId === id
				),
				1
			)
		},
		updateRiskAssessmentStore(
			modifiedAt: string,
			modifiedBy: string,
			riskAssessmentId: string,
			riskAssessmentName?: string,
			sortOrder?: number,
			isActive?: boolean
		) {
			const target = self.riskAssessmentList.find(
				(item) => item.riskAssessmentId === riskAssessmentId
			)
			if (!target) {
				console.error("No matched engType ID")
				return
			}
			target.modifiedAt = modifiedAt
			target.modifiedBy = modifiedBy
			if (riskAssessmentName !== undefined)
				target.riskAssessmentName = riskAssessmentName
			if (sortOrder !== undefined) target.sortOrder = sortOrder
			if (isActive !== undefined) {
				target.isActive = isActive
				target.isActive
					? (target.activatedAt = modifiedAt)
					: (target.deActivatedAt = modifiedAt)
			}
		},
	}))
	//CRUD
	.actions(GetRiskAssessmentList)
	.actions(GetActiveRiskAssessmentList)
	.actions(EditRiskAssessment)
	.actions(AddRiskAssessment)
	.actions(RemoveRiskAssessment)
	.views((self) => ({
		getRiskAssessmentById(id: string) {
			return self.riskAssessmentList.find(
				(element) => element.riskAssessmentId === id
			)
		},
		formattedList(dntFormat: DnTFormatProps) {
			const formatDate = (date: string | null | undefined) =>
				date ? formattedDateWithoutTzLabel({ date, dntFormat }) : "-"

			// Sort the original list by sortOrder
			const sortedList = [...self.riskAssessmentList].sort(
				(a, b) => a.sortOrder - b.sortOrder
			)

			// Format the list
			return sortedList.map((item) => ({
				...item,
				createdAt: formatDate(item.createdAt),
				modifiedAt: formatDate(item.modifiedAt),
				activatedAt: formatDate(item.activatedAt),
				deActivatedAt: formatDate(item.deActivatedAt),
			}))
		},
		getActiveList() {
			return self.riskAssessmentList.filter((item) => item.isActive)
		},
		getInactiveList() {
			return self.riskAssessmentList.filter((item) => !item.isActive)
		},
		isDuplicatedName(name: string) {
			return self.riskAssessmentList.some(
				(risk: any) => risk.riskAssessmentName === name
			)
		},
		isDuplicatedSortOrder(sortOrder: string) {
			return self.riskAssessmentList.some(
				(risk: any) => risk.sortOrder === +sortOrder
			)
		},
		get riskAssessmentOptions() {
			let options: { id: string; title: string }[] = []
			self.riskAssessmentList.map((item) => {
				options.push({
					id: item.riskAssessmentId,
					title: item.riskAssessmentName,
				})
			})
			return options
		},
		get activeRiskAssessmentOptions() {
			return self.activeRiskAssessmentList.map((item) => ({
				id: item.riskAssessmentId,
				title: item.riskAssessmentName,
			}))
		},
	}))
	//COMMON
	.actions(CommonViewModelActions)
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupRa")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.views(ViewResponseHelper)

export default OrgRiskAssessmentViewModel
