import { types, applySnapshot } from "mobx-state-tree"
import { Responses, ResponseSnackbar } from "../../../../../common-models"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./org-proj-arch-date-policy.provider"
import GetProjArchDatePolicies from "./view-model-actions/get-proj-arch-date-policies"
import { getTreeFromFlatData } from "../../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { ArchDatePolicyFlatItem } from "./data-models/proj-arch-date-policies.data-models"
import { formattedDateWithoutTzLabel } from "../../../../../library/converters/date-utc-converter"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import EditArchivePolicyDatePolicyItem from "./view-model-actions/edit-arch-date-policy-item"
import { ClickPoint } from "../../../../../common-models/types/dialog.props"
import AddArchiveDatePolicy from "./view-model-actions/add-archive-date-policy"
import EditArchiveDatePolicy from "./view-model-actions/edit-archive-date-policy"
import RemoveArchiveDatePolicy from "./view-model-actions/remove-archive-date-policy"
import CopyArchiveDatePolicy from "./view-model-actions/copy-archive-date-policy"
import MoveEngType from "./view-model-actions/move-eng-type"

export const OrgProjArchiveDatePolicyViewModel = types
	.model({
		flatList: types.array(ArchDatePolicyFlatItem),
		openCreateDialog: false,
		openEditDialog: false,
		openEditSortingOrderDialog: false,
		selectedPolicy: "",
		clickPoint: ClickPoint,
		// tableClickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	// actions
	.actions((self) => ({
		pushItemToFlatList(item: any) {
			self.flatList.push(item)
		},
		spliceItemFromFlatlist(id: string) {
			self.flatList.splice(
				self.flatList.findIndex((item: any) => item.id === id),
				1
			)
		},
		toggleExpand(id: string, expanded?: boolean) {
			const target = self.flatList.find((item) => item.id === id)
			if (target) {
				target.expanded = expanded ? expanded : !target.expanded
			}
		},
		setSelectedPolicy(id: string) {
			self.selectedPolicy = id
		},
		updateArchivePolicyItem(
			modifiedAt: string,
			modifiedBy: string,
			policyId: string,
			policyItemId: number,
			SortOrder: number,
			isActive?: boolean
		) {
			console.log(isActive, Boolean(isActive))
			const target = self.flatList.find((item) => item.id === policyId)
			if (!target) {
				console.error(`Policy with ID ${policyId} not found.`)
				return
			}
			console.log("modifiedByUserName", modifiedBy)
			const updatedItems = target.archiveDatePolicyItems?.map((item) => {
				return item.ArchiveDatePolicyItemId === policyItemId
					? {
							...item,
							IsActive:
								isActive !== undefined
									? isActive
									: item.IsActive,
							SortOrder,
							ModifiedAtUtc: modifiedAt,
							ModifiedByUserName: modifiedBy,
							ActivatedAtUtc: isActive
								? modifiedAt
								: item.ActivatedAtUtc,
							ActivatedByUserName: isActive
								? modifiedBy
								: item.ActivatedByUserName,
							DeActivatedAtUtc: !isActive
								? modifiedAt
								: item.DeActivatedAtUtc,
							DeActivatedByUserName: !isActive
								? modifiedBy
								: item.DeActivatedByUserName,
					  }
					: item
			})
			// Update the entire array at once for better performance
			target.archiveDatePolicyItems = updatedItems?.sort(
				(a, b) => a.SortOrder - b.SortOrder
			)
		},
		setClickPoint(clickPoint: typeof self.clickPoint) {
			self.clickPoint = clickPoint
		},
		setOpenCreateDialog(request?: boolean) {
			self.openCreateDialog = request ?? !self.openCreateDialog
		},
		setOpenEditDialog(request?: boolean) {
			self.openEditDialog = request ?? !self.openEditDialog
		},
		setOpenEditSortingOrderDialog(request?: boolean) {
			self.openEditSortingOrderDialog =
				request ?? !self.openEditSortingOrderDialog
		},
		isDuplicatedTitle(name: string) {
			return self.flatList.some(
				(item: any) =>
					item.title.toLocaleLowerCase() === name.toLocaleLowerCase()
			)
		},
		editPolicyInfo(title: string, description: string, policyId: string) {
			const index = self.flatList.findIndex(
				(item: any) => item.id === policyId
			)
			self.flatList[index].title = title
			self.flatList[index].description = description
		},
		moveEngTypeInFlatList(engTypeId: string, newPolicyId: string) {
			const index = self.flatList.findIndex(
				(item: any) => item.id === engTypeId
			)
			self.flatList[index].parentId = newPolicyId
		},
	}))
	.actions(GetProjArchDatePolicies)
	.actions(EditArchivePolicyDatePolicyItem)
	.actions(AddArchiveDatePolicy)
	.actions(EditArchiveDatePolicy)
	.actions(RemoveArchiveDatePolicy)
	.actions(CopyArchiveDatePolicy)
	.actions(MoveEngType)
	.views((self) => ({
		get simpleTreeForNav() {
			return getTreeFromFlatData(self.flatList)
		},
		get selectedPolicyInfo() {
			const target = self.flatList.find(
				(item: any) => item.id === self.selectedPolicy
			)
			return target
		},
		viewSelectedPolicy() {
			const target = self.flatList.find(
				(i: any) => i.id === self.selectedPolicy
			)
			return target
		},
		viewSelectedActiveDatePolicy(dntFormat: DnTFormatProps) {
			const target = self.flatList.find(
				(i: any) => i.id === self.selectedPolicy
			)
			const activePolicy = target?.archiveDatePolicyItems?.filter(
				(i) => i.IsActive
			)

			function formatDate(date: string | null) {
				if (!date) return "-"
				return formattedDateWithoutTzLabel({
					date,
					dntFormat,
				})
			}

			return activePolicy?.map((item) => ({
				...item,
				ModifiedAtUtc: formatDate(item.ModifiedAtUtc),
				ActivatedAtUtc: formatDate(item.ActivatedAtUtc),
			}))
		},
		viewSelectedInActiveDatePolicy(dntFormat: DnTFormatProps) {
			const target = self.flatList.find(
				(i: any) => i.id === self.selectedPolicy
			)
			const inactivePolicy = target?.archiveDatePolicyItems?.filter(
				(i) => !i.IsActive
			)

			function formatDate(date: string | null) {
				if (!date) return "-"
				return formattedDateWithoutTzLabel({
					date,
					dntFormat,
				})
			}

			return inactivePolicy?.map((item) => ({
				...item,
				ModifiedAtUtc: formatDate(item.ModifiedAtUtc),
				DeActivatedAtUtc: formatDate(item.DeActivatedAtUtc),
			}))
		},
		isSelected(id: string) {
			return self.selectedPolicy === id
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)
