import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import PageContainer from "../../../../components/app-frame-elements/sub-components/PageContainer"
import styled from "styled-components"
import { DLButton, DLRadioGroup } from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

export default observer(function OrgSetupProjectAdminSettings({
	partialStore,
	fetchingStatus,
	permission,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const [projectAdminSettings, setProjectAdminSettings] = useState({
		replicaTeamMemberConfiguration: "1",
		replicaProjectAssignUserEligibility: "1",
	})
	const isUpdating =
		partialStore.getActionStatus("editOrgSetupProjectAdminSettings") ===
		ActionStatus.loading
	const dntFormat = store.global.getDntFormat

	const settings = partialStore.viewProjAdminSettings()
	const editHistory = partialStore.viewEditHistory(dntFormat)

	const userInfo = orgStore.checkin.orgInfo
	const modifiedByUserName = `[${userInfo.userAliasId}] ${userInfo.userName}`

	useEffect(() => {
		if (fetchingStatus === ActionStatus.success) {
			setProjectAdminSettings({
				replicaTeamMemberConfiguration:
					settings.replicaTeamMemberConfiguration.toString(),
				replicaProjectAssignUserEligibility:
					settings.replicaProjectAssignUserEligibility.toString(),
			})
		}
	}, [fetchingStatus])

	const handleInputs = (e: any) => {
		const { name, value } = e.target
		setProjectAdminSettings((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleSave = () => {
		let proceed = window.confirm(
			"Are you sure you want to save the changes?"
		)
		if (!proceed) {
			return
		}

		partialStore.editOrgSetupProjectAdminSettings({
			ReplicaProjectAssignUserEligibility: parseInt(
				projectAdminSettings.replicaProjectAssignUserEligibility
			),
			ReplicaTeamMemberConfiguration: parseInt(
				projectAdminSettings.replicaTeamMemberConfiguration
			),
			modifiedByUserName,
		})
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === ActionStatus.loading || isUpdating}
		>
			<StyledSetupRiskAssessment>
				<h2>Replica Settings</h2>
				<div className="section">
					<div className="FC">
						<div className="header">
							Replica Project Team Member Status - At Replica
							creation
						</div>
						<div className="radioGroup">
							<DLRadioGroup
								groupName="replicaTeamMemberConfiguration"
								itemDirection="column"
								items={[
									{
										value: "1",
										label: "Active: Current Active Project Team Members",
									},
									{
										value: "2",
										label: "Active: Only Current Active EP Role",
									},
									{
										value: "3",
										label: "Active: Only Current Project Archive & Admin Access Right",
									},
									{
										value: "4",
										label: "Inactive: All Current Active Project Team Members",
									},
								]}
								eleOnChange={handleInputs}
								eleClassName="replicaTeamMemberConfiguration"
								selectedValue={
									projectAdminSettings.replicaTeamMemberConfiguration
								}
							/>
						</div>
					</div>
				</div>
				<div className="section">
					<div className="FC">
						<div className="header">
							Replica Project Assign User Eligibility
						</div>
						<div className="radioGroup">
							<DLRadioGroup
								groupName="replicaProjectAssignUserEligibility"
								itemDirection="column"
								items={[
									{
										value: "1",
										label: "Org Super Admin Only",
									},
									{
										value: "2",
										label: "Org Super Admin + Project & Archive Admin/ Project Admin [Project Access Type]",
									},
								]}
								eleClassName="replicaProjectAssignUserEligibility"
								selectedValue={
									projectAdminSettings.replicaProjectAssignUserEligibility
								}
								eleOnChange={handleInputs}
							/>
						</div>
					</div>
				</div>
				<div className="section FR AC">
					{permission.update && (
						<div className="FR">
							<DLButton
								variant="contained"
								eleTestId="add-group-dialog-open-btn"
								color="primary"
								clickHandler={handleSave}
								eleClassName="FR"
							>
								Save
							</DLButton>
						</div>
					)}
					<div>
						*This configuration is last modified by{" "}
						<b>{editHistory?.updatedBy}</b> on{" "}
						<b>{editHistory?.updatedAt}</b>
					</div>
				</div>
			</StyledSetupRiskAssessment>
		</PageContainer>
	)
})

const StyledSetupRiskAssessment = styled.div`
	padding: 0 1rem;
	.section {
		.header {
			font-weight: bold;
			font-size: 1rem;
		}
		margin-top: 10px;
		padding: 10px 0px 10px 30px;
		.radioGroup {
			padding: 10px 0px 10px 30px;
		}
	}
`
