import React from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { DLButton } from "../../../../components/basic-elements"
import SimpleMDE from "react-simplemde-editor"
import "easymde/dist/easymde.min.css"

export default observer(function Note({
	partialStore,
	saveNotesPermission,
}: {
	partialStore: any
	saveNotesPermission: boolean
}) {
	// const isLocked = projStore.projInfo.projInfo.isLocked

	// const modules = {
	// 	toolbar: [
	// 		[{ header: "1" }, { header: "2" }, { font: [] }],
	// 		[{ size: [] }],
	// 		["bold", "italic", "underline", "strike", "blockquote"],
	// 		[
	// 			{ list: "ordered" },
	// 			{ list: "bullet" },
	// 			{ indent: "-1" },
	// 			{ indent: "+1" },
	// 		],
	// 		["image", "video"],
	// 		[{ color: [] }, { background: [] }],
	// 	],
	// 	clipboard: {
	// 		// toggle to add extra line breaks when pasting HTML:
	// 		matchVisual: false,
	// 	},
	// }

	// const formats = [
	// 	"header",
	// 	"font",
	// 	"size",
	// 	"bold",
	// 	"italic",
	// 	"underline",
	// 	"strike",
	// 	"blockquote",
	// 	"list",
	// 	"bullet",
	// 	"indent",
	// 	"link",
	// 	"image",
	// 	"video",
	// 	"color",
	// 	"background",
	// ]

	const changeHandler = (value: string) => {
		partialStore.updateNotes(value)
	}

	const saveNotes = () => {
		partialStore.saveNotes(partialStore.notes, partialStore.noteId)
	}

	return (
		<PageContainer pageTools={<NoteToolbar />}>
			<StyledNote>
				{saveNotesPermission && (
					<StyledButton>
						<DLButton
							variant="contained"
							eleTestId="notes-save-btn"
							color="primary"
							clickHandler={saveNotes}
							align="right"
						>
							Save
						</DLButton>
					</StyledButton>
				)}

				<SimpleMDE
					className="md-editor"
					value={partialStore.viewNotes()}
					onChange={changeHandler}
					data-testid="post-md-editor"
				/>
			</StyledNote>
		</PageContainer>
	)
})

const NoteToolbar = () => {
	return <div>note page toolbar</div>
}

const StyledNote = styled.div`
	.quill {
		width: 100%;
		height: 410px;
	}
`

const StyledButton = styled.div`
	float: right;
	padding: 6px;
	position: relative;
	z-index: 10;
`
