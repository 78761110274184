import { flow } from "mobx-state-tree"
import { OrgProjArchDatePolicyApiProps } from "../org-proj-arch-date-policies.apis"

const ReadProjArchDatePolicies = (
	self: any,
	read: OrgProjArchDatePolicyApiProps["apiRead"]
) => ({
	readProjArchDatePolicies: flow(function* () {
		const actionName = "readProjArchDatePolicies"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		// NOTE: NO PARAMS
		try {
			const response = yield read()
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadProjArchDatePolicies
