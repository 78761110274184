import React, { useState, useEffect } from "react"
import ProjHeader from "./header/ProjHeader"
import { ProjSidebar } from "./sidebars"
// WARNING: Have to remove
import { useTheme } from "@material-ui/core/styles"
import { useRootStore } from "../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import {
	StyledAppFrame,
	AppFrameStyles,
	StyledResizingTrigger,
} from "./app-frame.style"
import DLDynamicTabs from "./app-frame-tabs/DLDynamicTabs"
import RightDrawer from "./global-drawer/RightDrawer"
import StyledDLMenuTabs from "./dl-menu-tabs.style"
import CommentDrawer from "../../screens/project-side/communications/proj-comments/CommentDrawer"
import DiagnosisDrawer from "../../screens/project-side/archive/archive-diagnosis/DiagnosisDrawer"
import { useProjStore } from "../../stores/proj-store/proj-store.provider"
import HelpInformationDrawer from "./sub-components/HelpInformationDrawer"
import { Helmet } from "react-helmet"

/**
 * You can find this component on the OrgRouter and ProjRouter
 */

interface AppFrameProps {
	children: any
}

export default observer(function ProjectSideAppFrame({
	children,
}: AppFrameProps) {
	const store = useRootStore()
	const projStore = useProjStore()
	const dntFormat = store.global.getDntFormat

	const theme = useTheme()
	const classes = AppFrameStyles(theme)
	const projTitle = projStore.projInfo.projInfo.projTitle
	const projAliasId = projStore.projInfo.projInfo.projAliasId

	// FIXME: private router should be implemented overall area (current, just a '/' router is wrapped by private router)
	// FIXME: also private router need to be updated

	const [dragStart, setDragStart] = useState(false)
	const [movedX, setMovedX] = useState(0)

	const handleMouseDown = (e: any) => {
		setDragStart(true)
	}
	const handleMouseUp = (e: any) => {
		setDragStart(false)
	}
	const handleMouseMove = (e: any) => {
		dragStart && setMovedX(e.movementX)
	}

	useEffect(() => {
		dragStart && store.ui.setDrawerWidth(movedX)
	}, [dragStart, movedX])

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					{projTitle
						? `(${projAliasId}) ${projTitle}`
						: "Audit Lobby"}
				</title>
			</Helmet>
			<StyledAppFrame
				className={classes.root}
				onMouseUp={handleMouseUp}
				onMouseMove={handleMouseMove}
			>
				<div>
					<ProjSidebar />
					<RightDrawer dntFormat={dntFormat} userList={[]} />
					<HelpInformationDrawer />
				</div>
				<StyledResizingTrigger
					className="FR JC"
					onMouseDown={handleMouseDown}
				>
					<div className="notify-area"></div>
				</StyledResizingTrigger>
				<div
					className={
						store.ui.isSidebarOpen
							? classes.contentContainer
							: classes.contentContainerShift
					}
				>
					<ProjHeader />
					<StyledDLMenuTabs className="page-header FR AC JSB">
						<DLDynamicTabs />
						{/* TODO: pageToolbar should be moved to page container */}
					</StyledDLMenuTabs>
					{children}
				</div>
				{/* {!projStore.lib.firstCalled && <LibraryDrawerInProject />} */}
				{/* Moved this to WP
				{!projStore.lib.firstCalled && <TemplateDrawerInProject />} */}
				{!projStore.comments.firstCalled && <CommentDrawer />}
				{!projStore.archive.firstCalled && <DiagnosisDrawer />}
			</StyledAppFrame>
		</div>
	)
})
