import { flow } from "mobx-state-tree"
import { OrgRiskAssessmentApiProps } from "../org-risk-assessment.apis"

const ReadActiveRiskAssessmentList = (
	self: any,
	apiReadActive: OrgRiskAssessmentApiProps["apiReadActive"]
) => ({
	readActiveRiskAssessmentList: flow(function* (payload: {
		PageIndex?: number
		PageSize: number
	}) {
		const actionName = "readActiveRiskAssessment"
		if (typeof payload.PageSize === "number") {
			try {
				const response = yield apiReadActive(payload)
				// ConsoleLog("requestActiveRiskAssessmentList response", response)
				return response
			} catch (error) {
				// console.error("requestActiveRiskAssessmentList error", error)
				self.handleModelError({ actionName, error, openSnackbar: true })

				return false
			}
		} else {
			alert(`(${actionName}) invalid prop exist. ${payload.PageSize}`)
		}
	}),
})

export default ReadActiveRiskAssessmentList
