import { flow } from "mobx-state-tree"
import { OrgRiskAssessmentApiProps } from "../org-risk-assessment.apis"

const UpdateRiskAssessment = (
	self: any,
	apiUpdate: OrgRiskAssessmentApiProps["apiUpdate"]
) => ({
	updateRiskAssessment: flow(function* (payload: {
		RiskAssessmentItemId: number
		RiskAssessmentItemName?: string
		SortOrder?: number
		IsActive?: boolean
	}) {
		const actionName = "updateRiskAssessment"
		if (typeof payload.RiskAssessmentItemId === "number") {
			try {
				const response = yield apiUpdate(payload)

				// ConsoleLog("requestRiskAssessmentList response", response)
				return response
			} catch (error) {
				// console.error("requestRiskAssessmentList error", error)
				self.handleModelError({ actionName, error, openSnackbar: true })

				return false
			}
		} else {
			alert(
				`(${actionName}) invalid prop exist. ${payload.RiskAssessmentItemId}`
			)
		}
	}),
})

export default UpdateRiskAssessment
