import { types } from "mobx-state-tree"
// for tree data
import { getFlatDataFromTree } from "react-sortable-tree"
import {
	blueTheme1,
	blueTheme2,
	darkTheme,
	greenTheme1,
	greenTheme2,
	grayTheme1,
	grayTheme2,
	solarizedTheme,
} from "../../../assets/styles/themes"
/**
 * ! current view type (org, proj) is managed by global store
 *
 */

type NodeProps = {
	node: any
}

const UiViewModel = types
	.model({
		showTabs: types.boolean,
		drawerWidth: types.number,
		collapsedDrawerWidth: types.number,
		windowInnerWidth: types.number,
		windowInnerHeight: types.number,
		theme: types.string /* Do not use enumeration for theme */,
		isSidebarOpen: types.boolean,
		isRightDrawerOpen: types.boolean,
		isHelpInfoDrawerOpen: false,
		isPageToolbarOpen: types.boolean,
		pageContentsAreaHeight: types.number,
	})
	.actions((self) => ({
		setDrawerWidth(value: any) {
			self.drawerWidth = self.drawerWidth + value
		},
	}))
	.views((self) => ({
		getThemeValue(attr: string) {
			switch (self.theme) {
				case "blue1":
					return blueTheme1[attr]
				case "blue2":
					return blueTheme2[attr]
				case "dark":
					return darkTheme[attr]
				case "gray1":
					return grayTheme1[attr]
				case "gray2":
					return grayTheme2[attr]
				case "green1":
					return greenTheme1[attr]
				case "green2":
					return greenTheme2[attr]
				case "solarized":
					return solarizedTheme[attr]
			}
		},
		getThemeShades() {
			console.log(self, "self")
			// const colors = ["shade05","shade10","shade20","shade30","shade40","shade60","shade90"].map((item:string) => {
			// 	self.getThemeValue(item)
			// })
		},
		get currentPageContentsAreaHeight() {
			if (self.isPageToolbarOpen) {
				return window.innerHeight - 48 * 2 // window.innerHeight - 48 * 3
			}
			return window.innerHeight - 48 // window.innerHeight - 48 * 2
		},
		get contentsAreaWidth() {
			let width: any
			if (self.isSidebarOpen) {
				width = self.windowInnerWidth - self.drawerWidth
			} else {
				width = self.windowInnerWidth - self.collapsedDrawerWidth
			}
			return width
		},
		get contentsAreaHeight() {
			let height: any
			if (self.isPageToolbarOpen) {
				// return window.innerHeight - 48 * 3
				// height = self.windowInnerHeight - 48 * 2 // because the tab was removed
				height = self.windowInnerHeight - 48 * 3 // TODO: update is required
			} else {
				// height = self.windowInnerHeight - 48 // because the tab was removed
				height = self.windowInnerHeight - 48 * 2 // TODO: update is required
			}
			return height
		},
	}))
	.views((self) => ({
		getThemeShades() {
			const colors = [
				"emphasis",
				"mainColor",
				"subColor",
				"shade05",
				"shade10",
				"shade20",
				"shade30",
				"shade40",
				"shade60",
				"shade90",
			].map((item: string) => self.getThemeValue(item))
			return colors
		},
	}))
	.actions((self) => ({
		setShowTabs(request: boolean) {
			self.showTabs = request
		},
		setTheme(request: string) {
			self.theme = request
		},
		setSidebarOpen(request: boolean) {
			self.isSidebarOpen = request
		},
		setRightDrawerOpen(request: boolean) {
			self.isRightDrawerOpen = request
		},
		setHelpInfoDrawerOpen(request: boolean) {
			self.isHelpInfoDrawerOpen = request
		},
		setPageToolbarOpen(request: boolean) {
			self.isPageToolbarOpen = request
		},
		// setCurrentView(viewType) {
		// 	self.currentView = viewType
		// },
		// TODO:  are velow requried?
		getCollapsedTreeFlatList(tree: any) {
			const getNodeKey = ({ node }: NodeProps) => node.id
			const collapsedTree = {
				treeData: tree.treeData,
				getNodeKey: getNodeKey,
				ignoreCollapsed: true,
			}
			return getFlatDataFromTree(collapsedTree)
		},
		getOverallTreeFlatList(tree: any) {
			const getNodeKey = ({ node }: NodeProps) => node.id
			const overallTree = {
				treeData: tree.treeData,
				getNodeKey: getNodeKey,
				ignoreCollapsed: false,
			}
			return getFlatDataFromTree(overallTree)
		},
		setWindowInnerWidth(width: number) {
			self.windowInnerWidth = width
		},
		setWindowInnerHeight(height: number) {
			self.windowInnerHeight = height
		},
	}))

export default UiViewModel
