import React from "react"
import styled from "styled-components"
import { makeStyles, alpha } from "@material-ui/core/styles"

const StyledAppFrame = styled.div`
	background-color: ${(props) => props.theme.background};
	color: ${(props) => props.theme.textMediumEmphasis};
	/* below lines do not work.. how to apply theme to scrollbar..?? */
	::-webkit-scrollbar-track:hover {
		background-color: ${(props) => props.theme.shade10} !important;
	}
	::-webkit-scrollbar-thumb {
		background-color: ${(props) => props.theme.shade30} !important;
	}
	::-webkit-scrollbar-thumb:hover {
		background-color: ${(props) => props.theme.shade40} !important;
	}
`

const AppFrameStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	contentContainer: {
		width: `calc(100vw - ${(props: any) => props.collapsedDrawerWidth}px)`,
		overflow: "hidden",
	},
	contentContainerShift: {
		width: `calc(100vw - ${(props: any) => props.collapsedDrawerWidth}px)`,
		overflow: "hidden",
	},

	// this menuTitleBar for sub header which has menu title. origin=appBar
	menuTitleBar: {
		display: "flex",
		flexDirection: "row",

		padding: theme.spacing(0, 0, 1),
		color: "${props=> props.theme.textHighEmphasis}",
		boxShadow: "none",
		backgroundColor: "transparent",
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	menuTitleBarShift: {
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuTitle: {
		marginRight: 16,
	},
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(3),
			width: "auto",
		},
	},
	searchIcon: {
		width: theme.spacing(7),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: "inherit",
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: 200,
		},
	},
	secondaryButtons: {
		justifyContent: "flex-end",
	},
}))

const StyledResizingTrigger = styled.div`
	/* NOTE: to calculate, to not use rem for width */
	width: 3px;
	.notify-area {
		min-width: 3px;
		padding: 1rem 0;
		transition: 0.4s;
		border-radius: 1.5px;
		height: 100%;
		background-color: ${(props) => props.theme.primaryLight};
	}
	:hover {
		cursor: col-resize;
	}
`

export { StyledAppFrame, AppFrameStyles, StyledResizingTrigger }
