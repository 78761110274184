import { AxiosResponse } from "axios"
import { getRequest, postRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

export async function read() {
	const response = await getRequest({
		url: "/GetArchiveConfiguration",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function update(payload: any) {
	const response = await postRequest({
		url: "/UpdateArchiveConfiguration",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgSetupProjectAdminSettingsApiProps = {
	apiRead: () => Promise<AxiosResponse<any>>
	apiUpdate: (params: {
		ReplicaProjectAssignUserEligibility: number
		ReplicaTeamMemberConfiguration: number
	}) => Promise<AxiosResponse<any>>
}
