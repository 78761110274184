import React, { useState } from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { DLButton } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import {
	mdiMessageTextOutline,
	mdiDownload,
	mdiShieldLockOutline,
} from "@mdi/js"
import { ProjectLockDialog, ProjectUnLockDialog } from "./dialogs"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { Link, useHistory } from "react-router-dom"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import { SupportedLanguage } from "../../../../../common-models/enumerations/common-enums"

export default observer(function ArchMainActionBtns({
	permission,
	partialStore,
	projectStatus,
	projectId,
	rootStore,
}: {
	permission: PermissionAsObjectProps
	partialStore: any
	projectStatus: any
	projectId: string
	rootStore: any
}) {
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	const history = useHistory()
	const [projLockDialogOpen, setProjLockDialogOpen] = useState(false)
	const [projUnLockDialogOpen, setProjUnLockDialogOpen] = useState(false)
	//
	/**
	 * < permission >
	 * - archive
	 * - lock
	 * - export
	 * - viewRationales
	 */
	const redirectTab = () => {
		history.push(`${defaultUrl}/archive/archive-pre-process`)
	}

	const renderRationaleButton = () => {
		const menuId = DLProjSubMenus.rationales
		const menuInfo = projStore.checkin.getMenuInfoById(menuId)
		const parentMenu =
			menuInfo?.menuGroup &&
			projStore.checkin.getMenuInfoById(menuInfo.menuGroup)

		const parentUrl = parentMenu && parentMenu.url

		if (menuInfo && parentUrl) {
			return (
				<Link
					to={defaultUrl + "/" + parentUrl + "/" + menuInfo.url}
					onClick={() => {
						menuInfo && projStore.menuTabs.addOpenedMenu(menuInfo)
					}}
				>
					<DLButton
						eleTestId="rationale-btn"
						startIcon={
							<Icon path={mdiMessageTextOutline} size={0.8} />
						}
					>
						Rationales
					</DLButton>
				</Link>
			)
		} else {
			return <div />
		}
	}

	const adminMode = localStorage.getItem("isAdminAppUser") === "true"
	const projSize = projStore.archive.projectSize
	const numSize = parseInt(projSize.slice(0, -2))
	let oversized: boolean = false
	if (projSize.includes("MB")) {
		if (numSize >= 3072) {
			oversized = true
		}
	} else if (projSize.includes("KB")) {
		//
	} else {
		console.log("? project size has weird format")
	}

	const { archiveChecklistItem1, archiveChecklistItem2 } =
		projStore.projInfo.projInfo

	const { isArchived, isLocked, statusId } = projStore.projInfo.projInfo

	const isPreProcessed =
		projStore.archivePreProcess.preConditionData.isPreProcessed

	console.log(isArchived, statusId, "projStoreisArchived")

	const disableBtn =
		archiveChecklistItem1 === "" ||
		archiveChecklistItem1 === null ||
		archiveChecklistItem1 === undefined ||
		archiveChecklistItem2 === "" ||
		archiveChecklistItem2 === null ||
		archiveChecklistItem2 === undefined

	// ConsoleLog([partialStore.getConditionInfo("archiveConditionId16"), "lock"])
	const archDiagFooterNotes_1 =
		rootStore.i18n.language === SupportedLanguage.kr
			? {
					message: (
						<>
							<b>[Archive Pre-process] 단계</b>를 수행하기
							위해서는 <b>3.Archive Diagnosis</b>의 모든 필수
							요건이 모두 완료되어야 합니다.
						</>
					),
			  }
			: {
					message: (
						<>
							All Required Conditions in{" "}
							<strong>3. Archive Diagnosis Section</strong> should
							be completed to click [Archive Pre-process].
						</>
					),
			  }
	const archDiagFooterNotes_2 =
		rootStore.i18n.language === SupportedLanguage.kr
			? {
					message: (
						<>
							<b>[Archive Pre-process] 화면</b>에서{" "}
							<b>[Cancel/취소]</b> 를 수행한 이후에{" "}
							<b>[프로젝트 잠금해제(UnLock)]</b>을 진행할 수
							있습니다.
						</>
					),
			  }
			: {
					message: (
						<>
							To unlock the project, click{" "}
							<strong>[Cancel]</strong> Archive Pre-Preprocess
							PDFs in the <strong>[Archive Pre-process]</strong>{" "}
							Screen.
						</>
					),
			  }
	return (
		<div className="archive-action-area">
			<div className="warning-msg">
				<div>{archDiagFooterNotes_1.message}</div>
				{!isArchived &&
					isLocked &&
					(statusId == "1" || statusId == "4") &&
					isPreProcessed && (
						<div>{archDiagFooterNotes_2.message}</div>
					)}
			</div>
			<div className="FR AC JSB">
				<div className="FR AC">
					{projectStatus !== ProjectStatus.archived &&
						projectStatus !== ProjectStatus.replica && (
							<>
								{permission.update &&
									(partialStore.getConditionInfo(
										"archiveConditionId16"
									) &&
									partialStore.getConditionInfo(
										"archiveConditionId16"
									)?.status === "DONE" ? (
										<ProjectUnLockDialog
											open={projUnLockDialogOpen}
											setOpen={setProjUnLockDialogOpen}
										/>
									) : (
										<div className="FR AC">
											{adminMode ? (
												<ProjectLockDialog
													open={projLockDialogOpen}
													setOpen={
														setProjLockDialogOpen
													}
													isAdminMode={true}
												/>
											) : !oversized ? (
												<ProjectLockDialog
													open={projLockDialogOpen}
													setOpen={
														setProjLockDialogOpen
													}
												/>
											) : (
												<div>
													Sorry, Project size is over
													3GB. Please contact to
													admin.
												</div>
											)}
										</div>
									))}
							</>
						)}
					{/* {partialStore.getConditionInfo("archiveConditionId16") &&
					partialStore.getConditionInfo("archiveConditionId16")
						?.status === "DONE" &&
					partialStore.getCompletedCount("mandatory") ===
						partialStore.getMandatoryList.length &&
					projectStatus !== ProjectStatus.archived && ( */}
					<div>
						{/* <Link to={`${defaultUrl}/archive/archive-pre-process`}> */}
						<DLButton
							eleTestId="archive-pre-process-btn"
							startIcon={
								<Icon path={mdiShieldLockOutline} size={0.8} />
							}
							clickHandler={() => redirectTab()}
							color="primary"
							disabled={
								!(
									(
										partialStore.getConditionInfo(
											"archiveConditionId16"
										) &&
										partialStore.getConditionInfo(
											"archiveConditionId16"
										)?.status === "DONE" &&
										partialStore.getCompletedCount(
											"mandatory"
										) ===
											partialStore.getMandatoryList.length
									) // &&
									// projectStatus !== ProjectStatus.archived
								) || disableBtn
							}
						>
							Archive Pre-Process
						</DLButton>
						{/* </Link> */}
					</div>
					{/* )} */}
				</div>
				<div className="FR AC">
					{permission.export &&
						projectStatus !== ProjectStatus.archived && (
							<DLButton
								eleTestId="export-diagnosis-btn"
								startIcon={
									<Icon path={mdiDownload} size={0.8} />
								}
								clickHandler={() =>
									partialStore.exportArchiveDiagnosisPdf()
								}
							>
								Export Diagnosis
							</DLButton>
						)}
					{/* WARNING: for Archive management screen's use  */}
					{permission.export &&
						projectStatus === ProjectStatus.archived && (
							// TODO:  Have to check hasExportAccess
							// and required API is GetArchivedZipUrl
							<DLButton
								eleTestId="export-archived-zip-btn"
								startIcon={
									<Icon path={mdiDownload} size={0.8} />
								}
								clickHandler={() =>
									partialStore.exportArchivedZipFile(
										projectId
									)
								}
							>
								Export Archive Zip
							</DLButton>
						)}
					{/* TODO: how about to use rationale permission directly? */}
					{permission.viewRationales && renderRationaleButton()}
				</div>
			</div>
		</div>
	)
})
