import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const AddEngType = (self: any) => ({
	addEngType(payload: {
		EngagementTypeItemName: string
		IsActive: boolean
		SortOrder: number
	}) {
		// 0.
		const actionName = "addEngType"
		// 1.
		const checkedProps = hasEmptyProps({
			EngagementTypeItemName: payload.EngagementTypeItemName,
			SortOrder: payload.SortOrder,
		})
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.createEngType(payload)
			.then((response: any) => {
				if (response.data.Status === 1) {
					const engType = response.data.Data
					const reOrganizedEngType = {
						id: idToString(
							engType.EngagementTypeItemId,
							IdType.engType
						), // [EngagementID], ex) 159
						name: engType.EngagementTypeItemName,
						archiveDatePolicySetId: idToString(
							engType.ArchiveDatePolicySetId,
							IdType.archivePolicy
						),
						createdByUserId: engType.CreatedByUserId,
						createdAt: engType.CreatedAtUtc,
						createdBy: engType.CreatedByUserName,
						modifiedByUserId: engType.ModifiedByUserId,
						modifiedAt: engType.ModifiedAtUtc,
						modifiedBy: engType.ModifiedByUserName,
						sortOrder: engType.SortOrder,
						isActive: engType.IsActive,
						activatedByUserId: engType.ActivatedByUserId,
						activatedAt: engType.ActivatedAtUtc,
						activatedBy: engType.ActivatedByUserName,
						deActivatedByUserId: engType.DeActivatedByUserId,
						deActivatedAt: engType.DeActivatedAtUtc,
						deActivatedBy: engType.DeActivatedByUserName,
					}
					self.pushItemToList(reOrganizedEngType)
					// close the dialog
					self.setOpenAdd(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: response.data.Message,
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddEngType
